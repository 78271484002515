<template>
	<div style="width: 100%;height: 100%;padding: 20px 30px 20px 30px;">
		<div style="font-size: 18px;color: #101010;">支付中心</div>
		
		
		
		<div style="font-size: 25px;color: #101010;text-align: center;color: rgb(223, 187, 102);font-weight: 700;letter-spacing: 10px;">标准查会员</div>
		<div style="letter-spacing: 4px;font-size: 20px;text-align: center;margin-top: 10px;">专业稳定 · 多端通用(电脑、手机、平板)</div>
		<div style="display: flex;align-items: center;margin-top: 10px;">
			<div v-for="(item,index) in shoplist" :key="index" style="width: 250px;height: 170px;background-color: #fff;margin: 10px;padding: 20px;border-radius: 5px;	cursor: pointer;display: flex;flex-direction: column;align-items: center;overflow: auto;" @click="changeshop(item,index)" :class="[shop_index == index ? 'activeCls' : '']">
				<div class="shopname">{{item.name}}</div>
				<div style="margin-top: 10px;color: #f90;font-size: 24px;">{{item.vip_price}} <span style="font-size: 14px;">元/年</span></div>
				<div v-if="item.product == '0029'">
					<div class="shoptxt">咨询客服定制</div>
				</div>
				<div v-else>
					<div v-if="item.product == '0026'" class="shoptxt">每日10次标准文件下裁</div>
					<div v-else class="shoptxt">每日30次标准文件下裁</div>
				</div>
			</div>
			
		</div>
		
		<div style="display: flex;align-items: center;">
			<div v-for="(item,index) in pay_type" :key="index" style="width: 150px;height: 50px;background-color: #fff;margin: 20px;display: flex;justify-content: space-between;align-items: center;padding: 20px;border-radius: 5px;	cursor: pointer;" @click="changepay(item,index)" :class="[pay_type_index == index ? 'activeCls' : '']">
				<img :src="item.imgs" alt="" style="width: 30px;height: 30px;"/>
				<p style="font-size: 16px;">{{item.title}}</p>
			</div>
		</div>
		<div class="btnpay" @click="topay">
			立即支付
		</div>
		<div style="">
			<img src="../../src/assets/images/vipinfo.jpg" alt="" style="width: 100%;margin: 20px 0;"/>
		</div>
		<el-dialog
		  title="微信支付"
		  :visible.sync="wxpayflag"
		  width="30%"
		  :before-close="handleClose">
		  <div style="width: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
			  <div style="font-size: 18px;color: #101010;">打开手机微信扫一扫，继续付款</div>
			  <img id="qrcode" :src="wxpayUrl">
			  <div style="font-size: 18px;color: #101010;">请在三分钟内完成支付！</div>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="closewxpay">取 消</el-button>
		    <!-- <el-button type="primary" @click="wxpayflag = false">确 定</el-button> -->
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
	  data() {
	    return {
			pay_type: [
				{
					pay: 'wx_pc_pay',
					title:'微信',
					imgs: require('../assets/images/wxlogo.png')
				},
				{
						pay: 'ali_pc_pay',
						title:'支付宝',
						imgs: require('../assets/images/zfblogo.png')
					}
			],
			pay_type_index:0,//支付方式的索引
			user_type:'wx_pc_pay',//用户选择的支付方式
			wxpayUrl:'',
			wxpayflag:false,
			shoplist:[],
			shop_index:0,
			usershop:'0026',//用户选择的商品
			timer:'',
			user_permission:''
	    }
	  },
	  created() {
	  	this.pushuser()
		this.getorderlist()
	  },
	  mounted(){
		  this.getvip()
	  },
	  methods:{
		  pushuser(){
			  let token = JSON.parse(window.localStorage.getItem('token'))
			  // console.log(token);
			  if(token.info.telephone){
			  	this.$axios({
			  		url:'/push_info/',
			  		params:{
			  			input:token.info.telephone
			  		}
			  	}).then(res=>{
			  		console.log(res);
			  		 this.email = res.data.info.email
			  		 this.user_permission = res.data.info.permission__name
			  	})
			  }
		  },
		  closewxpay(){
			  clearInterval(this.timer);
			  this.wxpayflag = false
		  },
		  changeshop(item,index){
			  this.shop_index = index
			  this.usershop = item.product
		  },
		  getvip(){
			  this.$axios({
				  url:'/shop/',
				  params:{
					  vip:'1'
				  }
			  }).then(res=>{
				  console.log(res);
				  if(res.data.status == 200){
					  this.shoplist = res.data.info
				  }
			  })
		  },
		  handleClose(done) {
		        this.$confirm('确认关闭？')
		          .then(_ => {
		            done();
					clearInterval(this.timer);
		          })
		          .catch(_ => {});
		      },
			  checkwxOrder(order_num){
				  console.log("订单的tradeno:"+order_num)
				        let count = 0;
				        //定时1分钟轮询检查
				        this.timer = setInterval(() =>{
				         
						 this.$axios({
							 url:'/order_pay/',
							 params:{
								 order_num:order_num
							 }
						 }).then(res=>{
							 console.log(res);
							 if(res.data.info.status == '交易成功'){
							 	//支付成功关闭弹窗 提示
							 	this.$message.success(res.data.info.status)
							   clearInterval(this.timer);
							   this.pushuser()
							   this.wxpayflag = false
							   // this.dialogFormVisible = false;
							 }
							 if(res.data.info.status == '交易失败'){
								 this.$message.error(res.data.info.status)
								 clearInterval(this.timer);
								 this.wxpayflag = false
							 }
						 })
						 
				          count++;
				          if(count == 30){
				            clearInterval(this.timer);
				            this.$message.error("支付超时");
							this.wxpayflag = false
				          }
				        },6000);
				  
			  },
		  topay(){
			  
			  	let token = JSON.parse(window.localStorage.getItem('token'))
			  	if(token){
			  		console.log(this.user_type);
			  		if(this.user_type == 'wx_pc_pay'){
						this.$axios({
							url:'/order_pay/',
							params:{
								product:this.usershop,
								pay_type:'wx_pc_pay'
							}
						}).then(res=>{
							console.log(res);
							if(res.data.status == 200){
								this.wxpayUrl = res.data.info
								this.wxpayflag = true
								this.checkwxOrder(res.data.order_num)
							}
						})
					}
					if(this.user_type == 'ali_pc_pay'){
						this.$axios({
							url:'/order_pay/',
							params:{
								product:this.usershop,
								pay_type:'ali_pc_pay'
							}
						}).then(res=>{
							console.log(res);
							if(res.data.status == 200){
								let payUrl = "https://openapi.alipay.com/gateway.do?"+res.data.info
								// this.wxpayflag = true
								 window.location.href = payUrl
							}
						})
					}
			  	}else{
			  		this.$message.error('您还没有登录')
			  	}
			  
		  },
		  changepay(item,index){
			  this.pay_type_index = index
			  this.user_type = item.pay
		  },
		  getorderlist(){
			  
		  }
	  }
	}
</script>

<style scoped="scoped">
	.activeCls {
	position: relative;
	border: 1px solid #f90;
	  &:before {
	    content: "";
	    position: absolute;
	    right: 0;
	    bottom: 0;
	    border: 12px solid #f90;
	    border-top-color: transparent;
	    border-left-color: transparent;
	  }
	  &:after {
	    content: "";
	    width: 5px;
	    height: 10px;
	    position: absolute;
	    right: 4px;
	    bottom: 5px;
	    border: 1px solid #fff;
	    border-top-color: transparent;
	    border-left-color: transparent;
	    transform: rotate(45deg);
	  }
	}
	.btnpay{
		width: 200px;
		height: 44px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-weight: 800;
		font-size: 20px;
		background-color: #f90;
		border-radius: 5px;
		margin-top: 10px;
		margin-left: 20px;
	}
	.shopname{
		font-size: 18px;
		font-weight: 700;
		color: #000;
	}
	.shoptxt{
		font-size: 14px;
		color: #666;
		text-align: center;
		margin-top: 10px;
	}
</style>