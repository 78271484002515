<template>
	<div  >
		<div id="login_container" style="display: flex;justify-content: center;align-items: center;margin-top: 10%;">
			<iframe class="iframe"></iframe>
		</div>
	<!-- 	<div class="promptBox">
			
		</div> -->
		<div  style="display: flex;justify-content: center;align-items: center;">
			<div @click="ooo" style="color: #f38942;cursor: pointer;">其他登录方式</div>
		</div>
	</div>
	
</template>

<script>
	import '../assets/js/wxsm.js'
	export default{
		data(){
			return{
				objr:{},
				local:'',
				therequset:{}
			}
		},
		mounted() {
			 
			// this.wxlogin()
			// this.getCode()
			
			this.wxs()
			// this.$router.push({path:'/wxbind',query: {nums:123456}})
			// console.log(this.objr);
		},
		methods:{
			getCode(){
				let url = location.search;
				            let theRequest = new Object()
				            if (url.indexOf("?") != -1) {
				                let str = url.substr(1)
				                let strs = str.split("&")
								alert('授权成功')
				                for(let i = 0; i < strs.length; i ++) {
				                    theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
				                }
				            }
							// console.log(theRequest);
				            return theRequest;
				
			},
			ooo(){
				this.$router.go(-1)
				// window.sessionStorage.setItem('code','123')
				// this.$router.push({path:'/wxbind',query: {nums:123456}})
			},
			wxs(){
				let url = location.search;
				let that = this
				if (url.indexOf("?") != -1) {
					 let theRequest = new Object()
				    let str = url.substr(1)
				    let strs = str.split("&")
					// alert('授权成功')
				    for(let i = 0; i < strs.length; i ++) {
				        theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
				    }
					// console.log(theRequest);
					that.therequset = theRequest
					// console.log(that.therequset);
					that.$axios({
						url:'/wxbind/',
						method:'post',
						data:{
							info:theRequest.code
						}
					}).then(res=>{
						// console.log(res);
						window.sessionStorage.setItem('codestatus',res.data.status)
						if(res.data.status==403){
							// let that = this
							window.sessionStorage.setItem('codeinfo1',JSON.stringify(res.data.info))
			
							that.$message.error('缺少必要信息！')
							setTimeout(function(){
								that.$router.go(-2)
								// that.$router.push({path:'/wxbind',query: {nums:theRequest.code}})
							},1000)
						}
						if(res.data.status==404){
							that.$message.error('微信授权失败！')
							that.$router.go(-2)
						}
						if(res.data.status==401){
							that.$message.error('该手机号已绑定其他微信！')
							that.$router.go(-2)
						}
						if(res.data.status==201){
							that.$message.success('绑定成功')
							that.$router.go(-2)
						}
						if(res.data.status==200){
							window.localStorage.setItem('token',JSON.stringify(res.data))
							that.$router.go(-2)
						}
					}).catch(err=>{
						// console.log(err);
					})
				}else{
					// let that = this
					// that.local = encodeURIComponent('https://www.biaozhuncha.com/api/try');//获取当前url
					// console.log(location.href)
					that.local = encodeURIComponent(window.location.href);//获取当前url
					// console.log(window.location.href);
					// console.log(that.local)
							
						
					let redirect_uri = that.local
					
					 var obj = new WxLogin({
					 self_redirect:true,
					 id:"login_container", 
					 appid: "wx50aa87314e38a9f7", 
					 scope: "snsapi_login", 
					 redirect_uri: redirect_uri,
					 state: "STATE#wechat_redirect",
					 style: "black"
					 });
				}
			},
			wxlogin(){
				let that = this
				// that.local = encodeURIComponent('https://www.biaozhuncha.com/api/try');//获取当前url
				// console.log(location.href)
				that.local = encodeURIComponent(window.location.href);//获取当前url
				// console.log(window.location.href);
				// console.log(that.local)
		
	
				let redirect_uri = that.local
				
				 var obj = new WxLogin({
				 self_redirect:true,
				 id:"login_container", 
				 appid: "wx50aa87314e38a9f7", 
				 scope: "snsapi_login", 
				 redirect_uri: redirect_uri,
				 state: "STATE#wechat_redirect",
				 style: "black"
				 });

			},
			sm(i){
				// console.log(object);
			}
		}
	}
</script>

<style>
</style>
