<template>

		<div>
			<!-- <el-input placeholder="请输入标准名称、标准号等" v-model="inpv" class="input-with-select">
			  <el-button @click="search1" slot="append" type="primary" icon="el-icon-search" style="color: red;">搜索</el-button>
			</el-input> -->
			
		
			<div style="min-height: 100%;margin-top: 10px;">
			<router-view></router-view>
			</div>
			
		
			
			
				 

		</div>
</template>

<script>
	export default{
		data(){
			return{
				inpv:'',
				// 当前页数
				currentPage:1,
				// 总条数
				totals:1,
				//标准分类
				fenlei:['全部','国家标准','行业标准','地方标准','企业标准','国际标准','团体标准'],
				searchData:[],
				//搜索传参分类
				fenValue:'国家标准',
				//默认展示的分类
				fencurrent:1,
				//搜索传参状态
				states:['全部','现行','废止','即将实施','正在审查','正在批准','正在起草','正在公示','征求意见','有更新版','责令改正','被代替','不执行'],
				statecurrent:0,
				//搜索传参状态
				stateValue:'全部',
				//
				options:['查标准','查产品','查规范'],
				opindex:0,
				tableData: [{
				            date: '2016-05-02',
				            name: '王小虎',
				            address: '上海市普陀区金沙江路 1518 弄'
				          }, {
				            date: '2016-05-04',
				            name: '王小虎',
				            address: '上海市普陀区金沙江路 1517 弄'
				          }, {
				            date: '2016-05-01',
				            name: '王小虎',
				            address: '上海市普陀区金沙江路 1519 弄'
				          }, {
				            date: '2016-05-03',
				            name: '王小虎',
				            address: '上海市普陀区金沙江路 1516 弄'
				          }]
			}
		},
		methods:{
			search1(){
				// console.log(this.inpv);
				if(this.inpv==''){
					this.$message.error('请您输入内容后搜索')
				}else{
					this.$axios({
						url: '/querylist/',
						params: {
							input: this.inpv,
							page_num:20,
							types:this.fenValue,
							state:this.stateValue,
							time:'默认'
						}
					}).then(res=>{
						// console.log(res);
						if(res.data.info.status==200){
								this.searchData = res.data.info
								let a = res.data.count
								let b = res.data.info.length
								// console.log(a,b);
								// console.log(a/b);
								let c = a/b
							let d =	parseInt((c)+1)
							// console.log(d);
								this.totals = d
						}else{
							this.$message.error('暂无搜索结果')
						}
					}).catch(err=>{
						// console.log(err);
					})
				}
			},
			handleSizeChange(val){
				// console.log(val);
				let nums = val*20
				// console.log(nums);
				this.$axios({
					url: '/querylist/',
					params: {
						input: this.inpv,
						page_num:nums,
						types:this.fenValue,
						state:this.stateValue,
						time:'默认'
					}
				}).then(res=>{
					// console.log(res);
					this.searchData = res.data.info
					
				}).catch(err=>{
					// console.log(err);
				})
			},
			toDetail(row){
				// console.log('click');
				// console.log(row);
				this.$router.push({path:'/detail',query: {id:'1'}})
			},
			fenClick(item,index){
				// console.log(item);
				this.fenValue = item
				this.fencurrent = index
				// console.log(this.fenValue);
				
			},
			stateClick(item,index){
				this.stateValue = item
				this.statecurrent = index
				// console.log(this.stateValue);
			},
			tolink(item,index){
				this.opindex = index
				// console.log(this.opindex)
				if(item=="查标准"){
					this.$router.push({path:'/search'})
				}
				if(item=="查产品"){
					this.$router.push({path:'/product'})
				}
				if(item=="查规范"){
					this.$router.push({path:'/searchgf'})
				}
			}
		}
	}
</script>

<style scoped="scoped">
	.flbj{
		background-color: #42B983;
	}
	.searchcss{
		font-size: 14px;
	}
	.opcss{
		font-size: 30px;
		color: #FFFFFF;
		width: 160px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.opbj{
		position: relative;
		/* color: red; */
	}
	.opbj:before{
		content: "";/*单引号双引号都可以，但必须是英文*/
		 
		    width: 50px;
		 
		    height: 1.5px; /*下划线高度*/
		 
		    background: #FFFFFF; /*下划线颜色*/
		 
		    position: absolute;
		 
		    top: 110%;
		 
		    left: 35%;
		 
		    transition: all .8s ; /*css动画效果，0.8秒完成*/
	}
</style>
