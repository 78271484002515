module.exports = {
  plugins: {
    // 'postcss-import': {},
    // 'postcss-url': {},
    // to edit target browsers: use "browserslist" field in package.json
    autoprefixer: {},
    'postcss-px2rem': {
      // rootValue: 422.4,//对应设计图宽度4224*1296
      // rootValue: 192,//对应设计图宽度1920*1080
      rootValue: 75, // 对应设计图的750*667 1rem = 75px
      propList: ['*', '!border*'] //  表示不匹配 要是想匹配边框之类的 可以在里面用 !border* 表示不配备边框，这样不会把边框1px变成1rem
    }
  }
}