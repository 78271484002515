<template>
	<div style="width: 100%;height: 100%;padding: 20px 30px 20px 30px;"> 
		<div style="display: flex;justify-content: center;align-items: center;width: 100%;height: 100%;" v-if="flag==true">
			<img src="../assets/images/personal/nomessage.png" alt="" style="width: 268px;height: 206px;">
		</div>
		
		<div style="display: flex;flex-direction: column;align-items: center;" v-if="flag==false">
			<div v-for="(item,index) in orderlist" :key="index" >
			<div v-if="item.info_type == 0" style="width: 700px;height: 80px;margin: 0 0 30px 0;padding: 10px;display: flex;align-items: center;color: #101010;padding-left: 80px;" class="xxbj">
				<div>
					<div>商品名称：{{item.title}}</div>
					<div style="margin-top: 15px;">订单号：{{item.order_num}}</div>
				</div>
				
				<div style="margin-left: 70px;">
					<div>订单价格：{{item.order_price}}元</div>
					<div style="margin-top: 15px;">下单时间：{{item.info_time.split('T')[0]}}  {{item.info_time.split('T')[1]}}</div>
				</div>
			</div>
			
			<div v-if="item.info_type == 1" style="width: 700px;height: 80px;margin: 0 0 30px 0;padding: 10px;display: flex;align-items: center;color: #101010;padding-left: 80px;" class="xxbj">
				<div>标准通知</div>
				<div style="margin-left: 20px;">
					<div>标准名称：{{item.title}}</div>
				</div>
				
				<div style="margin-left: 70px;">
					<div style="margin-top: 15px;">录入时间：{{item.info_time.split('T')[0]}}  {{item.info_time.split('T')[1]}}</div>
					<div style="margin-top: 15px;">{{item.body}}</div>	
				</div>
			</div>
			
			<div v-if="item.info_type == 2" style="width: 700px;height: 80px;margin: 0 0 30px 0;padding: 10px;display: flex;align-items: center;color: #101010;padding-left: 80px;" class="xxbj">
				<div>其他通知</div>
				<div style="margin-left: 20px;">
					<div>{{item.title}}</div>
				</div>
				
				<div style="margin-left: 70px;">
					<div style="margin-top: 15px;">{{item.info_time.split('T')[0]}}  {{item.info_time.split('T')[1]}}</div>
					<div style="margin-top: 15px;">{{item.body}}</div>	
				</div>
			</div>
					
			</div>
			
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				orderlist: [],
				flag:true
			}
		},
		mounted() {
			this.getinfo()
		},
		methods: {
			getinfo() {
				this.$axios({
					url: '/app_info/'
				}).then(res => {
					console.log(res);
					if (res.data.status == 200) {
						this.orderlist = res.data.info.reverse()
						this.flag = false
						// console.log(this.orderlist);
					}else{
						this.flag = true
					}
					
					
				})
			}
		}
	}
</script>

<style scoped="scoped">
.xxbj{
	background: url(../assets/images/personal/xxbj.png)no-repeat;
	background-size: 100% 100%;
}
</style>
