<template>
	<div style="display: flex; justify-content: center;">
		<div class="content">
			
			<div v-for="(item,index) in nesDetail" :key="index" class="left">
				<div style="font-size: 0.24rem;color: #101010;">{{item.title}}</div>
				<div style="font-size: 0.14rem;color: #666666;display: flex;align-items: center;margin-top: 0.1rem;">
					<div>来源：{{item.author}}</div>
					<div style="margin-left: 0.2rem;">发表于{{item.time}}</div>
				</div>
			</div>
			<div v-html="bodys" style="width: 100%;" class="left"></div>
			
			
			
			
			<div style="width: 9rem;height: 0.4rem;background-color: rgba(255,152,59,.1);display: flex;justify-content: center;align-items: center;margin-bottom: 0.3rem;color: #666666;margin: 0 auto;">
				<div>新闻来源于网络，版权归原作者所有，内容仅代表作者本人观点，不代表标准查的立场。如有任何疑问或需要删除，请联系：400-678-5363</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				nesDetail:[],
				bodys:''
			}
		},
		mounted() {
			this.getnews()
		},
		methods:{
			getnews(){
				let nums = this.$route.query.num
				// console.log(nums);
				this.$axios({
					url: '/news/',
					params: {
						input: nums
					}
				}).then(res=>{
					// console.log(res);
					this.nesDetail = res.data.info
					res.data.info.forEach(res => {
						// console.log(res);
						// console.log(res.like_me);
						// this.pic = res.picture
						// console.log(res.news_content)
						this.bodys = res.news_content.replace(/\<img/gi,
							'<img style="display:-webkit-box;width:100% !important;margin:15px auto !important;" '
							);
						this.bodys = this.bodys.replace(/section/g, 'p')
				
					
					})
				})
			}
		}
	}
</script>

<style scoped="scoped">
	@media screen and (max-width: 750px) {
		.left{
		
			/* margin-left: 1rem; */
		}
		
	}
	.content{
		width: 11rem;
		background-color: #FFFFFF;
		margin-top: 0.4rem;
		margin-bottom: 0.3rem;
		border-radius: 0.1rem;
		display: flex;
		flex-direction: column;
		padding: 0.3rem 0.2rem 0 0.2rem;
		/* justify-content: center; */
	}
</style>
