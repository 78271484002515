<template>
	<div style="width: 100%;height: 100%;padding: 20px 30px 20px 30px;"> 
		<div style="display: flex;justify-content: center;align-items: center;width: 100%;height: 100%;" v-if="flag==true">
			<img src="../assets/images/personal/nogz.png" alt="" style="width: 268px;height: 206px;">
		</div>
		
		<div style="display: flex;flex-direction: column;align-items: center;" v-if="flag==false">
			<div v-for="(item,index) in gz" :key="index" style="width: 700px;height: 80px;margin: 0 0 30px 0;padding: 10px;display: flex;align-items: center;color: #101010;cursor: pointer;" class="gzbj" @click="godetail(item.pk_id)">
			<div>
				<div>标准名称：{{item.c_c_name}}</div>
				<!-- <div style="margin-top: 15px;">发布日期：{{item.issue_date}}</div> -->
			</div>
			
			<div style="margin-left: 50px;">
				<div>标准号：{{item.c_std_code}}</div>
				<!-- <div style="margin-top: 15px;">实施日期：{{item.act_date}}</div> -->
			</div>
					
			</div>
			
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				gz: [],
				flag:true
			}
		},
		mounted() {
			this.getinfo()
		},
		methods: {
			godetail(pid){
				
								// console.log(r)
								let id =JSON.stringify(pid);
								// console.log(id)
								
				// tag: 'a',
								const {href} = this.$router.resolve(
								{ 
								  path: '/detail',
								  query: {body: id}}
								)
								let token = JSON.parse(window.localStorage.getItem('token'))
								// console.log(token);
								if(token){
									window.open(href, '_blank')
									// console.log(this.currentPage);
								}else{
									this.$message.error('您还没有登录')
									setTimeout(()=>{
										this.$router.push({path:'/login'})
									},1200)
								}
								
								// this.$router.push({path:'/detail',query: {body:r}})
							
			},
			getinfo() {
				this.$axios({
					url: '/index_menu/',
					params:{
						trace:1
					}
				}).then(res => {
					console.log(res);
					if(res.data.status == 200){
						this.flag = false
						this.gz = res.data.info
					}else{
						this.flag = true
					}
					
				})
			}
		}
	}
</script>

<style scoped="scoped">
.gzbj{
	background: url(../assets/images/personal/gzbj.png)no-repeat;
	background-size: 100% 100%;
}
</style>
