<template>
	<div style="background-color: #f8f8f8;">
		
		<div style="display: flex;justify-content: center;">
			<div style="width: 1200px;background-color: #ffffff;margin-top: 40px;border-radius: 0 45px 0 0;">
				<div style="width: 100%;">
					<!-- 头部信息展示 -->
					<div class="head" style="display: flex;align-items: center;line-height: 46px;">
						<div class="iconfont icon-gongsi00" style="font-size: 19px;color: #FFFFFF;margin-left: 26px;"></div>
						<div style="font-size: 18px;color: #FFFFFF;margin-left: 10px;">{{unit}}</div>
					</div>

					<div style="display: flex;">
						<div style="display: flex;margin-top: 20px;">
							<div style="width: 8px;height: 30px;background-color: #f9a31c;border-radius: 2px;"></div>
							<div style="margin-left: 16px;display: flex;">
								<div style="font-size: 18px;color: #101010;font-weight: 800;margin-top: 3px;">简介：</div>
								
							</div>
						</div>
						<div style="width: 1063px;font-size: 14px;color: #4a4b4b;margin-left: 10px;margin-top: 28px;line-height:23px;line-height: 25px;">药品的生产、医疗器械生产经营范围见医疗器械生产经营许可证、临床输血专业领域内的技术开发、转让、服务、培训，从事货物和技术的进出口业务。 【依法须经批准的项目，经相关部门批准后方可开展经营活动</div>
						
					</div>
				<div style="margin-left: 26px;margin-right: 26px;margin-top: 5px;">
					<el-divider></el-divider>
				</div>
						
				
				</div>
				
			<div style="display: flex;margin-top: 44px;">
				<div style="width: 8px;height: 30px;background-color: #f9a31c;border-radius: 2px;"></div>
				<div style="margin-left: 16px;display: flex;">
					<div style="font-size: 18px;color: #101010;font-weight: 800;margin-top: 3px;">标委会信息</div>
					
				</div>
				
			</div>
				<div style="display: flex;justify-content: center;margin-top: 25px;">
					<div style="width: 1144px;height: 180px;border: 1px solid #e5e5e5;overflow: hidden;">
						<div style="width: 1144px;height: 45px;background-color: rgba(251,155,124,.2);line-height: 45px;color: #4a4b4b;padding-left: 20px;overflow: hidden;display: flex;">
							<div style="width: 84px;">
								<p style="float: right;">标委会名称：</p>
							</div>
							<div style=""></div>
						</div>
						
						<div style="width: 1144px;height: 45px;line-height: 45px;color: #4a4b4b;padding-left: 20px;overflow: hidden;display: flex;">
							<div style="width: 84px;">
								<p style="float: right;">标委会职务：</p>
							</div>
							<div style=""></div>
						</div>
						<div style="width: 1144px;height: 45px;background-color: rgba(251,155,124,.2);line-height: 45px;color: #4a4b4b;padding-left: 20px;overflow: hidden;display: flex;">
							<div style="width: 84px;">
								<p style="float: right;">加入时间：</p>
							</div>
							<div style=""></div>
						</div>
						<div style="width: 1144px;height: 45px;line-height: 45px;color: #4a4b4b;padding-left: 20px;overflow: hidden;display: flex;">
							<div style="width: 84px;">
								<p style="float: right;">工作单位：</p>
							</div>
							<div style=""></div>
						</div>
					</div>
				</div>
		
				
				<div style="display: flex;margin-top: 44px;">
					<div style="width: 8px;height: 30px;background-color: #f9a31c;border-radius: 2px;"></div>
					<div style="margin-left: 16px;display: flex;">
						<div style="font-size: 18px;color: #101010;font-weight: 800;margin-top: 3px;">起草的标准计划</div>
						
					</div>
					
				</div>

				<div class="table">
					<el-table :data="draList" style="margin-top: 30px;width: 100%;" align="center" :header-cell-style="{backgroundColor: '#ffdddc',color:'#101010',width: '100%',borderRight:'2px solid #ffffff',textAlign:'center',borderRadius:'3px',opacity:'.8',fontWeight:'550',height:'40px',fontSize:'16px'}" :cell-style="TableRowClassName">
					 		  <el-table-column
					 		        type="index"
					 				label="序号"
					 		        width="75"
										align="center">
					 		      </el-table-column>
					    <el-table-column
					      prop="number"
					      label="计划号"
					      width="180"
							   align="center">
					    </el-table-column>
					 		<!-- <el-table-column
					 		   prop="type"
					 		   label="规范分类"
					 		   width="130"
								   align="center">
					 		 </el-table-column> -->
					    <el-table-column
					      prop="name"
					      label="计划名称"
					      width="390"
					      :show-overflow-tooltip="true"
							   align="center">
					    </el-table-column>
					 		
					 		<el-table-column
					 		  prop="ding"
					 		  label="制修订"
					 		  width="110"
					 			   align="center">
					 		</el-table-column> 
					   
					    <el-table-column
					      prop="fabu"
					      label="计划下达日期"
					      width="190"
							   align="center">
					    </el-table-column>
					 		
					 <el-table-column
					   prop="status"
					   label="项目状态"
					   width="170"
					 								   align="center">
					 </el-table-column>
					 		 
					 		 
					    </el-table>
				</div>
				
			<div style="display: flex;margin-top: 44px;">
				<div style="width: 8px;height: 30px;background-color: #f9a31c;border-radius: 2px;"></div>
				<div style="margin-left: 16px;display: flex;">
					<div style="font-size: 18px;color: #101010;font-weight: 800;margin-top: 3px;">起草的标准</div>
					
				</div>
				
			</div>
			
			<div class="table">
				<el-table :data="bz" style="margin-top: 30px;width: 100%;" align="center" :header-cell-style="{backgroundColor: '#ffebdf',color:'#101010',width: '100%',borderRight:'2px solid #ffffff',textAlign:'center',borderRadius:'3px',opacity:'.8',fontWeight:'550',height:'40px',fontSize:'16px'}" :cell-style="TableRowClassName">
				 		  <el-table-column
				 		        type="index"
				 				label="序号"
				 		        width="75"
									align="center">
				 		      </el-table-column>
				    <el-table-column
				      prop="number"
				      label="标准号"
				      width="180"
						   align="center">
				    </el-table-column>
				 		
				    <el-table-column
				      prop="name"
				      label="标准名称"
				      width="400"
				      :show-overflow-tooltip="true"
						   align="center">
				    </el-table-column>
				 		
				 		 
				   
				    <el-table-column
				      prop="fabu"
				      label="发布日期"
				      width="160"
						   align="center">
				    </el-table-column>
				 		 <el-table-column
				 		   prop="shishi"
				 		   label="实施日期"
				 		   width="160"
							   align="center">
				 		 </el-table-column>
				 <el-table-column
				   prop="status"
				   label="标准状态"
				   width="140"
				 								   align="center">
				 </el-table-column>
				 		 
				 		 
				    </el-table>
			</div> 
				
			
			</div>
		</div>
	
	<div style="height: 40px;width: 100%;"></div>
	
	</div>
</template>

<script>
	export default{
		data(){
			return{
				sear:'标委会名称标委会名称标委会名称标委会名称',
				draList:[{
					number:'20183248-Q-464',
					name:'人体血液及血液成分袋式塑料容器 第4部分：内容...内容...内容...内容...内容...内容...',
					fabu:'2016-06-12',
					shishi:'2016-06-12',
					status:'已发布',
					ding:'制定'
				},{
					number:'20183248-Q-464',
					name:'人体血液及血液成分袋式塑料容器 第4部分：内容...内容...',
					fabu:'2016-06-12',
					shishi:'2016-06-12',
					status:'已发布',
					ding:'制定'
				},{
					number:'20183248-Q-464',
					name:'人体血液及血液成分袋式塑料容器 第4部分：内容...',
					fabu:'2016-06-12',
					shishi:'2016-06-12',
					status:'已发布',
					ding:'制定'
				},{
					number:'20183248-Q-464',
					name:'人体血液及血液成分袋式塑料容器 第4部分：内容...',
					fabu:'2016-06-12',
					shishi:'2016-06-12',
					status:'已发布',
					ding:'制定'
				}],
				bz:[
					{
						number:'20183248-Q-464',
						name:'人体血液及血液成分袋式塑料容器 第4部分：内容...',
						fabu:'2016-06-12',
						shishi:'2016-06-12',
						status:'现行',
					},{
						number:'20183248-Q-464',
						name:'人体血液及血液成分袋式塑料容器 第4部分：内容...',
						fabu:'2016-06-12',
						shishi:'2016-06-12',
						status:'现行',
					},{
						number:'20183248-Q-464',
						name:'人体血液及血液成分袋式塑料容器 第4部分：内容...',
						fabu:'2016-06-12',
						shishi:'2016-06-12',
						status:'现行',
					},{
						number:'20183248-Q-464',
						name:'人体血液及血液成分袋式塑料容器 第4部分：内容...',
						fabu:'2016-06-12',
						shishi:'2016-06-12',
						status:'现行',
					}
				],
				unit:''
			}
		},
		mounted() {
			this.unit = this.$route.query.draftunit
			// console.log(this.$route.query.draftunit);
		},
		methods:{
			TableRowClassName(){
				return 'background:#f5f5f5;border-bottom:1px solid #ffffff;'
			},
			onther(){
				// console.log();
			}
		}
	}
</script>

<style scoped="scoped">
	.head{
		width: 100%;
		height: 46px;
		background: url(../assets/images/detail/draferhead.png) no-repeat;
		background-size: cover;
	}
	.el-table__header-wrapper{
		background-color: #000000;
	}
	.table .el-table::before{
	    background-color: #FFFFFF;
	  }
	.table /deep/  .el-table, .el-table__expanded-cell {
	    background-color: transparent;
	}
	.el-table__row.statistics-warning-row {
	background: #F7FFFE;
	}
</style>
