<template>
	<div style="display: flex;flex-direction: column;align-items: center;">
		<div style="width: 1100px;height: 110px;background-color: #FFFFFF;margin-top: 50px;border-radius: 10px;display: flex;align-items: center;" class="perhead">

			<img :src="'https://www.biaozhuncha.com/api/media/'+userinfo.info.headshot" alt="" v-if="userinfo" style="width: 70px;height: 70px;margin-left: 30px;border: 1px solid #e5e5e5;">
			<div style="margin-left: 20px;">
				<div style="font-size: 22px;color: #101010;">{{tel}}</div>
				<img src="../assets/images/personal/ptyh.png" alt="" style="width: 76px;height: 26px;margin-top: 10px;" v-if="permission=='普通用户'">
				<img src="../assets/images/personal/pthy.png" alt="" style="width: 76px;height: 26px;margin-top: 10px;" v-if="permission=='兑换会员'">
				<img src="../assets/images/personal/gjhy.png" alt="" style="width: 76px;height: 26px;margin-top: 10px;" v-if="permission=='月度会员'||permission=='年度会员'">
			</div>
		</div>
		<div style="display: flex;width: 1100px;margin-top: 30px;justify-content: space-between;">
			<div style="display: flex;flex-direction: column;" class="menubj">
				<div v-for="(item,index) in menu" :key="index">
					<div style="width: 100%;height: 60px;" class="menusb">
						<div style="width: 36px;height: 36px;display: flex;justify-content: center;align-items: center;">
							<img :src="item.imgs" :style="item.styles" />
						</div>
						<div style="margin-left: 18px;font-size: 20px;">{{item.name}}</div>
					</div>
					
					<div style="width: 100%;height: 50px;cursor: pointer;" class="menusb" v-for="(i,index2) in item.list" :key="i.id"  @click="cyclick(i,index2)" :class="{active: i.id==currentIndex}">
							<div style="width: 36px;height: 36px;display: flex;justify-content: center;align-items: center;">
								<img :src="i.img" :style="i.styles">
							</div>
							
							<div style="margin-left: 18px;">{{i.name}}</div>
				

					</div>
				</div>


				
			</div>
			<div style="width: 780px;height: 703px;background-color:#f8f8f8;border-radius: 10px;overflow: auto;">
				<router-view></router-view>
			</div>
		</div>






	

		<div style="width: 100%;height: 30px;"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				srcs: require('../assets/images/index/xx.png'),
				menu: [{
					styles: 'width:33px;height: 33px;',
					name: '常用功能',
					imgs: require('../assets/images/personal/cygn.png'),
					list: [{
							name: '我的消息',
							path: '/my_mess',
							img: require('../assets/images/index/xx.png'),
							styles: 'width:21px;height: 21px;',
							id:1,
						}, {
							name: '我的跟踪',
							path: '/my_track',
							img: require('../assets/images/index/gz.png'),
							styles: 'width:26px;height: 26px;',
				
							id:2,
						}, {
							name: '我的预约',
							path: '/my_his',
							img: require('../assets/images/personal/lsjl.png'),
							styles: 'width:22px;height: 22px;',
						
							id:3,
						},
						{
							name: '我的订阅',
							path: '/my_down',
							img: require('../assets/images/personal/xz.png'),
							styles: 'width:22px;height: 23px;',
						
							id:4,
						}, {
							name: '意见反馈',
							path: '/my_feed',
							img: require('../assets/images/personal/yjfk.png'),
							styles: 'width:21px;height: 21px;',
						
							id:5,
						}
					]
				},{
					name: '个人信息',
					imgs: require('../assets/images/personal/grxx.png'),
					styles: 'width:33px;height: 33px;',
					list: [{
						name: '账号信息',
						path: '/my_acc',
						img: require('../assets/images/personal/zhxx.png'),
						styles: 'width:24px;height: 24px;',
						id:6,
					},{
						name: '会员充值',
						path: '/pay',
						img: require('../assets/images/personal/zhxx.png'),
						styles: 'width:24px;height: 24px;',
						id:7,
					}]
				}],
				menu2: [{
					name: '个人信息',
					imgs: require('../assets/images/personal/grxx.png'),
					styles: 'width:33px;height: 33px;',
					list: [{
						name: '账号信息',
						path: 'mess1',
						img: require('../assets/images/personal/zhxx.png'),
						styles: 'width:24px;height: 24px;',
					}]
				}],
				currentIndex: 0,
				userinfo:'',
				header:'',
				tel:'',
				permission:'',
				xxIndex:Number
			}
		},
		mounted() {
			this.getinfo()
			
		},
		methods: {
			getinfo(){
				let token = JSON.parse(window.localStorage.getItem('token'))
				// console.log(token);
				if(token.info.telephone){
					this.$axios({
						url:'/push_info/',
						params:{
							input:token.info.telephone
						}
					}).then(res=>{
						// console.log(res);
						this.userinfo = res.data
						var reg = /^(\d{3})\d{6}(\d{2})$/
						this.tel =  res.data.info.telephone.replace(reg, '$1****$2')
						this.header = res.data.info.headshot
						this.permission =  res.data.info.permission__name
					})
				}
			},
			xxclick(item,i){
				this.xxIndex = i 
				// this.$router.push({path:item.path})
			},
			cyclick(item,i) {
				// console.log(item.id);
				this.currentIndex = item.id
			
					this.$router.push({path:item.path})
				
				
			},
			saveActivePath(activePath) {
				// console.log(activePath);
				this.activePath = activePath
			}
		}
	}
</script>

<style scoped="scoped">
	.el-submenu:hover {
		/* background-color: #000000; */
	}

	.el-menu-item:hover {
		color: yellow !important;
		background-color: #ffeae7 !important;
	}

	/* .el-menu-item.is-active {  
  color: #101010 !important;  
  background: #ffeae7 !important;  
} */
.perhead{
	background: url(../assets/images/personal/perheader.png) no-repeat;
	background-size: cover;
}
	.menubj {
		width: 288px;
		height: 703px;
		background: url(../assets/images/personal/menubj.png)no-repeat;
		background-size: cover;
		font-size: 16px;
		color: #101010;
	}

	.menusb {
		display: flex;
		align-items: center;
		padding: 20px;
	}

	.active {
		background-color: #ffeae7;
	}
</style>
