<template>
	<div style="width: 100%;height: 100%;border: 1px solid pink;">
		<!-- 绑定微信:{{code}} -->
		<div style="display: flex;justify-content: center;">
			<div style="color: #101010;font-weight: 600;font-size: 20px;margin-top: 30px;">请完成绑定</div>
		</div>
		<div style="width: 300px;height: 300px;margin: 0 auto;margin-top: 10%;">
			<div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0" >
				  <el-form-item  prop="phone">
				    <el-input v-model="ruleForm.phone" placeholder="请输入手机号">
						<i slot="prefix" class="iconfont icon-user" style="font-size: 18px;margin-left: 4px;"></i>
					</el-input>
				  </el-form-item>
				  <el-form-item prop="code">
				    <div style="display: flex;justify-content: center;align-items: center;">
						<el-input v-model="ruleForm.code" placeholder="请输入验证码">
							<i slot="prefix" class="iconfont icon-dunpaibaoxianrenzheng_o" style="font-size: 25px;"></i>
				
							
						</el-input>
						<div style="width: 30px;"></div>
						
							<button type="button" :disabled="disable" @click="getVerifyCode" style="width: 130px;height: 35px;color: #f29818;border: 1px solid #f29818;font-size: 12px;background-color: #FFFFFF;line-height: 35px;border-radius: 5px;">{{getCode}}</button>
						
						
					</div>
				  </el-form-item>
				</el-form>
				<button class="btnlogin" @click="register" style="margin-top:30px;">提交</button>
			</div> 
		</div>
		
		<div @click="codepath" style="color: #f38942;cursor: pointer;text-align: center;">其他登录方式</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				code:'',
				ruleForm:{
					phone:'',
					code:''
				},
				rules: {
				          phone: [
				            { required: true, message: '请输入手机号码', trigger: 'blur' },
				            { min: 11, max: 11, message: '请输入完整手机号', trigger: 'blur' }
				          ],
						  code: [
						    { required: true, message: '请输入验证码', trigger: 'blur' },
						    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
						  ]
				        },
				disable:false,
				getCode:'获取验证码',
				count:60,
				phoneflag:false,
				info1:{}
			}
		},
		mounted() {
			
			// this.code = this.$route.query.nums;
			// this.code = window.sessionStorage.getItem('codeinfo')
			// console.log(this.code);
			// console.log('绑定微信页面-------'+this.code);
			
			
			this.info1 = JSON.parse(window.sessionStorage.getItem('codeinfo1'))
			// console.log(info1);

		},
		methods:{
			codepath(){
				this.$router.push({path:'/code'})
			},
			getVerifyCode(){
				// console.log('获取');
				// const Time_Count = 60
				let phone = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
			          if(!phone.test(this.ruleForm.phone)){
						  this.$message.error('请输入正确手机号！')
					  }else{
						  var countDown = setInterval(()=>{
						    if(this.count < 1){
						    
						      this.disable = false;
						      this.getCode = '获取验证码';
						      this.count = 60;
						      clearInterval(countDown);
						    }else{
						  						
						      this.disable = true;
						      this.getCode = this.count-- + 's后重发';
						    }
						  },1000);
						  this.getcode()
					  }
			        },
			getcode(){
				// 手机号验证规则
				let phone = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
				if(this.ruleForm.phone==''||!phone.test(this.ruleForm.phone)){
					this.$message.error('请输入正确手机号！')
				}else{
					// console.log(this.ruleForm.phone);
					this.phoneflag = true
					this.$axios({
						url:'/get_code/',
						params:{
							phone:this.ruleForm.phone
						}
					}).then(res=>{
						// console.log(res);
						if(res.data.status==200){
							this.$message.success('发送成功')
						}else{
							this.$message.error('请稍后再试！')
						}
					}).catch(err=>{
						this.$message.error('请稍后再试！')
					})
				}
			},
			register(){
				let that = this
				// console.log(that.info1);
				let infos = JSON.stringify(that.info1)
				// console.log(infos);
				if(that.phoneflag==true){
					that.$axios({
						url:'/wxbind/',
						method:'post',
						data:{
							phone:that.ruleForm.phone,
							code:that.ruleForm.code,
							name:that.info1
						}
					}).then((res) => {
						// console.log(res);
						if (res.data.status == 404){
							that.$message.error(res.data.info)
						}
						if (res.data.status == 402){
							that.$message.error(res.data.info)
						}
						if (res.data.status == 401){
							that.$message.error(res.data.info)
						}
						if(res.data.status == 200){
							window.localStorage.setItem('token',JSON.stringify(res.data))
							this.$message.success('登录成功！')
							that.$router.push({path:'/search'})
						}
					}).catch((err) => {
						// console.log(err);
					});
				}else{
					that.$message.error('请填写正确信息！')
				}
			}
		}
	}
</script>

<style scoped="scoped">
	.btnlogin{
		width: 300px;
		border-radius: 5px;
		color: #ffffff;
		background-color: #f32727;
		height: 40px;
		line-height: 40px;
		margin: 0 auto;
	}
</style>
