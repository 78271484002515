<template>
	<div style="width: 100%;height: 100%;padding: 20px 30px 20px 30px;"> 
		<div style="display: flex;justify-content: center;align-items: center;width: 100%;height: 100%;" v-if="flag==true">
			<!-- <img src="../assets/images/personal/nogz.png" alt="" style="width: 268px;height: 206px;"> -->
			<el-empty description="暂时没有预约记录"></el-empty>
		</div>
		
		<div style="display: flex;flex-direction: column;align-items: center;" v-if="flag==false">
			<div v-for="(item,index) in list" :key="index" style="width: 700px;height: 80px;margin: 0 0 30px 0;padding: 10px;color: #101010;cursor: pointer;" class="gzbj" @click="godetail(item.pk_id)">
			<div style="display: flex;align-items: center;">
				<div>标准名称：{{item.c_c_name}}</div>
				<div style="margin-left: 50px;">标准号：{{item.c_std_code}}</div>
			</div>
			
			<div style="">
				<div style="margin-top: 15px;">预约日期：{{item.search_time.slice(0,10)}}</div>
			</div>
					
			</div>
			
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [],
				flag:true
			}
		},
		mounted() {
			this.getinfo()
		},
		methods: {
			godetail(pid){
				
								// console.log(r)
								let id =JSON.stringify(pid);
								console.log(id)
								
				// tag: 'a',
								const {href} = this.$router.resolve(
								{ 
								  path: '/detail',
								  query: {body: id}}
								)
								let token = JSON.parse(window.localStorage.getItem('token'))
								// console.log(token);
								if(token){
									window.open(href, '_blank')
									// console.log(this.currentPage);
								}else{
									this.$message.error('您还没有登录')
									setTimeout(()=>{
										this.$router.push({path:'/login'})
									},1200)
								}
								
								// this.$router.push({path:'/detail',query: {body:r}})
							
			},
			getinfo() {
				// let down = JSON.parse(localStorage.getItem("downlist"))
				// console.log(down);
				// if(down){
				// 	const res = new Map(); //定义常量 res,值为一个Map对象实例
				// 	//返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
				// 	this.downlist  = down.filter((arr) => !res.has(arr.pk_id) && res.set(arr.pk_id, 1))
				// 	this.falg = false
				// 	// console.log(this.downlist);
				// }
				
				// console.log('11111111111111111');
				this.$axios({
					url:'/text_list/',
					params:{
						text:'1'
					}
				}).then(res=>{
					console.log(res);
					
					if(res.data.status == 200){
						this.list = res.data.info
						this.flag = false
						console.log(this.list);
					}
					if(res.data.status == 201){
						this.flag = true
					}
				})
				
			}
		}
	}
</script>

<style scoped="scoped">
.gzbj{
	background: url(../assets/images/personal/gzbj.png)no-repeat;
	background-size: 100% 100%;
}
</style>
