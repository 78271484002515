<template>
	<div style="background-color: #f8f8f8;" class="about">
		<div class="header" >
			<div style="width: 100%;height: 40px;background-color: #fa6143;display: flex;align-items: center;justify-content: flex-end;">
				<div style="margin-right: 220px;color: #FFFFFF;font-size: 15px;display: flex;align-items: center;cursor: pointer;">
					<div class="iconfont icon-user" style="margin-right: 5px;"></div>
					<div v-if="tokens == false" @click="gologin">登录/注册</div>
					<div v-else @click="layout">退出</div>
				</div>
			</div>
			<Header></Header>
			<!-- <img src="../assets/images/detail/title.png" style="width: 355px;height: 37px;margin-right: 180px;"> -->
		</div>

		 <div style="width: 100%;display: flex;flex-direction: column;justify-content: space-around;align-items: center;">
			 <div style="width: 100%;height: 40px;"></div>
			 <div style="width: 1200px;height: 240px;" class="aboutimg">
				 <div style="margin: 30px 0 0 30px;">
					 <div style="font-size: 26px;color: #101010;font-weight: 600;">简介</div>
					 <div style="width: 30px;height: 4px;background-color: #f9a31c;"></div>
					 <div style="width: 850px;font-size: 16px;color: #101010;margin-top: 20px;line-height: 30px;">
						 标准查是北京慧制科技有限公司旗下APP，是国内首创集标准规范与产品智能查询为一体的一款APP，于2021年12月1日正式上线。“标准查”APP已收录标准信息100多万条，注册用户突破1万人次，VIP付费用户突破千人。
					 </div>
				 </div>
			 </div>
			 <div style="width: 100%;height: 30px;"></div>
			 <div style="width: 1200px;height: 310px;display: flex;justify-content: flex-end;align-items: center;" class="twoimg">
				 <div class="jianbian">
					 <div style="margin: 20px 0 0 20px;">
					 					 <div style="font-size: 26px;color: #101010;font-weight: 600;">产品文化</div>
					 					 <div style="width: 30px;height: 4px;background-color: #f9a31c;"></div>
					 					 <div style="font-size: 16px;color: #101010;margin-top: 20px;">使命：标准助力创新与发展</div>
					 					 <div style="font-size: 16px;color: #101010;margin-top: 20px;">愿景：做您忠诚的标准专家、产品建议家。</div>
					 </div>
				 </div>
			 </div>
			 
		 </div>
		 
		 <!-- <div @click="open">点击</div> -->
			 <div style="width: 100%;height: 55px;"></div>

		<Bottom></Bottom>

	</div>
</template>

<script>
	import Header from '../common/Headers.vue'
	import Bottom from '../common/Bottom.vue'
	export default {
		components:{
			Header,
			Bottom
		},
		data() {
			return {
				tokens:false,
			}
		},
		mounted() {
			this.loginout()
			
			

		},
		methods: {
			open(){
				
				this.$axios({
					url:'/try/'
				}).then(res=>{
					console.log(res)
					const div = document.createElement('div');
					div.innerHTML = res.data.info;
					document.body.appendChild(div);
				})
			},
			loginout(){
				let token = JSON.parse(window.localStorage.getItem('token'))
				// console.log(token);
				if(token==null){
					this.tokens = false
				}else{
					this.tokens = true
					// console.log(token);
					// this.headhost = 'https://www.biaozhuncha.com/api/media/'+token.info.headshot
				}
			},
			layout(){
				this.$axios({
					url:'/logout/'
				}).then(res=>{
					window.localStorage.removeItem('token')
					this.$message.success('退出成功')
					this.loginout()
				
				})
			},
			gologin(){
				this.$router.push({path:'/login'})
			},
		}
	}
</script>

<style scoped="scoped">
	@media screen and (max-width: 750px) {
		.about{
			width: 1920px;
		}
	}
	.header {
		background: url(../assets/images/detail/detailhead.png) no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		width: 100%;
		height: 220px;
	}

	.btn {
		width: 60px;
		height: 36px;
		text-align: center;
		border: 1px solid #f32727;
		outline: none;
		background-color: #FFFFFF;
		border-radius: 6px;
		color: #f32727;
	}
	.btn:hover{
		background-color: #f32727;
		color: #FFFFFF;
	}
>>> .el-step__head.is-success {
  color: #f32727;
  border-color: #f32727;
  font-size: 14px;
  font-weight: 400;
}
>>> .el-step__title.is-success {
    font-weight: bold;
    color: #f32727;
	font-size: 14px;
	font-weight: 400;
}
>>> .el-step__description.is-success {
    color: #f32727;
	font-size: 14px;
	font-weight: 400;
}


.hvers{
	color: #101010;
	text-decoration: none;
}

.hvers:hover{
	background-color: #F32727;
	color: #FFFFFF;
}
.harda{
	display: flex;justify-content: center;line-height: 60px;
}
.harda:hover{
	box-shadow: 0 0 10px #fdecec;
	border-radius: 10px;
	color: #0077AA;
}
.btnover{
	width: 280px;
	height: 60px;
	cursor: pointer;
	text-align: center;
	line-height: 60px;
	border: 1px solid #F32727;
	font-size: 16px;
	border-radius: 10px;
	color: #F32727;
}
.btnover:hover{
	background-color: #F32727;
	color: #FFFFFF;
}
.aboutimg{
	background: url(../assets/images/about/aboutone.png)no-repeat;
	background-size: cover;
	-webkit-background-size: cover;
}
.twoimg{
	background: url(../assets/images/about/abouttwo.png)no-repeat;
	background-size: cover;
	-webkit-background-size: cover;
}
.jianbian{
	width: 698px;
	height: 175px;
	background: url(../assets/images/about/header.png)no-repeat;
	background-size: cover;
	-webkit-background-size: cover;
}
</style>
