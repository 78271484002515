<template>
	<div style="background-color: #2d2d2d;color: #ffffff;align-self: flex-end;width:100%;padding-left: 1.6rem;padding-right: 1.6rem;display: flex;flex-direction: column;box-sizing: border-box;">
		<div style="display: flex;justify-content: space-between;margin-top: 0.2rem;font-size: 0.14rem;box-sizing: border-box;">
				
						
						<div>
							<p style="font-size: 0.2rem;" class="befores">联系我们</p>
							<div style="display: flex;margin-top: 0.2rem;">
									<img src="../assets/images/address.png" style="width: 0.17rem;height: 0.2rem;" >
									<p style="margin-left: 0.16rem;">北京市顺义区空港街道空港融慧园6号楼</p>
							</div>
							<div style="display: flex;margin-top: 0.05rem;">
								<img src="../assets/images/email.png" style="width: 0.22rem;height: 0.18rem;" >
								<p style="margin-left: 0.16rem;">kf@biaozhuncha.com</p>
							</div>
							<div style="display: flex;margin-top: 0.05rm;">
								<img src="../assets/images/phone.png" style="width: 0.2rem;height: 0.19rem;" >
								<p style="margin-left: 0.16rem;">4006785363</p>
							</div>
						</div>
						
						<div style="display: flex;align-items: center;justify-content: center;">
							<div style="width: 0.5px;height: 0.5rem;background-color: #FFFFFF;"></div>
						</div>
			
						
						<div style="width: 5.77rem;">
							<p style="font-size: 0.2rem;" class="befores">关于我们</p>
							<p style="margin-top: 0.2rem;line-height: 0.25rem;">北京慧制科技有限公司（简称“慧制”）成立于2016年02月，落地于北京市顺义区海高大厦。在智慧城市建设的大背景下，慧制专注于智慧城市标准制定、规划咨询、国际合作、核心技术产业转化等领域，致力于为我们的客户提供卓有成效的、不可替代的优质服务。</p>
						</div>
						<div style="display: flex;align-items: center;justify-content: center;">
							<div style="width: 0.5px;height: 0.5rem;background-color: #FFFFFF;"></div>
						</div>
		
						<div style="display: flex;flex-direction: column;align-items: center;">
							<img src="../assets/images/erweima.png" style="width: 1.07rem;height: 1.07rem;">
							<p style="margin-top: 0.2rem;">扫一扫  下载标准查APP</p>
						</div>
				</div>
				
		
				<div style="display: flex;align-items: center;justify-content: center;">
					<p style="font-size: 0.2rem;">友情链接：</p>
					<div>
						<a href="https://www.zcsmart.com/" target="_blank"><img src="../assets/images/zckj.png" style="width: 1rem;height: 0.38rem;margin-right: 0.1rem;"></a>
						<a href="http://www.antaiib.com/" target="_blank"><img src="../assets/images/antai.png" style="width: 1rem;height: 0.38rem;margin-right: 0.1rem;"></a>
						<a href="https://www.isoftstone.com/" target="_blank"><img src="../assets/images/tong.png" style="width: 1rem;height: 0.38rem;margin-right: 0.1rem;"></a>
						<a href="https://www.tencent.com/zh-cn" target="_blank"><img src="../assets/images/tencent.png" style="width: 1rem;height: 0.38rem;margin-right: 0.1rem;"></a>
						<a href="https://www.aliyun.com/" target="_blank"><img src="../assets/images/ali.png" style="width: 1rem;height: 0.38rem;margin-right: 0.1rem;"></a>
						<a href="http://www.sbcvc.com/" target="_blank"><img src="../assets/images/sbcvc.png" style="width: 1rem;height: 0.38rem;margin-right: 0.1rem;"></a>
					</div>
				</div>
				<div  style="font-size: 0.14rem;display: flex;align-items: center;justify-content: center;margin-top: 0.15rem;">
					<a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" style="color: #ffffff;"><span style="margin-right: 20px;">© Copyright 2020 by北京慧制科技有限公司</span>
					<span>京ICP备2021012552号</span></a>
				</div>
				<div style="width: 100%;height: 0.2rem;"></div>
	</div>
</template>

<script>
	export default{
		name:'Bottom'
	}
</script>

<style scoped>
	.befores{
			  position: relative;
	 }
	.befores:before{
			 content: "";/*单引号双引号都可以，但必须是英文*/
			     width: 0.4rem;
			     height: 1px; /*下划线高度*/
			     background: #FFFFFF; /*下划线颜色*/
			     position: absolute;
				 top: 110%;;
	}
</style>