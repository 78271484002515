<template>
	<div style="display: flex;flex-direction: column;align-items: center;width: 100%;height: 100%;position: relative;">
		<div>
			<img src="../assets/images/login/rightcolor.png" class="rightco">
		</div>
		<div style="display: flex;justify-content: center;align-items: center;margin-right: 0.2rem;">
			<div  v-for="(item,index) in loginme" :key="index" @click="changeIndex(index)">
				<div class="loginmeth" :class="{'activelogin':loginindex==index}" style="cursor: pointer;">{{item}}</div>
			</div>
		</div>
		<div class="quires">
			<!-- 验证码登录 -->
			<div v-if="loginindex==0" style="margin: 0 auto;">
				
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0" >
				  <el-form-item  prop="phone" style="width: 100%;height: 0.4rem;">
				    <el-input v-model="ruleForm.phone" placeholder="请输入手机号" style="font-size: 0.14rem;">
						<i slot="prefix" class="iconfont icon-user" style="font-size: 0.18rem;margin-left: 0.04rem;"></i>
					</el-input>
				  </el-form-item>
				  <el-form-item prop="code" >
				    <div style="display: flex;justify-content: center;align-items: center;">
						<el-input v-model="ruleForm.code" placeholder="请输入验证码" style="font-size: 0.14rem;">
							<i slot="prefix" class="iconfont icon-dunpaibaoxianrenzheng_o" style="font-size: 0.25rem;"></i>

							
						</el-input>
						<div style="width: 0.3rem;"></div>
						
							<button type="button" :disabled="disable" @click="getVerifyCode" style="width: 1.3rem;height: 0.35rem;color: #f29818;border: 0.01rem solid #f29818;font-size: 0.12rem;background-color: #FFFFFF;line-height: 0.35rem;border-radius: 0.05rem;">{{getCode}}</button>
						
						
					</div>
				  </el-form-item>
				</el-form>
				<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
					<button class="btnlogin" @click="loginclick">登录/注册</button>
					<div style="width: 3rem;font-size: 0.14rem;margin-top: 0.05rem;"><p>登录即同意<span class="usertext">用户协议</span>和<span class="usertext">隐私政策</span></p></div>
				</div>
			</div>
			
			<!-- 密码登录 -->
			<div v-if="loginindex==1">
				<el-form :model="passForm" :rules="passrules" ref="ruleFormPass" label-width="0" >
				  <el-form-item  prop="phone">
				    <el-input v-model="passForm.phone" placeholder="请输入手机号">
						<i slot="prefix" class="iconfont icon-user" style="font-size: 0.18rem;margin-left: 0.04rem;"></i>
					</el-input>
				  </el-form-item>
				  <el-form-item prop="pass">
				    <div style="display: flex;justify-content: center;align-items: center;">
						<el-input v-model="passForm.pass" placeholder="请输入密码" show-password>
							<i slot="prefix" class="iconfont icon-lock" style="font-size: 0.2rem;margin-left: 0.03rem;"></i>
						</el-input>
					</div>
				  </el-form-item>
				</el-form>
				<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
					<button class="btnlogin" @click="loginpass">登录</button>
					<div style="width: 3rem;font-size: 0.14rem;margin-top: 0.05rem;"><p>登录即同意<span class="usertext">用户协议</span>和<span class="usertext">隐私政策</span></p></div>
				</div>
				
			</div>
			<div v-if="loginindex==2">
				<!-- <router-view></router-view> -->
					
				</div>
		</div>
		<img src="../assets/images/login/wxlogin.png" style="width: 0.3rem;cursor: pointer;" @click="gowx" class="wx">
		<div @click="topath" style="cursor: pointer;margin-top: 0.5rem;font-size: 0.14rem;">忘记密码？<span style="color: #f38942;cursor: pointer;">迅速找回</span></div>
		
	
			<img src="../assets/images/login/scanimgs.png" class="scans" @click="appscan">
	

		<!-- <div @click="open">微信登录</div> -->
	</div>
</template>

<script>
	export default{
		data(){
			return{
				loginme:['验证码登录','密码登录'],
				loginindex:0,
				disable:false,
				getCode:'获取验证码',
				passForm:{
					phone:'',
					pass:''
				},
				ruleForm:{
					phone:'',
					code:''
				},
				rules: {
				          phone: [
				            { required: true, message: '请输入手机号码', trigger: 'blur' },
				            { min: 11, max: 11, message: '请输入完整手机号', trigger: 'blur' }
				          ],
						  code: [
						    { required: true, message: '请输入验证码', trigger: 'blur' },
						    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
						  ]
				        },
				passrules: {
				          phone: [
				            { required: true, message: '请输入手机号码', trigger: 'blur' },
				            { min: 11, max: 11, message: '请输入完整手机号', trigger: 'blur' }
				          ],
						  pass: [
						    { required: true, message: '请输入密码', trigger: 'blur' },
						    { min: 6, max: 18, message: '请输入6-18个字符的密码', trigger: 'blur' }
						  ]
				        },
				isGeting:false,
				count:60,
				timer:null,
				phoneflag:false
			}
		},
		created() {
			// const codes = window.sessionStorage.getItem('code')
			const codestatus = window.sessionStorage.getItem('codestatus')
			if(codestatus == 403){
				this.$router.push({path:'/wxbind'})
			}
			if(codestatus == 200){
				this.$message.success('登录成功！')
				this.$router.push({path:'/search'})
			}
		},
		destroyed() {
			window.sessionStorage.removeItem('code')
			window.sessionStorage.removeItem('codestatus')
		},
		methods:{
			appscan(){
				this.$router.push({path:'/scanapp'})
			},
			topath(){
				this.$router.push({path:'/changepass'})
			},
			changeIndex(i){
				this.loginindex = i
				if(i==2){
					this.$router.push({path:'/wxlogin'})
				}
			},
			gowx(){
				this.$router.push({path:'/wxlogin'})
			},
			 getVerifyCode(){
				// console.log('获取');
				// const Time_Count = 60
				let phone = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
			          if(!phone.test(this.ruleForm.phone)){
						  this.$message.error('请输入正确手机号！')
					  }else{
						  var countDown = setInterval(()=>{
						    if(this.count < 1){
						    
						      this.disable = false;
						      this.getCode = '获取验证码';
						      this.count = 60;
						      clearInterval(countDown);
						    }else{
						  						
						      this.disable = true;
						      this.getCode = this.count-- + 's后重发';
						    }
						  },1000);
						  this.getcode()
					  }
			        },
			getcode(){
				// 手机号验证规则
				let phone = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
				if(this.ruleForm.phone==''||!phone.test(this.ruleForm.phone)){
					this.$message.error('请输入正确手机号！')
				}else{
					// console.log(this.ruleForm.phone);
					this.phoneflag = true
					this.$axios({
						url:'/get_code/',
						params:{
							phone:this.ruleForm.phone
						}
					}).then(res=>{
						// console.log(res);
						if(res.data.status==200){
							this.$message.success('发送成功')
						}else{
							this.$message.error('请稍后再试！')
						}
					}).catch(err=>{
						this.$message.error('请稍后再试！')
					})
				}
			},
			loginclick(){
				if(this.phoneflag==false){
					this.$message.error('您需要先获取验证码！')
				}
				if(this.phoneflag==true&&this.ruleForm.code!=''){
					// console.log('lll')
					this.$axios({
						url:'/sign_in/',
						params:{
							phone:this.ruleForm.phone,
							code:this.ruleForm.code
						}
					}).then(res=>{
						// console.log(res);
						if(res.data.status==403){
							this.$message.error('验证码错误！')
						}
						if(res.data.status==200){
							this.$message.success('登录成功！')
							window.localStorage.setItem('token',JSON.stringify(res.data))
							// this.$router.push({path:'/search'})
							this.$router.go(-1);//返回上一层
						}
					})
				}
				
			},
			open(){
					
					this.$router.push({path:'/wxlogin'})
			},
			loginpass(){
				let phone = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
				if(this.ruleForm.pass==''||!phone.test(this.passForm.phone)){
					this.$message.error('请输入正确信息！')
				}else{
					// console.log(this.passForm);
					this.$axios({
						url:'/sign_in/',
						params:{
							phone:this.passForm.phone,
							pwd:this.passForm.pass
						}
					}).then(res=>{
						// console.log(res);
						if(res.data.status==200){
							this.$message.success('登录成功！')
							window.localStorage.setItem('token',JSON.stringify(res.data))
							// window.localStorage.setItem('local','123456798')
							// this.$router.push({path:'/search'})
							this.$router.go(-1);//返回上一层
						}
						if(res.data.status==404){
							this.$message.error('账号或密码错误')
							 
						}
						if(res.data.status==405){
							this.$message.error(res.data.info)
							 
						}
					})
				}
			}
		}
	}
</script>

<style scoped="scoped">
		
	@media screen and (max-width: 750px) {
		.wx{
			display: none;
		}
	}
	.loginmeth{
		font-size: 0.16rem;
		color: #666666;
		margin-left: 0.2rem;
	}
	.activelogin{
		color: #101010;
		font-weight: 600;
		font-size: 0.2rem;
	}
	.quires{
		width: 3rem;
		height: 2.4rem;
		/* border: 1px solid green; */
		margin-top: 0.2rem;
		/* display: flex;
		flex-direction: column; */
		/* align-items: center; */
	}
	.btnlogin{
		width: 3rem;
		border-radius: 5px;
		color: #ffffff;
		background-color: #f32727;
		height: 0.4rem;
		line-height: 0.4rem;
		margin-top: 0.3rem;
		margin: 0 auto;
	}
	.usertext{
		color: #f38942;
		margin: 0 5px 0 5px;
	}
	.rightco{
		/* width: 110px; */
		height: 0.4rem;
		transform: translate(1.76rem);
		margin-top: 0.2rem;
	}
	.scans{

		/* border: 1px solid pink; */
		width: 0.85rem;
		height: 0.85rem;
		position:absolute;
		right: 0;
		bottom: 0;
		-webkit-transition: margin 0.5s ease-out;      
		-moz-transition: margin 0.5s ease-out;      
		-o-transition: margin 0.5s ease-out; 
		border-radius: 0 0 0.3rem 0;
		cursor: pointer;
	}
 	

</style>
