<template>
	<div class="searchbj">
		<Tablist></Tablist>
		<div style="display: flex;justify-content: center;align-items: center;cursor:pointer;margin-top: 20px;">
		
				<div v-for="(item,index) in options" :key="index" @click="tolink(item,index)" class="opcss" :class='{opbj:index==opindex}'>{{item}}</div>
				
		</div>
		<div style="display: flex;justify-content: center;margin-top: 30px;" >
		<!-- @keyup.enter="search1" -->
		
			<div style="width: 900px;height: 55px;border-radius: 10px;display: flex;justify-content: space-between;align-items: center;background-color: #ffffff;overflow: hidden;">
			<input type="text" style="margin-left: 20px;width: 800px;background-color:transparent" placeholder="请输入规范信息并选择分类查询"  v-model="inpv" @blur="onblursave" @keyup.enter="enterfun">
				<img src="../assets/images/searchicon.png" @click="search1" style="width: 36px;height: 36px;margin-right: 20px;cursor:pointer;">
			<!-- <p class="el-icon-search" style="font-size: 20px;margin-right: 20px;cursor:pointer;" @click="search1"></p> -->
			</div>
		</div>
		
		<!-- 搜索参数 -->
		<div style="display: flex;justify-content: flex-start;overflow: hidden;margin-top: 30px;">
			<div style="width: 20%;"></div>
			<div style="display: flex;flex-direction: column;justify-content: center;align-items: flex-start;">
				<div style="display: flex;justify-content: center;margin-top: 20px;">
					<div style="display: flex;align-items: center;">
						<p  style="font-size: 16px;color: #333333;margin-right: 10rpx;font-weight: 800;">分类</p>
						
						<div v-for="(item,index) in fenlei" :key="index" style="width: 80px;height: 30px;line-height: 30px;margin-left: 20px;cursor:pointer;" @click="fenClick(item,index)" class="searchcss" :class='{flbj:index==fencurrent}'>
							<div style="text-align: center;font-size: 26rpx;">{{item}}</div>
						</div>
					</div>
				</div>
				
			<div style="display: flex;justify-content: center;margin-top: 20px;">
				<div style="display: flex;align-items: center;">
					<p  style="font-size: 16px;color: #333333;margin-right: 10rpx;font-weight: 800;">文件</p>
					
					<div v-for="(item,index) in file" :key="index" style="width: 80px;height: 30px;line-height: 30px;margin-left: 20px;cursor:pointer;" @click="fileClick(item,index)" class="searchcss" :class='{flbj:index==filecurrent}'>
						<div style="text-align: center;font-size: 26rpx;">{{item}}</div>
					</div>
				</div>
			</div>
				
				<div style="display: flex;justify-content: center;margin-top: 20px;">
					<div style="display: flex;align-items: center;">
						<p  style="font-size: 16px;color: #333333;margin-right: 10rpx;font-weight: 800;">时间</p>
					
						<div v-for="(item,index) in timer" :key="index" style="width: 80px;height: 30px;line-height: 30px;margin-left: 20px;cursor:pointer;" @click="timeClick(item,index)" class="searchcss" :class='{flbj:index==timecurrent}'>
							<div style="text-align: center;font-size: 26rpx;">{{item}}</div>
						</div>
					</div>
				</div>
				
				
				
			</div>
		</div> 
		
		<div class="table">
		<el-table :data="searchData" style="margin-top: 30px;width: 100%;" align="center" :header-cell-style="{backgroundColor: 'rgb(255,230,162)',color:'#101010',width: '100%',borderRight:'2px solid #ffffff',textAlign:'center',borderRadius:'3px',opacity:'.8',fontWeight:'550',height:'40px',fontSize:'16px'}" stripe :cell-style="TableRowClassName">
		 		  <el-table-column
		 		        type="index"
		 				label="序号"
		 		        width="100"
							align="center">
		 		      </el-table-column>
		  <!--  <el-table-column
		      prop="num"
		      label="规范号"
		      width="170"
				   align="center">
		    </el-table-column> -->
		 		<el-table-column
		 		   prop="type"
		 		   label="规范分类"
		 		   width="350"
					   align="center">
		 		 </el-table-column>
		    <el-table-column
		      prop="c_c_name"
		      label="规范名称"
		      width="400"
		      :show-overflow-tooltip="true"
				   align="center">
		    </el-table-column>
		 		<!-- <el-table-column
		 		   prop="state"
		 		   label="状态"
		 		   width="120"
					   align="center">
		 		 </el-table-column> -->
		 		 
		   
		  <!--  <el-table-column
		      prop="issue_date"
		      label="发布日期"
		      width="140"
				   align="center">
		    </el-table-column> -->
		 		<!-- <el-table-column
		 		   prop="act_date"
		 		   label="实施日期"
		 		   width="140"
					   align="center">
		 		 </el-table-column> -->
		 
		 		 <el-table-column
		 		      
		 		       label="操作"
		 		       width="200"
						   align="center">
		 		       <template slot-scope="scope">
		 		         <!-- <el-button @click="toDetail(scope.row)" type="text" size="small">查看</el-button> -->
		 		         <!-- <el-button type="text" size="small">分享</el-button> -->
							 <i class="iconfont icon-yulanchakan iconhover" style="font-size: 26px;cursor: pointer;" @click="toDetail(scope.row)"></i>
							<!-- <i class="iconfont icon-fenxiang iconhover" style="font-size: 26px;margin-left: 5px;cursor: pointer;" @click="share(scope.row)"></i> -->
		 		       </template>
		 		     </el-table-column>
		 		 
		    </el-table>
			<div style="margin-top: 20px;">
				<el-pagination
				    layout="prev, pager, next,jumper"
										background
									   :current-page="currentPage"
									   :page-count="totals"
									   :page-size="20"
									   @current-change="handleSizeChange"
									   align="center"
									   id="pages"
									   >
				  </el-pagination>
			</div>
				
					 
				 </div>
		<!-- <div @click="gfdetail">dkjg</div> -->
	</div>
</template>

<script>
	import Tablist from '../common/Tab.vue'
	export default{
		components:{
			Tablist,
		},
		data(){
			return{
				inpv:'',
				//分类
				fenValue:'全部',
				//日期
				timeValue:'默认',
				// 当前页数
				currentPage:1,
				// 总条数
				totals:1,
				fenlei:['全部','药品','化妆品','交通','建筑','其他'],
				file:['全部','白皮书','通知','指南','规范','其他'],
				timer:['默认','最新'],
				fencurrent:0,
				timecurrent:0,
				totals:1,
				searchData:[],
				//文件
				filetitle:'全部',
				filecurrent:0,
				options:['查标准','查产品','查规范'],
				opindex:2,
			}
		},
		mounted() {
			
		},
		created() {
			let inv = JSON.parse(window.sessionStorage.getItem('searchgf'))
			this.inpv = inv
			// console.log(inv);
			if(inv==null||this.inpv==''){
				// console.log('没有值')
				window.sessionStorage.setItem('searchgf',JSON.stringify('灭活疫苗'))
				this.$axios({
					url: '/querylist_med/',
					params: {
						input: '灭活疫苗',
						page_num:20,
						type:this.fenValue,
						time:this.timeValue,
						file:this.filetitle
					}
				}).then(res=>{
					// console.log(res);
					if(res.data.info.status==404||res.data.status==404){
						
							
							this.$message.error('暂无搜索结果')
					}else{
						this.searchData = res.data.info
						// window.sessionStorage.setItem('searchData',JSON.stringify(res.data.info))
							let a = res.data.count
							let b = res.data.info.length
							// console.log(a,b);
							// console.log(a/b);
							let c = a/b
						
							this.totals =Math.ceil(c)
					
					}
				}).catch(err=>{
					// console.log(err);
				})
			}else{
				// console.log('有值')
				
				let inv =	JSON.parse(window.sessionStorage.getItem('searchgf'))
				this.inpv = inv
				// console.log(inv);
					if(inv==''){
						// this.$message.error('请您输入内容后搜索')
						
					}else{
						this.$axios({
							url: '/querylist_med/',
							params: {
								input: inv,
								page_num:20,
								type:this.fenValue,
								time:this.timeValue,
								file:this.filetitle
							}
						}).then(res=>{
							// console.log(res);
							if(res.data.info.status==404||res.data.status==404){
								
									
									this.$message.error('暂无搜索结果')
							}else{
								this.searchData = res.data.info
								// window.sessionStorage.setItem('searchData',JSON.stringify(res.data.info))
									let a = res.data.count
									let b = res.data.info.length
									// console.log(a,b);
									// console.log(a/b);
									let c = a/b
								
									this.totals =Math.ceil(c)
							
							}
						}).catch(err=>{
							// console.log(err);
						})
					}
			}
			
			
		},
		methods:{
			tolink(item,index){
				this.opindex = index
				// console.log(this.opindex)
				if(item=="查标准"){
					this.$router.push({path:'/search'})
				}
				if(item=="查产品"){
					this.$router.push({path:'/product'})
				}
				if(item=="查规范"){
					this.$router.push({path:'/searchgf'})
				}
			},
			enterfun(){
				window.sessionStorage.setItem('searchgf',JSON.stringify(this.inpv))
				this.search1()
			},
			handleSizeChange(val){
				// console.log(val);
				let nums = val*20
				let inv = JSON.parse(window.sessionStorage.getItem('searchgf'))
				// console.log(nums);
				
				// if(this.inpv==''){
				// 	this.$message.error('请您输入内容后搜索')
				// }else{
					// console.log(inv);
					this.$axios({
						url: '/querylist_med/',
						params: {
							input: inv,
							page_num:nums,
							type:this.fenValue,
							time:this.timeValue,
							file:this.filetitle
						}
					}).then(res=>{
						// console.log(res);
						this.searchData = res.data.info
						// this.backTop()
					}).catch(err=>{
						// console.log(err);
					})
				// }
				
			},
			TableRowClassName({ row, column, rowIndex, columnIndex }){
				if(rowIndex%2==1){
					return 'background:rgba(255,221,162,.2)'
				}else{
					return ''
				}
			},
			fileClick(item,index){
				this.filetitle = item
				this.filecurrent = index
				// console.log(this.filetitle);
				let inv = JSON.parse(window.sessionStorage.getItem('searchgf'))
				if(inv==''){
					this.$message.error('请您输入内容后搜索')
				}else{
					this.search1()
				}
			},
			timeClick(item,index){
				this.timeValue = item
				this.timecurrent =index
				let inv = JSON.parse(window.sessionStorage.getItem('searchgf'))
				if(inv==''){
					this.$message.error('请您输入内容后搜索')
				}else{
					this.search1()
				}
				
			},
			fenClick(item,index){
				this.fenValue = item
				this.fencurrent =index
				let inv = JSON.parse(window.sessionStorage.getItem('searchgf'))
				if(inv==''){
					this.$message.error('请您输入内容后搜索')
				}else{
					this.search1()
				}
			},
			
			onblursave(){
				// console.log(this.inpv);
				window.sessionStorage.setItem('searchgf',JSON.stringify(this.inpv))
			},
			search1(){
				let inv =	JSON.parse(window.sessionStorage.getItem('searchgf'))
				// console.log(inv);
				// console.log(this.fenValue)
				// console.log(this.timeValue)
					if(inv==''){
						this.$message.error('请您输入内容后搜索')
					}else{
						this.$axios({
							url: '/querylist_med/',
							params: {
								input: inv,
								page_num:20,
								type:this.fenValue,
								time:this.timeValue,
								file:this.filetitle
							}
						}).then(res=>{
							// console.log(res);
							if(res.data.info.status==404||res.data.status==404){
									this.$message.error('暂无搜索结果')
							}else{
								this.$message.success('加载成功')
								this.searchData = res.data.info
								// window.sessionStorage.setItem('searchData',JSON.stringify(res.data.info))
									let a = res.data.count
									let b = res.data.info.length
									// console.log(a,b);
									// console.log(a/b);
									let c = a/b
								
									this.totals =Math.ceil(c)
							
							}
						}).catch(err=>{
							// console.log(err);
						})
					}
			},
			toDetail(r){
				let id =JSON.stringify(r);
				// console.log(id)
				
				
				
				let token = JSON.parse(window.localStorage.getItem('token'))

				// console.log(token);
				if(token){
					if(r.pk_id){
						const {href} = this.$router.resolve(
						{ 
						  path: '/detail',
						  query: {body: id}}
						)
						window.open(href, '_blank')
					}
					if(r.pk_id_me){
						const {href} = this.$router.resolve(
						{ 
						  path: '/gfdetail',
						  query: {body: id}}
						)
						window.open(href, '_blank')
					}
					// console.log(this.currentPage);
				}else{
					this.$message.error('您还没有登录')
					setTimeout(()=>{
						this.$router.push({path:'/login'})
					},1200)
				}
			},
			gfdetail(){

				const {hrefgf} = this.$router.resolve(
				{ 
				  path: '/gfdetail',
				  }
				)
				// let token = JSON.parse(window.sessionStorage.getItem('token'))

			
					window.open(hrefgf, '_blank')
			
				
				
			}
		},
	}
</script>

<style scoped="scoped">
	.searchbj{
		
	}
	.searchcss{
		font-size: 14px;
	}
	.flbj{
		background: url(../assets/images/quire.png) no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		background-position: center;
		border-radius: 5px;
		color: #FFFFFF;
	}
	.tableStyle{
		background-color: #000000;
	}
	.el-table__header-wrapper{
		background-color: #000000;
	}
	.table .el-table::before{
	    background-color: #FFFFFF;
	  }
	.table /deep/  .el-table, .el-table__expanded-cell {
	    background-color: transparent;
	}
	.el-table__row.statistics-warning-row {
	background: #F7FFFE;
	}
	.iconhover:hover{
		color: red;
	}
	
	.opend{
		font-family: jinnan;
		font-size: 16px;
		color: #3F94FB;
	}
	.opcss{
		font-size: 30px;
		color: #FFFFFF;
		width: 160px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.opbj{
		position: relative;
		/* color: red; */
	}
	.opbj:before{
		content: "";/*单引号双引号都可以，但必须是英文*/
		 
		    width: 50px;
		 
		    height: 1.5px; /*下划线高度*/
		 
		    background: #FFFFFF; /*下划线颜色*/
		 
		    position: absolute;
		 
		    top: 110%;
		 
		    left: 35%;
		 
		    transition: all .8s ; /*css动画效果，0.8秒完成*/
	}
</style>
<style>
	#pages.is-background .el-pager li:not(.disabled).active  {
	  background-color: #f32727;  
	}
</style>
