<template>
	<div style="background-color: #f8f8f8;" class="content">
		
		<div style="display: flex;justify-content: center;">
			<div style="width: 100%;background-color: #ffffff;margin-top: 40px;border-radius: 5px;">
				<div style="margin-top: 30px;">
					<!-- 头部信息展示 -->
					<!-- <div style="display: flex;justify-content: space-between;align-items: center;"> -->
					<div style="display: flex;align-items: flex-start;flex-direction: column;">

						<div style="display: flex;align-items: center;">
							<div style="width: 2px;height: 30px;background-color: #f9a31c;"></div>
							<div style="margin-left: 16px;">
								<div style="font-size: 18px;color: #101010;">{{search.c_c_name}}</div>
								<div style="font-size: 14px;color: #4a4b4b;margin-top: 10px;">{{search.eu_name}}</div>
							</div>
						</div>
							<div style="display: flex;justify-content: space-between;align-items: center;margin: 0.05rem 0 0 0.2rem;">
								<div style="margin-right: 5px;" v-if="search.types">
									<div style="background-color: #24c4ff;width: 70px;height: 28px;line-height: 28px;text-align: center;border-radius: 6px;">
										<p style="color: #FFFFFF;">{{search.types}}</p>
									</div>
								</div>
								<div style="margin-right: 0.05rem;" v-if="search.std_nature">
									<div style="background-color: #ff9024;width: 70px;height: 28px;line-height: 28px;text-align: center;border-radius: 6px;">
										<p style="color: #FFFFFF;">{{search.std_nature}}</p>
									</div>
								</div>
								<div style="margin-right: 30px;" v-if="search.state">
									<div style="background-color: #56d176;width: 70px;height: 28px;line-height: 28px;text-align: center;border-radius: 6px;">
										<p style="color: #FFFFFF;">{{search.state}}</p>
									</div>
								</div>
							</div>
					</div>
					<!-- 功能按钮 -->
					<div style="display: flex;margin-left: 0.2rem;margin-top: 0.16rem;">
						 <button  class="btn2" @click="down" v-if="search.content!=''&&search.content!=null">PDF</button>
						<button  class="btn2" style="margin-left: 0.2rem;" @click="genzong" v-if="search.trace==0">跟踪</button> 
						<button  class="btn3" style="margin-left: 0.2rem;" v-else>已跟踪</button> 
						<button  class="btn2" @click="yyclick" style="margin-left: 0.2rem;" v-if="search.content==''||search.content==null">预约</button>
						<!-- <button type="button" class="btn2">下载</button> -->
					</div>
				
						<div style="display: flex;justify-content: center;margin-top: 30px;">
							<div style="width: 11.5rem;height: 1px;background-color: #e5e5e5;"></div>
						</div>
				
				</div>
				
				<!-- 目录 -->
				
				<div style="display: flex;margin-top: 0.5rem;margin-left: 30px;justify-content: center;">
					<!-- <div style="width: 195px;height: 50px;border: 1px solid #f32727;display: flex;justify-content: center;line-height: 50px;border-radius:10px 0 0 10px;" class="hvers"> -->
							<a href="#state" style="width: 1.95rem;height: 0.5rem;border: 1px solid #f32727;display: flex;justify-content: center;line-height: 0.5rem;border-radius:0.1rem 0 0 0.1rem;font-size: 0.18rem;"  class="hvers">标准状态</a>
					<!-- </div> -->
					<a href="#other" style="width: 1.95rem;height: 0.5rem;border: 1px solid #f32727;display: flex;justify-content: center;line-height: 0.5rem;font-size: 0.18rem;"  class="hvers">基础信息</a>
					<a href="#man" style="width: 1.95rem;height: 0.5rem;border: 1px solid #f32727;display: flex;justify-content: center;line-height: 0.5rem;font-size: 0.18rem;"  class="hvers">起草人</a>
					
					<a href="#danwei" style="width: 1.95rem;height: 0.5rem;border: 1px solid #f32727;display: flex;justify-content: center;line-height: 0.5rem;border-radius:0 0.1rem 0.1rem 0;font-size: 0.18rem;"  class="hvers">起草单位</a>
				</div>
				
				<div v-if="search.state!='被代替'">
					<div style="font-size: 0.18rem;color: #101010;font-weight: 600;margin-left: 30px;margin-top: 30px;" id="state">标准状态</div>
					<div style="margin-top: 30px;">
						<el-steps :active="1" align-center finish-status="success" v-if="search.state=='即将实施'">
						  <el-step :title="'发布于'+search.issue_date" ></el-step>
						  <el-step title="实施" icon="el-icon-refresh"></el-step>
						  <el-step title="废止" icon="el-icon-refresh"></el-step>
						</el-steps>
						<el-steps :active="2" align-center finish-status="success" v-if="search.state=='现行'">
						  <el-step :title="'发布于'+search.issue_date" ></el-step>
						  <el-step :title="'实施'+search.act_date" icon="el-icon-refresh"></el-step>
						  <el-step title="废止" icon="el-icon-refresh"></el-step>
						</el-steps>
						<el-steps :active="0" align-center finish-status="success" v-if="search.state=='正在起草'">
						  <el-step title="发布" ></el-step>
						  <el-step title="实施" icon="el-icon-refresh"></el-step>
						  <el-step title="废止" icon="el-icon-refresh"></el-step>
						</el-steps>
						<el-steps :active="3" align-center finish-status="success" v-if="search.state=='废止'">
						  <el-step :title="'发布于'+search.issue_date" ></el-step>
						  <el-step :title="'实施'+search.act_date" icon="el-icon-refresh"></el-step>
						  <el-step :title="'废止'+search.abo_date" icon="el-icon-refresh" v-if="search.abo_date"></el-step>
						  <el-step title="废止" icon="el-icon-refresh" v-else></el-step>
						</el-steps>
						
						<!-- <el-steps :active="4" align-center finish-status="success" v-if="search.state=='被代替'">
						  <el-step :title="'发布于'+search.issue_date" ></el-step>
						  <el-step :title="'实施'+search.act_date" icon="el-icon-refresh"></el-step>
						  <el-step :title="'废止'+search.abo_date" icon="el-icon-refresh" v-if="search.abo_date"></el-step>
						  <el-step title="废止" icon="el-icon-refresh" v-else></el-step>
						  <el-step title="被代替" icon="el-icon-refresh"></el-step>
						</el-steps> -->
					</div>
				</div>
				
				<!-- 基础信息 -->
				<div style="margin-top: 0.6rem;">
					<div style="font-size: 0.18rem;color: #101010;font-weight: 600;margin-left: 30px;margin-top: 0.5rem;" id="other">基础信息</div>
					
					<div style="display: flex;justify-content: center;align-items: center;margin-top: 0.2rem;">
						<div style="width: 5.72rem;height: 0.46rem;background-color: #f3f3f3;line-height: 0.46rem;border-radius: 0.1rem 0 0 0;">
							<p style="font-size: 0.14rem;color: #4a4b4b;margin-left: 0.2rem;">标准号：{{search.c_std_code}}</p>
							
						</div>
						<div style="width: 5.72rem;height: 0.46rem;background-color: #f3f3f3;line-height: 0.46rem;margin-left: 1px;border-radius: 0 10px 0 0;">
							<p style="font-size: 0.14rem;color: #4a4b4b;margin-left: 0.2rem;">中国标准分类号（CCS）：{{search.ch_no}}</p>
					
						</div>
					</div>
					<div style="display: flex;justify-content: center;align-items: center;margin-top: 2px;">
						<div style="width: 5.72rem;height: 0.46rem;background-color: #f3f3f3;line-height: 0.46rem;">
							<p style="font-size: 0.14rem;color: #4a4b4b;margin-left: 0.2rem;">发布日期：{{search.issue_date}}</p>
							
						</div>
						<div style="width: 5.72rem;height: 0.46rem;background-color: #f3f3f3;line-height: 0.46rem;margin-left: 1px;">
							<p style="font-size: 0.14rem;color: #4a4b4b;margin-left: 0.2rem;">国际标准分类号（ICS）：{{search.eu_no}}</p>
							
						</div>
					</div>
					<div style="display: flex;justify-content: center;align-items: center;margin-top: 2px;">
						<div style="width: 5.72rem;height: 0.46rem;background-color: #f3f3f3;line-height: 0.46rem;">
							<p style="font-size: 0.14rem;color: #4a4b4b;margin-left: 0.2rem;">实施日期：{{search.act_date}}</p>
							
						</div>
						<div style="width: 5.72rem;height: 0.46rem;background-color: #f3f3f3;line-height: 0.46rem;margin-left: 1px;">
							<p style="font-size: 0.14rem;color: #4a4b4b;margin-left: 0.2rem;" >归口单位：{{search.department}}</p>
							
						</div>
					</div>
					<div style="display: flex;justify-content: center;align-items: center;margin-top: 2px;">
						<div style="width: 5.72rem;height: 0.46rem;background-color: #f3f3f3;line-height: 0.46rem;border-radius: 0 0 0 10px;"></div>
						<div style="width: 5.72rem;height: 0.46rem;background-color: #f3f3f3;line-height: 0.46rem;margin-left: 1px;border-radius: 0 0 10px 0;">
							<p style="font-size: 0.14rem;color: #4a4b4b;margin-left: 0.2rem;">发布单位：{{search.act_department}}</p>
						</div>
					</div>
				</div>
				<div style="font-size: 18px;color: #101010;font-weight: 600;margin-left: 30px;margin-top: 50px;" id="man">起草人</div>
				<div v-for="(item,index) in drafter" :key="index" style="width: 950px;margin-left: 30px;margin-top: 0.2rem;" @click="draftergo(item)">
					<div style="width: 90px;height: 60px;float: left;margin: 10px;border-radius: 10px;border: 1px solid #ebebeb;cursor: pointer;">
						<div class="harda">
							<div class="iconfont icon-user" style="width: 15px;height: 14px;"></div>
							<div style="margin-left: 5px;">{{item}}</div>
						</div>
					</div>
				</div>
				<!-- 起草人 -->
				<div style="width: 100%;height: 0.5rem;clear: both;"></div>
				<div style="clear: both;font-size: 0.18rem;color: #101010;font-weight: 600;margin-left: 30px;" id="danwei" @click="unit(un,index)">起草单位</div>
				
				<div style="margin-top: 0.2rem;">
					<!-- <table style="width: 100%;margin-left: 30px;">
					    <template v-for='(todo,index) in danwei' v-if='index%2==0'> 
						  <tr @click="unit(todo,index)">
						    <td style="padding: 0 10px 10px 0;border-right: 1px solid #ebebeb;cursor: pointer;" >
						      {{todo}}
							  
						    </td>
					      <td v-if='index+1<danwei.length' style="padding-left: 30px;cursor: pointer;">
					        {{danwei[index+1]}}
					      </td>
					    </tr>
					    </template>
					  </table> -->
					
							<div style="margin-left: 30px;">
								<div v-for='(un,index) in danwei' >
									<div style="float: left;width: 5.7rem;margin-top: 10px;cursor: pointer;" class="dafterunit" @click="unit(un,index)">{{un}}</div>
								</div>
		
							</div>
				
				</div>
				<!-- <button @click="patg">技术委员会</button> -->
				<!-- <button type="button" @click="openlogin">登录</button> -->
			<div style="width: 100%;height: 30px;clear: both;"></div>
				<div style="display: flex;justify-content: center;clear: both;">
					<div class="btnover" style="margin-right: 0.2rem;" @click="down" v-if="search.content!=''&&search.content!=null">PDF</div>
					<div class="btnover" style="margin-right: 0.4rem;" @click="genzong" v-if="search.trace==0">跟踪</div>
					<div class="btnover" style="margin-right: 0.4rem;background-color: #F32727;color: #FFFFFF;"  v-else>已跟踪</div>
				</div>
				
				<div style="width: 100%;height: 0.5rem;clear: both;"></div>
			</div>
			
		</div>

		<el-dialog
		  title="您是否查询以下信息："
		  :visible.sync="drafterDialog"
		  width="30%"
		  :before-close="handleClose"
		  v-loading="loading"
		  >
		  
		  <div style="overflow:auto;z-index:999;" >
		  	<div>
		  	<div v-for="(unit,index) in darfterList" :key="index" @click="unitinfodra(unit,index)" >
		  		<div style="display: flex;align-items: flex-start;justify-content: space-between;cursor: pointer;" class="unit2" :class="[isActive==index?'actived':'']">
		  			<div>{{unit.name}}</div>
		  			<div style="text-align: left;width: 400px;">{{unit.committee__full_name}}</div>
		  		</div>
		  		<div style="width: calc(100%-40rpx);height: 1px;background-color: #F5F5F5;margin: 0 20px 0 20px;"></div>
		  	
		  	</div>
		  	</div>
		  </div>
		  
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="drafterDialog = false">取 消</el-button>
		    <el-button type="primary" @click="drafterDetail">确 定</el-button>
		  </span>
		</el-dialog>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				search:{},
				drafter:[],
				danwei:[],
				gzflag:false,
				downs:JSON.parse(localStorage.getItem("downlist")||"[]"),
				darfterList:[],
				drafterDialog:false,
				mandetail:'',//从起草人用户选择的单位
				isActive:0,
				loading:false
			}
		},
		mounted() {
			// this.path()
			// console.log(document.cookie);
			// console.log(wx);
		},
		created() {
			let token = JSON.parse(window.localStorage.getItem('token'))
			// console.log(token.info.telephone);
			if(token.info.telephone){
				this.$axios({
					url:'/push_info/',
					params:{
						input:token.info.telephone
					}
				}).then(res=>{
					// console.log(res);
					this.path2()
				})
			}
			// window.localStorage.setItem('downs',JSON.stringify(null))
		},
		watch:{
			downs(){
				window.localStorage.setItem("downlist",JSON.stringify(this.downs))
			}
		},
		methods: {
			yyclick(){
				
				let token = JSON.parse(window.localStorage.getItem('token'))
					// console.log(token);
				if(token){
					// console.log(token);
						
						this.$axios({
							url: '/search/',
							params: {
								pkid: this.search.pk_id
							}
						}).then(res => {
							console.log(res);
							this.$message.success('已通知站长上传！')
						
						})
					
				}else{
					this.$message.error('您还没有登录')
					setTimeout(()=>{
						this.$router.push({path:'/login'})
					},1200)
				}
				
				
				
			},
			path2(){
				let a= JSON.parse(this.$route.query.body)
				// console.log(JSON.parse(this.$route.query.body));
				// console.log(this.search)

				// console.log(a.pk_id);
				this.$axios({
					url: '/search/',
					params: {
						pkid: a,
						read:1
					}
				}).then(res=>{
					console.log(res);
					if(res.data.status==200){
						this.search = res.data.info
						if(this.search.drafter){
							if(this.search.drafter.indexOf(',')!=-1){
								this.drafter = this.search.drafter.split(',')
							}else{
								this.drafter = this.search.drafter.split('、')
							}
						}
						if(this.search.drafter_unit){
							if(this.search.drafter_unit.indexOf(',')!=-1){
								this.danwei = this.search.drafter_unit.split(',')
							}else{
								this.danwei = this.search.drafter_unit.split('、')
							}
							// this.danwei =  this.search.drafter_unit.split(',')
							// console.log(this.danwei);
						}
					}
				})
			},
			patg(){
				this.$router.push({path:'committee'})
			},
			unit(i,index){
				console.log(i);
				window.sessionStorage.setItem('search',JSON.stringify(i))
				// this.$router.push({path:'draftingunit',query:{draftunit:i}})
				this.$router.push({path:'/search',query:{draftunit:1}})
			},
			handleClose(done) {
			        this.$confirm('确认关闭？')
			          .then(res=> {
			            done();
			          })
			          .catch(err => {});
			      
			    },
				unitinfodra(u, i) {
					this.mandetail = u
					this.isActive = i
				},
			draftergo(i){
				// console.log(i);
				this.$axios({
					url:'/auth_drafter/',
					params:{
						drafter: i
					}
				}).then(res=>{
					// console.log(res);
					if(res.data.state==201){
						this.$message.error(res.data.info)
					}
					if(res.data.state==200){
						this.darfterList = res.data.info
						this.drafterDialog = true
					}
					// if(res.data.state==200){
					// 	let info = {
					// 		info:res.data.info,
					// 		guan:this.search.department
					// 	}
					// 	this.$router.push({path:'drafter',query:{drafter:info}})
					// }
					if(res.data.state==404){
					this.$message.error(res.data.info)
						
					
					}
				})
				// this.$router.push({path:'drafter',query:{drafter:i}})
			},
			drafterDetail(){
				// console.log(this.mandetail);
				this.loading = true
				if(this.mandetail.pk_id){
					this.go(this.mandetail)
				}else{
					let draobj = this.darfterList[0]
					// console.log(draobj);
					this.go(draobj)
				}
			},
			go(e){
				this.$axios({
					url:'/auth_drafter/',
					params:{
						drafter_id:e.pk_id
					}
				}).then(res=>{
					// console.log(res);
					this.loading = false
					if(res.data.state == 200){
							let dra = JSON.stringify(res.data.info)
							this.$router.push({path:'drafter',query:{drafter:dra}})
					}
				})
			},
			path() {},
			openlogin(){
				this.$router.push({path:'/login'})
			},
			down(){
				
				
					
					
					
						// console.log(this.search.content);
					
						let token = JSON.parse(window.localStorage.getItem('token'))
						// console.log(token);
					if(token){
						// console.log(token);
						if(token.info.is_vip==true){
							
							
							
							if(this.search.content!=''||this.search.content!=null){
								location.href='/url'
								window.location.href="https://www.biaozhuncha.com/api/download_text/?pk_id="+this.search.pk_id;  
							}else{
								this.$message.warning('暂无PDF')
							}
							// if(this.search.content!=''||this.search.content!=null){
							// 	this.$axios({
							// 		url: '/search/',
							// 		params: {
							// 			pkid: this.search.pk_id,
							// 			download:1
							// 		}
							// 	}).then(res=>{
							// 		console.log(res);
							// 	}).catch(err=>{
							// 		console.log(err);
							// 	})
								// this.downs.push(this.search)
								// let urls = 'http://www.biaozhuncha.com/api/media/'+this.search.content
								// window.location.href = urls; // 本窗口打开下载
								// window.open(urls, '_blank')//新窗口打开
								
								// this.$axios({
								// 	url:'/download_text/',
								// 	params:{
								// 		pk_id: pkid,
								// 	}
								// }).then(res=>{
								// 	console.log(res);
								// 	if(res.data.status == 402){
								// 		this.$message.error(res.data.info)
								// 	}else{
								// 		// location.href='/url'
								// 		console.log(res.data);
								// 		window.location.href=res.data; 
								// 	}
									
								// })
							// }else{
							// 	this.$message.error('资料收集中，敬请期待！')
							// }
							
						}else{
							this.$message.error('开通会员解锁更多功能!')
						}
					}else{
						this.$message.error('您还没有登录')
						setTimeout(()=>{
							this.$router.push({path:'/login'})
						},1200)
					}
					
					
					
				
			

			},
			genzong(){
				let token = JSON.parse(window.localStorage.getItem('token'))
					// console.log(token);
				if(token){
					// console.log(token);
					if(token.info.is_vip==true){
						this.$axios({
							url: '/search/',
							params: {
								pkid: this.search.pk_id,
								trace:1
							}
						}).then(res=>{
							console.log(res);
							
							if(res.data.status==200){
								
								this.$message.success('跟踪成功！')
								this.gzflag = true
							}
							if(res.data.status==403){
								this.$message.error('您还没有登录')
								setTimeout(()=>{
									this.$router.push({path:'/login'})
								},1200)
							}
						})
					}else{
						this.$message.error('开通会员解锁更多功能!')
					}
				}else{
					this.$message.error('您还没有登录')
					setTimeout(()=>{
						this.$router.push({path:'/login'})
					},1200)
				}
			}
		}
	}
</script>

<style scoped="scoped">

	.header {
		background: url(../assets/images/detail/detailhead.png) no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		width: 100%;
		height: 2.2rem;
	}
button{
		outline: none;
}
.btn3 {
		width: 0.6rem;
		height: 0.36rem;
		text-align: center;
		border: 1px solid #f32727;
		background-color: #FFFFFF;
		border-radius: 6px;
		color: #f32727;
	}
.btn2 {
		width: 0.6rem;
		height: 0.36rem;
		text-align: center;
		border: 1px solid #f32727;
		background-color: #FFFFFF;
		border-radius: 6px;
		color: #f32727;
	}
	.btn2:hover{
		background-color: #f32727;
		color: #FFFFFF;
	}
	.btn {
		width: 0.6rem;
		height: 0.36rem;
		text-align: center;
		border: 1px solid #f32727;
		background-color: #FFFFFF;
		border-radius: 6px;
		color: #f32727;
	}
	.btn:hover{
		background-color: #f32727;
		color: #FFFFFF;
	}
>>> .el-step__head.is-success {
  color: #f32727;
  border-color: #f32727;
  font-size: 0.14rem;
  font-weight: 400;
}
>>> .el-step__title.is-success {
    font-weight: bold;
    color: #f32727;
	font-size: 0.14rem;
	font-weight: 400;
}
>>> .el-step__description.is-success {
    color: #f32727;
	font-size: 0.14rem;
	font-weight: 400;
}


.hvers{
	color: #101010;
	text-decoration: none;
}

.hvers:hover{
	background-color: #F32727;
	color: #FFFFFF;
}
.harda{
	display: flex;justify-content: center;line-height: 60px;
}
.harda:hover{
	box-shadow: 0 0 10px #fdecec;
	border-radius: 10px;
	color: #F32727;
}
.btnover{
	width: 0.8rem;
	height: 0.6rem;
	cursor: pointer;
	text-align: center;
	line-height: 0.6rem;
	border: 1px solid #F32727;
	font-size: 0.16rem;
	border-radius: 0.1rem;
	color: #F32727;
}
.btnover:hover{
	background-color: #F32727;
	color: #FFFFFF;
}
.unit2{
	padding: 10px 25px 10px 20px;
}
.actived {
		background-color: #F32727;
		color: #FFFFFF;
	}
.dafterunit:hover{
	/* background-color: aqua; */
	color: #F32727;
}
</style>
