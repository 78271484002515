<template>
	<div style="background-color: #f8f8f8;" class="content">
		<div class="header" >
			<div style="width: 100%;height: 0.4rem;background-color: #fa6143;display: flex;align-items: center;justify-content: flex-end;">
				<div style="margin-right: 2.2rem;color: #FFFFFF;font-size: 0.15rem;display: flex;align-items: center;cursor: pointer;">
					<div class="iconfont icon-user" style="margin-right: 0.05rem;"></div>
					<div v-if="tokens == false" @click="gologin">登录/注册</div>
					<div v-else @click="layout">退出</div>
				</div>
			</div>
			<div style="display: flex;justify-content: space-between;align-items: center;width: 100%;height: 1.8rem;">
				<div style="display: flex;align-items: center;margin-left: 1.8rem;">
					<img src="../assets/images/newslogo.png" style="width: 0.6rem;height: 0.6rem;cursor: pointer;" @click="goindex">
					<div style="width: 3px;height: 50px;background-color: #c62100;margin: 0 0.3rem 0 0.3rem;"></div>
					<img src="../assets/images/detail/dog.png" style="width: 0.86rem;height: 0.9rem;">
				</div>
				<div style="font-size: 40px;color: #FFFFFF;margin-right: 180px;font-family: jinnan;">查标准就用标准查！</div>
			</div>
			<!-- <img src="../assets/images/detail/title.png" style="width: 355px;height: 37px;margin-right: 180px;"> -->
		</div>

		 	<router-view></router-view>


		<Bottom></Bottom>

	</div>
</template>

<script>
	import Bottom from '../common/Bottom.vue'
	export default {
		components:{
			Bottom
		},
		data() {
			return {
				search:{},
				drafter:[],
				danwei:[],
				gzflag:false,
				tokens:false
			}
		},
		mounted() {
			this.path()
			this.loginout()
			// console.log(document.cookie);
			// console.log(wx);
			// document.cookie = 'phone' + "=" + "19523667356" + "; " ;
			
				
			
		},
		methods: {
			goindex(){
				this.$router.push({path:'/indext'})
			},
			loginout(){
				let token = JSON.parse(window.localStorage.getItem('token'))
				// console.log(token);
				if(token==null){
					this.tokens = false
				}else{
					this.tokens = true
					// console.log(token);
					// this.headhost = 'https://www.biaozhuncha.com/api/media/'+token.info.headshot
				}
			},
			layout(){
				
				
				this.$axios({
					url:'/logout/'
				}).then(res=>{
					window.localStorage.removeItem('token')
					this.$message.success('退出成功')
					this.loginout()
				
				})
			},
			gologin(){
				this.$router.push({path:'/login'})
			},
			path() {
				this.search = JSON.parse(this.$route.query.body)
				// console.log(JSON.parse(this.$route.query.body));
				// let token = JSON.parse(window.sessionStorage.getItem('token'))
				// console.log(token);
				
				// if(this.searchdetail.drafter){
				// 	if(this.searchdetail.drafter.indexOf(',')!=-1){
				// 		console.log('存在');
				// 		return this.searchdetail.drafter.split(',')
				// 	}else{
				// 		return this.searchdetail.drafter.split('、')
				// 	}
				// }
				if(this.search.drafter){
					if(this.search.drafter.indexOf(',')!=-1){
						this.drafter = this.search.drafter.split(',')
					}else{
						this.drafter = this.search.drafter.split('、')
					}
					// this.drafter = this.search.drafter.split(',')
				}
				if(this.search.drafter_unit){
					this.danwei =  this.search.drafter_unit.split(',')
				}
				// console.log(this.danwei);
			},
			openlogin(){
				this.$router.push({path:'/login'})
			},
			down(){
				// console.log(this.search.content);
			
			
				let token = JSON.parse(window.sessionStorage.getItem('token'))
				// console.log(token);
			if(token){
				// console.log(token);
				if(token.info.is_vip==true){
					let urls = 'http://www.biaozhuncha.com/api/media/'+this.search.content
					// window.location.href = urls; // 本窗口打开下载
					window.open(urls, '_blank')//新窗口打开
				}else{
					this.$message.error('开通会员解锁更多功能!')
				}
			}else{
				this.$message.error('您还没有登录')
				setTimeout(()=>{
					this.$router.push({path:'/login'})
				},1200)
			}

			},
			genzong(){
				let token = JSON.parse(window.sessionStorage.getItem('token'))
					// console.log(token);
				if(token){
					// console.log(token);
					if(token.info.is_vip==true){
						this.$axios({
							url: '/search/',
							params: {
								pkid: this.search.pk_id,
								trace:1
							}
						}).then(res=>{
							// console.log(res);
							
							if(res.data.status==200){
								
								this.$message.success('跟踪成功！')
								this.gzflag = true
							}
							if(res.data.status==403){
								this.$message.error('您还没有登录')
								setTimeout(()=>{
									this.$router.push({path:'/login'})
								},1200)
							}
						})
					}else{
						this.$message.error('开通会员解锁更多功能!')
					}
				}else{
					this.$message.error('您还没有登录')
					setTimeout(()=>{
						this.$router.push({path:'/login'})
					},1200)
				}
			}
		}
	}
</script>

<style scoped="scoped">
	@media screen and (max-width: 750px) {
		.content{
			width: 19.2rem;
		}
	}
	.header {
		background: url(../assets/images/detail/detailhead.png) no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		width: 100%;
		height: 2.2rem;
	}

	.btn {
		width: 0.6rem;
		height: 0.36rem;
		text-align: center;
		border: 1px solid #f32727;
		outline: none;
		background-color: #FFFFFF;
		border-radius: 0.06rem;
		color: #f32727;
	}
	.btn:hover{
		background-color: #f32727;
		color: #FFFFFF;
	}
>>> .el-step__head.is-success {
  color: #f32727;
  border-color: #f32727;
  font-size: 14px;
  font-weight: 400;
}
>>> .el-step__title.is-success {
    font-weight: bold;
    color: #f32727;
	font-size: 0.14rem;
	font-weight: 400;
}
>>> .el-step__description.is-success {
    color: #f32727;
	font-size: 0.14rem;
	font-weight: 400;
}


.hvers{
	color: #101010;
	text-decoration: none;
}

.hvers:hover{
	background-color: #F32727;
	color: #FFFFFF;
}
.harda{
	display: flex;justify-content: center;line-height: 0.6rem;
}
.harda:hover{
	box-shadow: 0 0 10px #fdecec;
	border-radius: 10px;
	color: #0077AA;
}
.btnover{
	width: 2.8rem;
	height: 0.6rem;
	cursor: pointer;
	text-align: center;
	line-height: 0.6rem;
	border: 1px solid #F32727;
	font-size: 0.16rem;
	border-radius: 10px;
	color: #F32727;
}
.btnover:hover{
	background-color: #F32727;
	color: #FFFFFF;
}
</style>
