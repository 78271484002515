<template>
	<div>
		<p style="font-size: 0.2rem;font-weight: 800px;margin: 0.5rem 0 0 0.3rem;">重设密码</p>
	
	<div class="quires">
		<!-- <div id="login_container" @click="click">扫码</div> -->
		
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0" >
		  <el-form-item  prop="phone">
		    <el-input v-model="ruleForm.phone" placeholder="请输入手机号">
				<i slot="prefix" class="iconfont icon-user" style="font-size: 0.18rem;margin-left: 0.04rem;"></i>
			</el-input>
		  </el-form-item>
		  
		  
		  <el-form-item prop="code">
		    <div style="display: flex;justify-content: center;align-items: center;">
				<el-input v-model="ruleForm.code" placeholder="请输入验证码">
					<i slot="prefix" class="iconfont icon-dunpaibaoxianrenzheng_o" style="font-size: 0.25rem;"></i>
		
					
				</el-input>
				<div style="width: 0.3rem;"></div>
				
					<button type="button" :disabled="disable" @click="getVerifyCode" style="width: 1.3rem;height: 0.35rem;color: #f29818;border: 1px solid #f29818;font-size: 0.12rem;background-color: #FFFFFF;line-height: 0.35rem;border-radius: 5px;">{{getCode}}</button>
				
				
			</div>
		  </el-form-item>
		  
		 <el-form-item prop="passone">
		   <div>
			   <!-- <span>{{ruleForm.passone}}</span> -->
			   <el-input v-model="ruleForm.passone" placeholder="请输入6-18位字符的新密码" show-password>
			   			<i slot="prefix" class="iconfont icon-lock" style="font-size: 0.18rem;margin-left: 0.04rem;"></i>
			   		</el-input>
		   </div>
		  </el-form-item>
		  
		  <el-form-item  prop="passtwo">
		    <el-input v-model="ruleForm.passtwo" placeholder="请再次输入新密码" show-password>
		  				<i slot="prefix" class="iconfont icon-lock" style="font-size: 0.18rem;margin-left: 0.04rem;"></i>
		  			</el-input>
		  </el-form-item>
		</el-form>
		<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
			<button class="btnlogin" @click="password">确定重设</button>
			<div style="width: 3rem;font-size: 0.14rem;margin-top: 0.05rem;"><p>登录即同意<span class="usertext">用户协议</span>和<span class="usertext">隐私政策</span></p></div>
		</div>
	</div>
	
	</div>
</template>

<script>
	export default{
		data(){
			return{
				ruleForm:{
					phone:'',
					code:'',
					passone:'',
					passtwo:''
				},
				count:60,
				getCode:'获取验证码',
				disable:false,
				rules: {
				          phone: [
				            { required: true, message: '请输入手机号码', trigger: 'blur' },
				            { min: 11, max: 11, message: '请输入完整手机号', trigger: 'blur' }
				          ],
						  code: [
						    { required: true, message: '请输入验证码', trigger: 'blur' },
						  ],
						 passone: [
						   { required: true, message: '请输入密码', trigger: 'blur' },
						   { min: 6, max: 18, message: '请输入6-18个字符的密码', trigger: 'blur' }
						 ],
						  passtwo: [
						    { required: true, message: '请输入密码', trigger: 'blur' },
						    { min: 6, max: 18, message: '请输入6-18个字符的密码', trigger: 'blur' }
						  ]
				        },
				phoneflag:false,
				passwordcode:false
			}
		},
		created() {
			const codes = window.sessionStorage.getItem('code')
			const codestatus = window.sessionStorage.getItem('codestatus')
			if(codestatus == 403){
				this.$router.push({path:'/wxbind',query: {nums:codes}})
			}
		},
		destroyed() {
			window.sessionStorage.removeItem('code')
			window.sessionStorage.removeItem('codestatus')
		},
		methods:{
			password(){
				
				
			 if(this.phoneflag!=true){
					this.$message.error('需要先获取验证码！')
				}
				
					else if(this.ruleForm.passone==this.ruleForm.passtwo){
						let reg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/)  //数字加英文
						// let reg = new RegExp(/^(.){6,20}$/)
						let result = reg.test(this.ruleForm.passone);
						if(!result){
					
						this.$message.error('密码格式错误,需数字加英文！')
						}else{
							this.passwordcode=true
							
						}
					}
					if(this.ruleForm.code!=''&&this.passwordcode==true){
						this.$axios({
							url:'/info/changepwd/',
							params:{
								phone:this.ruleForm.phone,
								code:this.ruleForm.code,
								new_pwd:this.ruleForm.passtwo
							}
						}).then(res=>{
							// console.log(res);
							if(res.data.status==200){
								this.$message.success(res.data.info)
								this.$router.push({path:'/code'})
							}
							if(res.data.status==401){
								this.$message.error(res.data.info)
							}
							if(res.data.status==403){
								this.$message.error(res.data.info)
							}
						})
					}else{
						this.$message.error('请填写正确信息')
					}
				
				
			},
			getVerifyCode(){
							// console.log('获取');
							// const Time_Count = 60
							let phone = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
			         if(!phone.test(this.ruleForm.phone)){
									  this.$message.error('请输入正确手机号！')
								  }else{
									  this.phoneflag = true
									  var countDown = setInterval(()=>{
									    if(this.count < 1){
									      this.disable = false;
									      this.getCode = '获取验证码';
									      this.count = 60;
									      clearInterval(countDown);
									    }else{
									  						
									      this.disable = true;
									      this.getCode = this.count-- + 's后重发';
									    }
									  },1000);
									  this.getcode()
								  }
			       },
				   getcode(){
				   	// 手机号验证规则
				   	let phone = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
				   	if(this.ruleForm.phone==''||!phone.test(this.ruleForm.phone)){
				   		this.$message.error('请输入正确手机号！')
				   	}else{
				   		// console.log(this.ruleForm.phone);
				   		this.phoneflag = true
				   		this.$axios({
				   			url:'/get_code/',
				   			params:{
				   				phone:this.ruleForm.phone
				   			}
				   		}).then(res=>{
				   			// console.log(res);
				   			if(res.data.status==200){
				   				this.$message.success('发送成功')
				   			}else{
				   				this.$message.error('请稍后再试！')
				   			}
				   		}).catch(err=>{
				   			this.$message.error('请稍后再试！')
				   		})
				   	}
				   },
			click(){
				
			}
		}
	}
</script>

<style scoped="">
	.quires{
		display: flex;flex-direction: column;align-items: center;
		margin: 0.2rem 0.3rem 0 0.3rem;
	}
	.btnlogin{
		width: 3rem;
		border-radius: 0.05rem;
		color: #ffffff;
		background-color: #f32727;
		height: 0.4rem;
		line-height: 0.4rem;
		margin-top: 0.3rem;
		margin: 0 auto;
	}
	.usertext{
		color: #f38942;
		margin: 0 0.05rem 0 0.05rem;
	}
</style>
