<template>
	<div style="width: 100%;height: 100%;padding: 20px 30px 20px 30px;">
		<div style="font-size: 18px;color: #101010;">问题描述</div>
		<div style="margin-top: 20px;">
			<el-input
			  type="textarea"
			  :rows="3"
			  placeholder="您遇到了什么问题？或者有什么建议？欢迎您反馈给我们(建议采纳后送您1个月VIP)"
			  v-model="textarea"
			  maxlength="300"
			  :autosize="autosize"
			  >
			</el-input>
			<div style="float: right;margin-top: 5px;">{{textarea.length}}/300</div>
		</div>
		<div style="font-size: 18px;color: #101010;margin-top: 30px;">相关图片</div>
		<div class="grows">
			<el-upload
			  action="https://www.biaozhuncha.com/api/opinion/"
			  :auto-upload="false"
			  list-type="picture-card"
			  :on-remove="handleRemove"
			  :data="{info:this.textarea,email:this.email,}"
			  ref="upload"
			  :on-success="succ"
			  :on-error="erros"
			  :limit="1"
			  :on-exceed="cclimit"
			  :with-credentials="true"
			  :file-list="filearr">
			  <i class="el-icon-plus" @click="flagimg"></i>
			</el-upload>
			<el-dialog :visible.sync="dialogVisible">
			  <img width="100%" :src="dialogImageUrl" alt="">
			</el-dialog>
			
			<div style="width: 90px;height: 35px;border-radius: 5px;background-color: #f32727;color: #ffffff;font-size: 16px;line-height: 35px;text-align: center;cursor: pointer;margin-top: 30px;float: right;" @click="submit">提交反馈</div>
		</div>
			
	
	</div>
</template>

<script>
	export default {
	  data() {
	    return {
	      textarea: '',
		dialogImageUrl: '',
		        dialogVisible: false,
				infoList:'',
				infoList2:{
					info:'',
					email:''
				},
				autosize:{ minRows: 3},
				email:'',
				flagimghave:false,
				filearr:[]
	    }
	  },
	  created() {
	  	let token = JSON.parse(window.localStorage.getItem('token'))
	  	// console.log(token);
	  	if(token.info.telephone){
	  		this.$axios({
	  			url:'/push_info/',
	  			params:{
	  				input:token.info.telephone
	  			}
	  		}).then(res=>{
	  			// console.log(res);
	  			 this.email = res.data.info.email
	  		})
	  	}
	  },
	  methods:{
		  flagimg(e){
			  console.log('点击了上传');
			  //true为有图
			  this.flagimghave = true
		  },
		  cclimit(){
			  this.$message.warning('最多只能上传一张图片')
		  },
		  erros(err){
			  console.log(err);
		  },
		  succ(res){
			  // console.log(res);
			  if(res.status == 200){
				  this.$message.success('感谢您的宝贵建议！')
			  }else{
				  this.$message.warning('请填写详细信息')
			  }
		  },
		   handleRemove(file, fileList) {
		          // console.log(file, fileList);
		        },
		        handlePictureCardPreview(file) {

		          this.dialogImageUrl = file.url;
		          this.dialogVisible = true;
		        },
				submit(){
					console.log(this.$refs.upload.uploadFiles)
					
					if(this.textarea!=''){
					
						if(this.$refs.upload.uploadFiles.length>0){
					
							this.$refs.upload.submit();
						}else{
							let obj = {
								email:this.email,
								info:this.textarea
							}
						
						
						// 	this.$axios({
								
						// 			headers:{
						// 				 'Content-Type': 'application/x-www-form-urlencoded'
						// 			},
						
						// 		method:'post',
						// 		url:'/opinion/',
						// 		data:{
						// 			email: this.email,
						// 			info: this.textarea
						// 		}
						// 	}).then(res=>{
						// 		console.log(res);
						// 	})
						this.$axios({	
							url:'/opinion/',
							params:{
								email: this.email,
								info: this.textarea
							}
						}).then(res=>{
							// console.log(res);
							if(res.status == 200){
											  this.$message.success('感谢您的宝贵建议！')
							}else{
											  this.$message.warning('请填写详细信息')
							}
						})
						
						
						}
					

							
						
				
					}else{
						this.$message.error('请留下您的宝贵建议！')
					}
				}
	  }
	}
</script>

<style scoped="scoped">
	.grows /deep/.el-upload__input{
		display: none;
	}
	.grows{
		margin-top: 30px;
	}
</style>