<template>
	<div>
		<div style="display: flex;justify-content: space-between;align-items: center;width: 100%;height: 180px;">
			<div style="display: flex;align-items: center;margin-left: 180px;">
				<img src="../assets/images/newslogo.png" style="width: 60px;height: 60px;cursor: pointer;"  @click="goindex">
				<div style="width: 3px;height: 50px;background-color: #c62100;margin: 0 30px 0 30px;"></div>
				<img src="../assets/images/detail/dog.png" style="width: 86px;height: 90px;">
			</div>
			<div style="font-size: 40px;color: #FFFFFF;margin-right: 180px;font-family: jinnan;">查标准就用标准查！</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'Headers',
		data(){
			return{
				
			}
		},
		methods:{
			goindex(){
				this.$router.push({path:'/indext'})
			}
		}
	}
</script>

<style>
</style>