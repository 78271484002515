<template>
	<div style="padding: 50px 30px 20px 30px;font-size: 16px;color: #101010;" v-if="userinfo">
		<div style="display: flex;align-items: center;">
			<div style="width: 60px;">头像</div>
			<img :src="'https://www.biaozhuncha.com/api/media/'+userinfo.info.headshot" alt="" style="margin-left: 25px;width: 70px;height: 70px;border: 1px solid #e5e5e5;">
		</div>
		
		<div style="display: flex;align-items: center;margin-top: 30px;">
			<div style="width: 60px;">昵称</div>
			<input type="text" v-model="name" style="margin-left: 25px;background-color:#f8f8f8;font-size: 14px;color: #4a4b4b;">
		</div>
		
		<div style="display: flex;align-items: center;margin-top: 30px;">
			<div style="width: 60px;">手机号</div>
			<div v-model="tel" style="margin-left: 25px;background-color:#f8f8f8;font-size: 14px;color: #4a4b4b;">{{tel}}</div>
		</div>
		
		<div style="display: flex;align-items: center;margin-top: 30px;">
					<div style="width: 60px;">邮箱</div>
					<input type="text" v-model="email" style="margin-left: 25px;background-color:#f8f8f8;font-size: 14px;color: #4a4b4b;" placeholder="请填写邮箱">
				</div>
		<div style="display: flex;align-items: center;margin-top: 30px;">
		
					<div style="width: 68px;">单位</div>
		
					<input type="text" v-model="unit" style="margin-left: 25px;background-color:#f8f8f8;font-size: 14px;color: #4a4b4b;width: 100%;" placeholder="请填写单位名称">
		
				</div>
				
		<div style="display: flex;align-items: center;margin-top: 30px;">
		
					<div style="width: 68px;">地址</div>
		
					<input type="text" v-model="address" style="margin-left: 25px;background-color:#f8f8f8;font-size: 14px;color: #4a4b4b;width: 100%;" placeholder="请填写地址">
		
				</div>
				
				<div style="width: 60px;height: 35px;border-radius: 5px;background-color: #f32727;color: #ffffff;font-size: 16px;line-height: 35px;text-align: center;cursor: pointer;margin-top: 30px;" @click="saveClick">保存</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				inputs:'19523667356',
				tel:'',
				userinfo:'',
				permission:'',
				name:'',
				email:'',
				unit:'',
				address:''
			}
		},
		mounted() {
			this.getinfo()
		},
		methods:{
			getinfo(){
				let token = JSON.parse(window.localStorage.getItem('token'))
				
				if(token.info.telephone){
					this.$axios({
						url:'/push_info/',
						params:{
							input:token.info.telephone
						}
					}).then(res=>{
						// console.log(res);
						this.userinfo = res.data
						var reg = /^(\d{3})\d{6}(\d{2})$/
						this.tel =  res.data.info.telephone
						this.name = res.data.username
						this.permission =  res.data.info.permission__name
						this.email = res.data.info.email
						this.unit = res.data.info.unit
						this.address = res.data.info.address
					})
				}
			},
			saveClick(){
				if(this.email!=''&&this.unit!=''&&this.address!=''&&this.name!=''){
					this.$axios({
						url: '/change_info/',
						params: {
							email: this.email,
							unit: this.unit,
							address: this.address,
							username: this.name
						}
					}).then(res=>{
						// console.log(res)
						if(res.data.status == 200){
							this.$message.success('修改成功')
						}
					})
				}else{
					this.$message.error('请填写完整信息')
				}
				
			}
		}
	}
</script>

<style scoped="scoped">
</style>