<template>
	<div style="background-color: #f8f8f8;height: 100%;" class="about">
		<div class="header" >
			<div style="width: 100%;height: 40px;background-color: #fa6143;display: flex;align-items: center;justify-content: flex-end;">
				<div style="margin-right: 220px;color: #FFFFFF;font-size: 15px;display: flex;align-items: center;cursor: pointer;">
					<div class="iconfont icon-user" style="margin-right: 5px;"></div>
					<div v-if="tokens == false" @click="gologin">登录/注册</div>
					<div v-else @click="layout">退出</div>
				</div>
			</div>
			<Header></Header>
			<!-- <img src="../assets/images/detail/title.png" style="width: 355px;height: 37px;margin-right: 180px;"> -->
		</div>




		<div style="width: 70%;margin: 0 auto;margin-top: 50px;">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
			  <el-form-item label="标准名称:" prop="c_c_name" style="width: 450px;">
				  <!--  @blur="blurCcname" -->
			    <el-input v-model="ruleForm.c_c_name" size="medium" clearable></el-input>
			  </el-form-item>
			  
			  <el-form-item label="标准名称(英文):" prop="eu_name" style="width: 450px;">
			  				  <!--  @blur="blurCcname" -->
			    <el-input v-model="ruleForm.eu_name" size="medium" clearable></el-input>
			  </el-form-item>
			  
			  <el-form-item label="行业:" prop="indust" style="width: 300px;">
			    <el-input v-model="ruleForm.indust" size="medium" clearable></el-input>
			  </el-form-item>
			  
			  <el-form-item label="标准代号:" prop="c_std_code" style="width: 300px;">
			     <el-input v-model="ruleForm.c_std_code" size="medium" clearable></el-input>
			   </el-form-item>
			  
			   <el-form-item label="实施时间:" prop="act_date" style="width: 300px;">
			      <el-input v-model="ruleForm.act_date" size="medium" clearable></el-input>
			    </el-form-item>
			  
			  <el-form-item label="废止时间:" prop="abo_date" style="width: 300px;">
			     <el-input v-model="ruleForm.abo_date" size="medium" clearable></el-input>
			   </el-form-item>
			  
			   <el-form-item label="发布时间:" prop="issue_date" style="width: 300px;">
			      <el-input v-model="ruleForm.issue_date" size="medium" clearable></el-input>
			    </el-form-item>
				
				<el-form-item label="计划下达时间:" prop="send_date" style="width: 300px;">
				   <el-input v-model="ruleForm.send_date" size="medium" clearable></el-input>
				 </el-form-item>
				
				<el-form-item label="标准类别:" prop="types" style="width: 300px;">
				   <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
				   <el-select v-model="ruleForm.types" placeholder="请选择标准类别">
				         <el-option label="国家标准" value="国家标准"></el-option>
				         <el-option label="行业标准" value="行业标准"></el-option>
				         <el-option label="地方标准" value="地方标准"></el-option>
				         <el-option label="团体标准" value="团体标准"></el-option>
				       </el-select>
				 </el-form-item>
				 
				 
				 
				 <el-form-item label="标准层次:" prop="std_nature" style="width: 300px;">
				    <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
				    <el-select v-model="ruleForm.std_nature" placeholder="请选择标准层次">
				          <el-option label="推荐性" value="推荐性"></el-option>
				          <el-option label="强制性" value="强制性"></el-option>
				        </el-select>
				  </el-form-item>
				  
				  <el-form-item label="标准状态:" prop="state" style="width: 300px;">
				     <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
				     <el-select v-model="ruleForm.state" placeholder="请选择标准状态">
						 <div v-for="(item,index) in bzstate" :key="index">
							 <el-option :label="item" :value="item"></el-option>
						 </div>
				          
				         </el-select>
				   </el-form-item>
				 
				 
				 <el-form-item label="制修订:" prop="recension" style="width: 300px;">
				    <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
				    <el-select v-model="ruleForm.recension" placeholder="请选择制定/修订">
				          <el-option label="制定" value="制定"></el-option>
				          <el-option label="修订" value="修订"></el-option>
				        </el-select>
				  </el-form-item>
				  
				  <el-form-item label="归口单位:" prop="department" style="width: 500px;">
				     <el-input v-model="ruleForm.department" size="medium" clearable></el-input>
				   </el-form-item>
				   
				   <el-form-item label="发布部门:" prop="act_department" style="width: 500px;">
				      <el-input v-model="ruleForm.act_department" size="medium" clearable></el-input>
				    </el-form-item>
					
					<el-form-item label="执行单位:" prop="exe_department" style="width: 500px;">
					   <el-input v-model="ruleForm.exe_department" size="medium" clearable></el-input>
					 </el-form-item>
				 
				 <el-form-item label="中国标准分类号:" prop="ch_no" style="width: 300px;">
				    <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
				    <el-input v-model="ruleForm.ch_no" size="medium" clearable></el-input>
				  </el-form-item>
				  
				  <el-form-item label="国际标准分类号:" prop="eu_no" style="width: 300px;">
				     <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
				     <el-input v-model="ruleForm.eu_no" size="medium" clearable></el-input>
				   </el-form-item>
				  
				  <el-form-item label="计划号:" prop="" style="width: 300px;">
				     <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
				     <el-input v-model="ruleForm.plan_num" size="medium" clearable></el-input>
				   </el-form-item>
				   
				   <el-form-item label="发布城市:" prop="" style="width: 300px;">
				      <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
				      <el-input v-model="ruleForm.industry" size="medium" clearable></el-input>
				    </el-form-item>
				   
				  <el-form-item label="备案号:" prop="" style="width: 300px;">
				     <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
				     <el-input v-model="ruleForm.filing_number" size="medium" clearable></el-input>
				   </el-form-item>
				   
				  <el-form-item label="备案公告:" prop="" style="width: 300px;">
				     <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
				     <el-input v-model="ruleForm.filing_cement" size="medium" clearable></el-input>
				   </el-form-item>
				  
				  
				 <el-form-item label="替代标准:" prop="" style="width: 300px;">
				     <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
				     <el-input v-model="ruleForm.c_c_name_replace" size="medium" clearable></el-input>
				</el-form-item>
				  
				  <el-form-item label="相似标准:" prop="" style="width: 300px;">
				      <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
				      <el-input v-model="ruleForm.c_c_name_similar" size="medium" clearable></el-input>
				 </el-form-item>
					
					<el-form-item label="通讯地址:" prop="" style="width: 500px;">
					    <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
					    <el-input v-model="ruleForm.mailing_address" size="medium" clearable></el-input>
					</el-form-item>
					  
					<el-form-item label="依托人:" prop="" style="width: 500px;">
					    <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
					    <el-input v-model="ruleForm.principal" size="medium" clearable></el-input>
					</el-form-item>
					  
					  <el-form-item label="依托单位:" prop="" style="width: 500px;">
					      <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
					      <el-input v-model="ruleForm.relying_unit_name" size="medium" clearable></el-input>
					</el-form-item>
				  
					<el-form-item label="主要起草人:" prop="" style="width: 500px;">
					    <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
					    <el-input v-model="ruleForm.drafter" type="textarea" size="medium" clearable></el-input>
						<!-- <span style="">请使用逗号分隔</span> -->
					  </el-form-item>
					
					<el-form-item label="起草单位:" prop="" style="width: 500px;">
					    <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
					    <el-input v-model="ruleForm.drafter_unit" type="textarea" size="medium" clearable></el-input>
					</el-form-item>
					
					<el-form-item label="标准适用范围:" prop="" style="width: 500px;">
					    <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
					    <el-input v-model="ruleForm.ranges"  size="medium" clearable></el-input>
					</el-form-item>
					
					<el-form-item label="标准摘要:" prop="" style="width: 500px;">
					    <!-- <el-input v-model="ruleForm.types" size="medium" clearable></el-input> -->
					    <el-input v-model="ruleForm.summary" type="textarea" size="medium" clearable></el-input>
					</el-form-item>
			<div style="width: 30%;">
				<el-upload
				  class="upload-demo"
				  action="https://test.biaozhuncha.com/api/record/"
				  :on-remove="handleRemove"
				  :auto-upload="false"
				  :data="ruleForm"
				  ref="upload"
				  :on-success="succ"
				  :on-error="erros"
				  :limit="1"
				  :on-exceed="cclimit"
				  :with-credentials="true">
				  <el-button slot="trigger" size="small" type="primary" style="margin-left: 20px;">选取文件</el-button>
				  <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload2" v-if="flagbutton==true">修改提交审核</el-button>
				  
				  <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload" v-else>提交审核</el-button>
				</el-upload>
			</div>
			
			</el-form>
		</div>
		<div style="width: 100%;height: 50px;"></div>
		
		<el-dialog
		  title="提示"
		  :visible.sync="dialogVisible"
		  width="30%"
		 >
		  <span>您是否要修改《{{bzxg.c_c_name}}》</span>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="xgbz">确 定</el-button>
		  </span>
		</el-dialog>
		
		<Bottom></Bottom>
	</div>
</template>

<script>
	import Header from '../common/Headers.vue'
	import Bottom from '../common/Bottom.vue'
	// import qs from 'qs'
	export default {
		components:{
			Header,
			Bottom
		},
		data() {
			var checkTime = (rule, value, callback) => {
			        if (value) {
			          var regex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
			          
			          if(!regex.test(value)){
			          	  callback(new Error('请按照yyyy-mm-dd格式'));
			          }else{
			          	  callback();
			          }
			        }else{
						callback();
					}
						
			          
			    
			      };
				  
			var checkCcname = (rule, value, callback)=>{
				if(value){
					console.log(value);
					this.$axios({
						url:'/record/',
						method:'POST',
						headers:{
							 'Content-Type':'application/json',
						},
						data:{
							c_c_name:value
						}
					}).then(res=>{
						console.log(res);
						if(res.data.state == 404){
							callback(new Error('请先登录'));
						}else{
							if(res.data.state == 202){
								// this.$message.warning(res.data.info)
								callback();
							}else{
								// callback(new Error('请填写完整'));
								//修改此标准
								if(res.data.state == 207){
									callback(new Error(res.data.info));
								}else{
									this.dialogVisible = true
									this.flagbutton = true
									this.bzxg = res.data.info
									callback()
								}
								
							}
						}
						
					}).catch(err=>{
						// console.log(err);
					})
					// callback()
				}else{
					callback(new Error('请输入标准名称'));
				}
			}
			
			var checkText=(rule, value, callback)=>{
			// 	console.log(value);
				// console.log(typeof value);
				// if(value.indexOf('，')==-1){
				// 	console.log('---不存在逗号');
				// 	// callback(new Error('适用中文逗号分隔'));
				// }else{
				// 	console.log('=====存在逗号');
				// 	console.log(value.length);
				// }
				// if(value.charAt(value.length - 1) == "，"){
				// 	console.log('有','---');
					
				// }else{
				// console.log('没有','++++++++');
				
				// }
			}
			
			return {
				tokens:false,
				flagbutton:false,
				ruleForm: {
				          c_c_name: null,//标准名称
						  eu_name:null,//标准名称英文
						  indust:null,//行业
						  c_std_code:null,//标准代号
						  act_date:null,//实施时间
						  abo_date:null,//废止时间
						  issue_date:null,//发布时间
						  send_date:null,//计划下达时间
						  types:null,//标准类别
						  std_nature:null,//标准层次
						  state:null,//标准状态
						  recension:null,//制修订
						  department:null,//归口单位
						  act_department:null,//发布单位
						  exe_department:null,//执行单位
						  ch_no:null,//中国标准分类号
						  eu_no:null,//国际标准分类号
						  plan_num:null,//计划号
						  industry:null,//发布城市
						  filing_number:null,//备案号
						  filing_cement:null,//备案公告
						  c_c_name_replace:null,//替代标准
						  c_c_name_similar:null,//相似标准
						  mailing_address:null,//通讯地址
						  principal:null,//依托人
						  relying_unit_name:null,//依托单位
						  drafter:null,//主要起草人
						  drafter_unit:null,//起草单位
						  ranges:null,//标准适用范围
						  pk_id:null
				        },
				rules: {
				          c_c_name: [
				            { required: true, validator:checkCcname, trigger: 'blur' },
				            // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
				          ],
				       
						 indust:[
							 { message: '请输入行业名称', trigger: 'blur' },
						 ],
						 eu_name:[
							 { message: '请输入标准名称（英文）', trigger: 'blur' },
						 ],
						 c_std_code:[
							 { required: true, message: '请输入标准代号', trigger: 'blur' },
						 ],
						 act_date:[
							 { trigger: 'blur',validator: checkTime, },
						 ],
						 abo_date:[
							 { trigger: 'blur',validator: checkTime, },
						 ],
						 issue_date:[
						 							 { validator: checkTime, trigger: 'blur'}
						 ],
						 send_date:[
							 { validator: checkTime, trigger: 'blur'}
						 ],
						 types:[
							 { required: true, message: '请选择标准类别', trigger: 'blur' },
						 ],
						 std_nature:[
							 { message: '请选择标准层次', trigger: 'blur' },
						 ],
						 state:[
							 { required: true, message: '请选择标准状态', trigger: 'blur' },
						 ],
						 recension:[
							 { message: '请选择制修订', trigger: 'blur' },
						 ],
						 department:[
							 { message: '请输入归口单位', trigger: 'blur' },
						 ],
						 act_department:[
							 { message: '请输入发布部门', trigger: 'blur' },
						 ],
						 exe_department:[
							 { message: '请输入执行单位', trigger: 'blur' },
						 ],
						 ch_no:[
							 { message: '请输入中国标准分类号', trigger: 'blur' },
						 ],
						 eu_no:[
							 { message: '请输入国际标准分类号', trigger: 'blur' },
						 ],
						 drafter:[
						 	{ trigger: 'blur'}
						 ]
				        },
					bzstate: ['现行', '即将实施', '正在审查', '正在批准', '正在起草', '正在公示', '征求意见', '有更新版', '责令改正', '被代替', '不执行','废止',],
					dialogVisible:false,//修改弹窗
					bzxg:{}//修改的数据
			}
		},
		mounted() {
			this.loginout()
			
			

		},
		methods: {
			xgbz(){
				this.dialogVisible = false
				// console.log(this.bzxg);
				this.ruleForm.c_c_name = this.bzxg.c_c_name
				this.ruleForm.eu_name = this.bzxg.eu_name
				this.ruleForm.indust = this.bzxg.indust
				this.ruleForm.c_std_code = this.bzxg.c_std_code
				this.ruleForm.act_date = this.bzxg.act_date
				this.ruleForm.abo_date = this.bzxg.abo_date
				this.ruleForm.issue_date = this.bzxg.issue_date
				this.ruleForm.send_date = this.bzxg.send_date
				this.ruleForm.types = this.bzxg.types
				this.ruleForm.std_nature = this.bzxg.std_nature
				this.ruleForm.state = this.bzxg.state
				this.ruleForm.recension = this.bzxg.recension
				this.ruleForm.department = this.bzxg.department
				this.ruleForm.act_department = this.bzxg.act_department
				this.ruleForm.exe_department = this.bzxg.exe_department
				this.ruleForm.ch_no = this.bzxg.ch_no
				this.ruleForm.eu_no = this.bzxg.eu_no
				this.ruleForm.plan_num = this.bzxg.plan_num
				this.ruleForm.industry = this.bzxg.industry
				this.ruleForm.filing_number = this.bzxg.filing_number
				this.ruleForm.filing_cement = this.bzxg.filing_cement
				this.ruleForm.c_c_name_replace = this.bzxg.c_c_name_replace
				this.ruleForm.c_c_name_similar = this.bzxg.c_c_name_similar
				this.ruleForm.mailing_address = this.bzxg.mailing_address
				this.ruleForm.principal = this.bzxg.principal
				this.ruleForm.relying_unit_name = this.bzxg.relying_unit_name
				this.ruleForm.drafter = this.bzxg.drafter
				this.ruleForm.drafter_unit = this.bzxg.drafter_unit
				this.ruleForm.ranges = this.bzxg.ranges
				this.ruleForm.pk_id = this.bzxg.pk_id
			},
			submitUpload(){
				// console.log(this.$refs.upload.uploadFiles)
				console.log(this.ruleForm);
				this.$refs["ruleForm"].validate(valid => {
					console.log('----');
					console.log(valid);
				     if (valid) {
				       console.log("submit here!")
					   if(this.$refs.upload.uploadFiles.length>0){
					   	// console.log('------有文件');
					   	this.$refs.upload.submit();
					   }else{
					   	// console.log('------没有文件');
					   	this.$axios({
					   		url:'/record/',
					   		method:'POST',
					   		headers:{
					   			 'Content-Type':'application/json',
					   		},
					   		data:{
					   			info:this.ruleForm
					   		}
					   	}).then(res=>{
					   		console.log(res);
					   		if(res.data.state == 203){
					   									  this.$message.success(res.data.info)
					   		}else{
					   									  this.$message.warning(res.data.info)
					   		}
					   	}).catch(err=>{
					   		// console.log(err);
					   	})
					   }
				     }else{
						 // console.log('时间不正确');
						  document.documentElement.scrollTop = 0;
						 this.$message.error('请填写正确格式')
					 }
				});
				
			},
			submitUpload2(){
				// console.log(this.$refs.upload.uploadFiles)
				console.log(this.ruleForm);
			

					   if(this.$refs.upload.uploadFiles.length>0){
					   	// console.log('------有文件');
					   	this.$refs.upload.submit();
					   }else{
					   	// console.log('------没有文件');
					   	this.$axios({
					   		url:'/record/',
					   		method:'POST',
					   		headers:{
					   			 'Content-Type':'application/json',
					   		},
					   		data:{
					   			info:this.ruleForm
					   		}
					   	}).then(res=>{
					   		console.log(res);
					   		if(res.data.state == 203){
					   									  this.$message.success(res.data.info)
					   		}else{
					   									  this.$message.warning(res.data.info)
					   		}
					   	}).catch(err=>{
					   		// console.log(err);
					   	})
					   }
				   
				
			},
			cclimit(){
						  this.$message.warning('最多只能上传一份文件')
			},
			erros(err){
						  console.log(err);
			},
			succ(res){
						  console.log(res);
						  if(res.status == 203){
							  this.$message.success('感谢您的贡献！')
						  }else{
							  this.$message.warning(res.info)
						  }
			},
			blurCcname(){
				// console.log(this.ruleForm.c_c_name);
				
			},
			loginout(){
				let token = JSON.parse(window.localStorage.getItem('token'))
				// console.log(token);
				if(token==null){
					this.tokens = false
				}else{
					this.tokens = true
					// console.log(token);
					// this.headhost = 'https://www.biaozhuncha.com/api/media/'+token.info.headshot
				}
			},
			layout(){
				this.$axios({
					url:'/logout/'
				}).then(res=>{
					window.localStorage.removeItem('token')
					this.$message.success('退出成功')
					this.loginout()
				
				})
			},
			gologin(){
				this.$router.push({path:'/login'})
			},
			handleRemove(file, fileList) {
			       // console.log(file, fileList);
			     },
		}
	}
</script>

<style scoped="scoped">
	@media screen and (max-width: 750px) {
		.about{
			width: 1920px;
		}
	}
	.header {
		background: url(../assets/images/detail/detailhead.png) no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		width: 100%;
		height: 220px;
	}

	.btn {
		width: 60px;
		height: 36px;
		text-align: center;
		border: 1px solid #f32727;
		outline: none;
		background-color: #FFFFFF;
		border-radius: 6px;
		color: #f32727;
	}
	.btn:hover{
		background-color: #f32727;
		color: #FFFFFF;
	}
>>> .el-step__head.is-success {
  color: #f32727;
  border-color: #f32727;
  font-size: 14px;
  font-weight: 400;
}
>>> .el-step__title.is-success {
    font-weight: bold;
    color: #f32727;
	font-size: 14px;
	font-weight: 400;
}
>>> .el-step__description.is-success {
    color: #f32727;
	font-size: 14px;
	font-weight: 400;
}


.hvers{
	color: #101010;
	text-decoration: none;
}

.hvers:hover{
	background-color: #F32727;
	color: #FFFFFF;
}
.harda{
	display: flex;justify-content: center;line-height: 60px;
}
.harda:hover{
	box-shadow: 0 0 10px #fdecec;
	border-radius: 10px;
	color: #0077AA;
}
.btnover{
	width: 280px;
	height: 60px;
	cursor: pointer;
	text-align: center;
	line-height: 60px;
	border: 1px solid #F32727;
	font-size: 16px;
	border-radius: 10px;
	color: #F32727;
}
.btnover:hover{
	background-color: #F32727;
	color: #FFFFFF;
}
.aboutimg{
	background: url(../assets/images/about/aboutone.png)no-repeat;
	background-size: cover;
	-webkit-background-size: cover;
}
.twoimg{
	background: url(../assets/images/about/abouttwo.png)no-repeat;
	background-size: cover;
	-webkit-background-size: cover;
}
.jianbian{
	width: 698px;
	height: 175px;
	background: url(../assets/images/about/header.png)no-repeat;
	background-size: cover;
	-webkit-background-size: cover;
}
</style>
