<template>
	<div style="background-color: #f8f8f8;">
		
		<div style="display: flex;justify-content: center;">
			<div style="width: 1200px;background-color: #ffffff;margin-top: 40px;border-radius: 0 45px 0 0;">
				<div style="width: 100%;">
					<!-- 头部信息展示 -->
					<div class="head" style="display: flex;align-items: center;line-height: 46px;">
						<div class="iconfont icon-user" style="font-size: 19px;color: #FFFFFF;margin-left: 26px;"></div>
						<div style="font-size: 18px;color: #FFFFFF;margin-left: 10px;" v-if="user">{{user.name}}</div>
					</div>

					<div style="display: flex;">
						<div style="display: flex;margin-top: 20px;">
							<div style="width: 8px;height: 30px;background-color: #f9a31c;border-radius: 2px;"></div>
							<div style="margin-left: 16px;display: flex;">
								<div style="font-size: 18px;color: #101010;font-weight: 800;margin-top: 3px;">简介：</div>
								
							</div>
						</div>
						<div style="width: 1063px;font-size: 14px;color: #4a4b4b;margin-left: 10px;margin-top: 28px;line-height:23px;line-height: 25px;">
							<div v-if="user">负责专业范围为{{user.specialized}}</div>
							<div v-if="user">擅长专业为{{user.spec_range}}</div>
						</div>
						
					</div>
				<div style="margin-left: 26px;margin-right: 26px;margin-top: 5px;">
					<el-divider></el-divider>
				</div>
						
				
				</div>
				
			<div style="display: flex;margin-top: 44px;">
				<div style="width: 8px;height: 30px;background-color: #f9a31c;border-radius: 2px;"></div>
				<div style="margin-left: 16px;display: flex;">
					<div style="font-size: 18px;color: #101010;font-weight: 800;margin-top: 3px;">标委会信息</div>
				</div>
				
			</div>
				<div style="display: flex;justify-content: center;margin-top: 25px;"  v-for="i in user.len" :key="i">
					<div style="width: 1144px;border: 1px solid #e5e5e5;overflow: hidden;">
						<div v-for="(d,q) in user.unit[i-1]" style="width: 1144px;height: 45px;background-color: rgba(251,155,124,.2);line-height: 45px;color: #4a4b4b;padding-left: 20px;overflow: hidden;display: flex;">
							<div style="width: 84px;">
								<p style="float: right;">标委会编号：</p>
							</div>
							<div style="">{{q}}</div>
						</div>
						
						<div style="width: 1144px;height: 45px;line-height: 45px;color: #4a4b4b;padding-left: 20px;overflow: hidden;display: flex;">
							<div style="width: 84px;">
								<p style="float: right;">标委会名称：</p>
							</div>
							<div style="">{{user.committee__full_name}}</div>
						</div>
						
						<div style="width: 1144px;height: 45px;line-height: 45px;color: #4a4b4b;padding-left: 20px;overflow: hidden;display: flex;background-color: rgba(251,155,124,.2);" v-for="(d,q) in user.unit[i-1]">
							<div style="width: 84px;" >
								<p style="float: right;">标委会职务：</p>
							</div>
							<div style="" v-if="user">{{d}}</div>
						</div>
						<div style="width: 1144px;height: 45px;line-height: 45px;color: #4a4b4b;padding-left: 20px;overflow: hidden;display: flex;" v-for="(t,q) in user.join_time[i-1]">
							<div style="width: 84px;">
								<p style="float: right;">加入时间：</p>
							</div>
							<div style="" v-if="user">{{t}}</div>
						</div>
						<div style="width: 1144px;height: 45px;line-height: 45px;color: #4a4b4b;padding-left: 20px;overflow: hidden;display: flex;background-color: rgba(251,155,124,.2);" v-for="(w,q) in user.position[i-1]">
							<div style="width: 84px;">
								<p style="float: right;">工作单位：</p>
							</div>
							<div style="" v-if="user" v-for="(a,b) in w">{{a}}</div>
						</div>
					</div>
				</div>
		
				
				<!-- <div style="display: flex;margin-top: 44px;">
					<div style="width: 8px;height: 30px;background-color: #f9a31c;border-radius: 2px;"></div>
					<div style="margin-left: 16px;display: flex;">
						<div style="font-size: 18px;color: #101010;font-weight: 800;margin-top: 3px;">涉及标准计划</div>
						
					</div>
					
				</div> -->

				<!-- <div class="table">
					<el-table :data="draList" style="margin-top: 30px;width: 100%;" align="center" :header-cell-style="{backgroundColor: '#ffdddc',color:'#101010',width: '100%',borderRight:'2px solid #ffffff',textAlign:'center',borderRadius:'3px',opacity:'.8',fontWeight:'550',height:'40px',fontSize:'16px'}" :cell-style="TableRowClassName">
					 		  <el-table-column
					 		        type="index"
					 				label="序号"
					 		        width="75"
										align="center">
					 		      </el-table-column>
					    <el-table-column
					      prop="number"
					      label="计划号"
					      width="180"
							   align="center">
					    </el-table-column>
					 	
					    <el-table-column
					      prop="name"
					      label="计划名称"
					      width="390"
					      :show-overflow-tooltip="true"
							   align="center">
					    </el-table-column>
					 		
					 		<el-table-column
					 		  prop="ding"
					 		  label="制修订"
					 		  width="110"
					 			   align="center">
					 		</el-table-column> 
					   
					    <el-table-column
					      prop="fabu"
					      label="计划下达日期"
					      width="190"
							   align="center">
					    </el-table-column>
					 		
					 <el-table-column
					   prop="status"
					   label="项目状态"
					   width="170"
					 								   align="center">
					 </el-table-column>
					 		 
					 		 
					    </el-table>
				</div>
				<el-pagination
				    layout="prev, pager, next,jumper"
										background
									   :current-page="sjjhPage"
									   :page-count="totals"
									   :page-size="20"
									   @current-change="handleSizeChange"
									   align="center"
									   id="pages"
									   >
				  </el-pagination> -->
				
				
		<!--	<div style="display: flex;margin-top: 44px;">
				<div style="width: 8px;height: 30px;background-color: #f9a31c;border-radius: 2px;"></div>
				<div style="margin-left: 16px;display: flex;">
					<div style="font-size: 18px;color: #101010;font-weight: 800;margin-top: 3px;">涉及标准</div>
					
				</div>
				
			</div>
			
			 <div class="table"> 
				<el-table :data="user.info.standard" style="margin-top: 30px;width: 100%;" align="center" :header-cell-style="{backgroundColor: '#ffebdf',color:'#101010',width: '100%',borderRight:'2px solid #ffffff',textAlign:'center',borderRadius:'3px',opacity:'.8',fontWeight:'550',height:'40px',fontSize:'16px'}" :cell-style="TableRowClassName">
				 		  <el-table-column
				 		        type="index"
				 				label="序号"
				 		        width="75"
									align="center">
				 		      </el-table-column>
				    <el-table-column
				      prop="number"
				      label="标准号"
				      width="180"
						   align="center">
				    </el-table-column>
				 		
				    <el-table-column
				      prop="name"
				      label="标准名称"
				      width="400"
				      :show-overflow-tooltip="true"
						   align="center">
				    </el-table-column>
				 		
				 		 
				   
				    <el-table-column
				      prop="fabu"
				      label="发布日期"
				      width="160"
						   align="center">
				    </el-table-column>
				 		 <el-table-column
				 		   prop="shishi"
				 		   label="实施日期"
				 		   width="160"
							   align="center">
				 		 </el-table-column>
				 <el-table-column
				   prop="status"
				   label="标准状态"
				   width="140"
				 								   align="center">
				 </el-table-column>
				 		 
				 		 
				    </el-table>
			</div> 
				-->
			
			</div>
		</div>
	
	<div style="height: 40px;width: 100%;"></div>
	
	</div>
</template>

<script>
	export default{
		data(){
			return{
				sjjhPage:4,
				// 总条数
				totals:10,
				sear:'标委会名称标委会名称标委会名称标委会名称',
				draList:[{
					number:'20183248-Q-464',
					name:'人体血液及血液成分袋式塑料容器 第4部分：内容...内容...内容...内容...内容...内容...',
					fabu:'2016-06-12',
					shishi:'2016-06-12',
					status:'已发布',
					ding:'制定'
				},{
					number:'20183248-Q-464',
					name:'人体血液及血液成分袋式塑料容器 第4部分：内容...内容...',
					fabu:'2016-06-12',
					shishi:'2016-06-12',
					status:'已发布',
					ding:'制定'
				},{
					number:'20183248-Q-464',
					name:'人体血液及血液成分袋式塑料容器 第4部分：内容...',
					fabu:'2016-06-12',
					shishi:'2016-06-12',
					status:'已发布',
					ding:'制定'
				},{
					number:'20183248-Q-464',
					name:'人体血液及血液成分袋式塑料容器 第4部分：内容...',
					fabu:'2016-06-12',
					shishi:'2016-06-12',
					status:'已发布',
					ding:'制定'
				}],
				bz:[
					{
						number:'20183248-Q-464',
						name:'人体血液及血液成分袋式塑料容器 第4部分：内容...',
						fabu:'2016-06-12',
						shishi:'2016-06-12',
						status:'现行',
					},{
						number:'20183248-Q-464',
						name:'人体血液及血液成分袋式塑料容器 第4部分：内容...',
						fabu:'2016-06-12',
						shishi:'2016-06-12',
						status:'现行',
					},{
						number:'20183248-Q-464',
						name:'人体血液及血液成分袋式塑料容器 第4部分：内容...',
						fabu:'2016-06-12',
						shishi:'2016-06-12',
						status:'现行',
					},{
						number:'20183248-Q-464',
						name:'人体血液及血液成分袋式塑料容器 第4部分：内容...',
						fabu:'2016-06-12',
						shishi:'2016-06-12',
						status:'现行',
					}
				],
				user:{}
			}
		},
		mounted() {
		
				this.user = JSON.parse(this.$route.query.drafter)
			
			// console.log(this.user);
			// console.log(this.$route.query.drafter);
			this.getinfo()
		},
		methods:{
			TableRowClassName(){
				return 'background:#f5f5f5;border-bottom:1px solid #ffffff;'
			},
			getinfo(){
				// this.$axios({
				// 	url:'/drafter/',
				// 	params:{
				// 		pk_id:this.user
				// 	}
				// }).then((result) => {
				// 	console.log(result);
				// }).catch((err) => {
				// 	console.log(err);
				// });
			},
			handleSizeChange(v){
				// console.log(v);
			}
		}
	}
</script>

<style scoped="scoped">
	.head{
		width: 100%;
		height: 46px;
		background: url(../assets/images/detail/draferhead.png) no-repeat;
		background-size: cover;
	}
	.el-table__header-wrapper{
		background-color: #000000;
	}
	.table .el-table::before{
	    background-color: #FFFFFF;
	  }
	.table /deep/  .el-table, .el-table__expanded-cell {
	    background-color: transparent;
	}
	.el-table__row.statistics-warning-row {
	background: #F7FFFE;
	}
	
</style>
