<template>
	<div class="searchbj">
		<Tablist></Tablist>
		
		
	
	
	<!-- 搜索参数 -->
	<div style="display: flex;justify-content: center;align-items: center;cursor:pointer;margin-top: 20px;">
	
			<div v-for="(item,index) in options" :key="index" @click="tolink(item,index)" class="opcss" :class='{opbj:index==opindex}'>{{item}}</div>
			
	</div>
	<div style="display: flex;justify-content: center;margin-top: 30px;" >
		<div style="width: 900px;height: 55px;border-radius: 10px;display: flex;justify-content: space-between;align-items: center;background-color: #ffffff;overflow: hidden;">
			<input type="text" style="margin-left: 20px;width: 800px;background-color:transparent" placeholder="请输入标准名称、标准号等"  v-model="inpv" @blur="onblursave" @keyup.enter="entersearch" >
				<img src="../assets/images/searchicon.png" @click="search1" style="width: 36px;height: 36px;margin-right: 20px;cursor:pointer;">
		</div>
	</div>
	<div style="display: flex;justify-content: center;overflow: hidden;margin-top: 30px;">
		<div style="display: flex;flex-direction: column;justify-content: center;width: 1040px;">
			<div style="display: flex; margin-top: 20px;align-items: flex-start;">
				<div style="display: flex;align-items: center;">
					<div  style="font-size: 16px;color: #333333;margin-right: 10rpx;font-weight: 800;">分类</div>
					
					<div v-for="(item,index) in fenlei" :key="index" style="width: 80px;height: 30px;line-height: 30px;margin-left: 15px;cursor:pointer;" @click="fenClick(item,index)" class="searchcss" :class='{flbj:index==fencurrent}'>
						<div style="text-align: center;font-size: 26rpx;">{{item}}</div>
					</div>
				</div>
			</div>
			
			<div style="display: flex;align-items: flex-start;margin-top: 30px;">
				<div style="display: flex;flex-wrap: wrap;">
					<div  style="font-size: 16px;color: #333333;margin-right: 10rpx;font-weight: 800;">状态</div>
				
					
					<div style="width: 1000px;height:80px; display: flex;flex-wrap: wrap;align-items: flex-start;">
						<div v-for="(item,index) in states" :key="index" style="width: 80px;height: 30px;line-height: 30px;margin-left: 15px;cursor:pointer" @click="stateClick(item,index)" class="searchcss" :class='{flbj:index==statecurrent}'>
							<div style="text-align: center;font-size: 26rpx;">{{item}}</div>
						</div>
					</div>
				</div>
			</div>
			
			
			<div style="display: flex;align-items: flex-start;margin-top: 10px;">
				<div style="display: flex;align-items: center;">
					<div  style="font-size: 16px;color: #333333;margin-right: 10rpx;font-weight: 800;">精确搜索</div>
				
					<div v-for="(item,index) in timer" :key="index" style="width: 80px;height: 30px;line-height: 30px;margin-left: 20px;cursor:pointer;" @click="timeClick(item,index)" class="searchcss" :class='{flbj:index==timecurrent}'>
						<div style="text-align: center;font-size: 26rpx;">{{item}}</div>
					</div>
				</div>
			</div>
		</div>
	</div> 
	 <div class="table">
	 <el-table :data="searchData" style="margin-top: 30px;width: 100%;" align="center" :header-cell-style="{backgroundColor: 'rgb(255,230,162)',color:'#101010',width: '100%',borderRight:'2px solid #ffffff',textAlign:'center',borderRadius:'3px',opacity:'.8',fontWeight:'550',height:'40px',fontSize:'16px'}" stripe :cell-style="TableRowClassName" @row-click="toDetail">
	  		  <el-table-column
	  		        type="index"
	  				label="序号"
	  		        width="75"
					align="center">
	  		      </el-table-column>
	     <el-table-column
	       prop="c_std_code"
	       label="标准号"
	       width="170"
		   align="center">
	     </el-table-column>
	  		 <el-table-column
	  		   prop="types"
	  		   label="标准分类"
	  		   width="120"
			   align="center">
	  		 </el-table-column>
	     <el-table-column
	       prop="c_c_name"
	       label="标准名称"
	       width="300"
	       :show-overflow-tooltip="true"
		   align="center"
		   >
	     </el-table-column>
	  		 <el-table-column
	  		   prop="state"
	  		   label="状态"
	  		   width="110"
			   align="center">
	  		 </el-table-column>
	  		 
	    
	     <el-table-column
	       prop="issue_date"
	       label="发布日期"
	       width="130"
		   align="center">
	     </el-table-column>
	  		 <el-table-column
	  		   prop="act_date"
	  		   label="实施日期"
	  		   width="130"
			   align="center">
	  		 </el-table-column>
	  
	  		 <el-table-column
	  		      
	  		       label="操作"
	  		       width="150"
				   align="center">
	  		       <template slot-scope="scope" >
	  		         <!-- <el-button @click="toDetail(scope.row)" type="text" size="small">查看</el-button> -->
	  		         <!-- <el-button type="text" size="small">分享</el-button> -->
					 <!-- <i class="iconfont icon-yulanchakan iconhover" style="font-size: 26px;margin-right: 18px;cursor: pointer;" @click="toDetail(scope.row)"></i> -->
					 <div style="display: flex;justify-content: space-between;align-items: center;z-index: 999;" @click.stop="">
						 <div class="btn2" @click.stop="openimg(scope.row)" style="margin: 0 auto;" v-if="scope.row.content!=''&&scope.row.content!=null">预览</div>
						 <div>
						 						 <div class="btn2" @click="down(scope.row)" style="margin: 0 auto;" v-if="scope.row.content!=''&&scope.row.content!=null">PDF</div>
						 						 <div class="btn2" @click="yyclick(scope.row.pk_id)" style="margin: 0 auto;" v-if="scope.row.content==''||scope.row.content==null">预约</div>
						 </div>
					 </div>
					 <!-- <i class="iconfont icon-fenxiang iconhover" style="font-size: 26px;margin-left: 5px;cursor: pointer;" @click="share(scope.row)"></i> -->
	  		       </template>
	  		     </el-table-column>
	  		 
	     </el-table>
		
			 
		 </div>
	<el-pagination
	    layout="prev, pager, next,jumper"
							background
						   :current-page="currentPage"
						   :page-count="totals"
						   :page-size="20"
						   @current-change="handleSizeChange"
						   align="center"
						   id="pages"
						   >
	  </el-pagination>
	  <el-dialog
	    title="PDF预览"
	    :visible.sync="imgflag"
	    width="30%"
	    center
		v-loading="imgload"
		>
	    <div style="display: flex;justify-content: center;">
			<img :src="imgsrc" alt="" style="width: 85%;"/>
		</div>
	  </el-dialog>
	  <div style="width: 100%;height: 20px;"></div>

	</div>
</template>

<script>
	import Tablist from '../common/Tab.vue'
	export default{
		components:{
			Tablist,
		},
		data(){
			return{
				pagecolor:'#f32727',
				inpv:'智慧城市',
				// 总条数
				totals:1,
				// 当前页数
				currentPage:1,
				//标准分类
				fenlei:['全部','国家标准','行业标准','地方标准','企业标准','国际标准','团体标准'],
				searchData:[],
				//搜索传参分类
				fenValue:'国家标准',
				//默认展示的分类
				fencurrent:1,
				//搜索传参状态
				states:['全部','现行','废止','即将实施','正在审查','正在批准','正在起草','正在公示','征求意见','有更新版','责令改正','被代替','不执行'],
				statecurrent:0,
				//搜索传参状态
				stateValue:'全部',
				timecurrent:0,
				timer:['默认','标准号','标准名称','起草人','起草单位'],
				timeValue:'最新',
				scrollToTop:'',
				scrollTop:0,
				options:['查标准','查产品','查规范'],
				opindex:0,
				imgflag:false,
				imgsrc:'',
				imgload:false
			}
		},
		
		mounted () {
		  // window.addEventListener('scroll', this.scrollToTop,true)
		},
		destroyed () {
		  // window.removeEventListener('scroll', this.scrollToTop,true)
		},
		created(e) {
		let token = JSON.parse(window.localStorage.getItem('token'))
		// console.log(token);
		if(token){
			this.$axios({
				url:'/push_info/',
				params:{
					input:token.info.telephone
				}
			}).then(res=>{
				// console.log(res);
				
			})
		}
			
			
			
			let inv =	JSON.parse(window.sessionStorage.getItem('search'))
			this.inpv = inv
			// console.log(inv);
			console.log(this.$route.query.draftunit);
			if(this.$route.query.draftunit==1){
				this.fenValue = '全部'
				this.fencurrent = 0
			}
			if(inv==null||inv==''){
				console.log('没有值')
				// window.sessionStorage.setItem('search',JSON.stringify('智慧城市'))
				// this.$axios({
				// 	url: '/querylist/',
				// 	params: {
				// 		input: '智慧城市',
				// 		page_num:20,
				// 		types:this.fenValue,
				// 		state:this.stateValue,
				// 		time:this.timeValue
				// 	}
				// }).then(res=>{
				// 	// console.log(res);
				// 	if(res.data.info.status==404||res.data.status==404){
				// 			this.$message.error('暂无搜索结果')
				// 	}else{
				// 		if(res.data.status==200){
				// 			this.searchData = res.data.info
				// 			this.$message.success('搜索成功！')
				// 			// window.sessionStorage.setItem('searchData',JSON.stringify(res.data.info))
				// 				let a = res.data.count
				// 				let b = res.data.info.length
				// 				// console.log(a,b);
				// 				// console.log(a/b);
				// 				let c = a/b
							
				// 				this.totals =Math.ceil(c)
				// 		}
						
					
				// 	}
				// }).catch(err=>{
				// 	// console.log(err);
				// })
			}else{
				// console.log('有值')
				
				let inv =	JSON.parse(window.sessionStorage.getItem('search'))
				this.inpv = inv
				// console.log(inv);
					if(inv==''){
						// this.$message.error('请您输入内容后搜索')
					}else{
						this.$axios({
							url: '/querylist/',
							params: {
								input: inv,
								page_num:20,
								types:this.fenValue,
								state:this.stateValue,
								time:this.timeValue,
								condition: this.timeValue
							}
						}).then(res=>{
							// console.log(res);
							if(res.data.info.status==404||res.data.status==404){
								
									
									this.$message.error('暂无搜索结果')
							}else{
								if(res.data.status == 200){
									this.$message.success('搜索成功！')
									this.searchData = res.data.info
									// window.sessionStorage.setItem('searchData',JSON.stringify(res.data.info))
										let a = res.data.count
										let b = res.data.info.length
										// console.log(a,b);
										// console.log(a/b);
										let c = a/b
									
										this.totals =Math.ceil(c)
								}
								
							
							}
						}).catch(err=>{
							// console.log(err);
						})
					}
			}
			
			
		},
		methods:{
			openimg(r){
				this.imgsrc = ''
				this.imgload = true
				this.imgflag = true
				console.log(r);
				this.$axios({
					url: '/search/',
					params: {
						pkid: r.pk_id,
						read: 1
					}
				}).then(res => {
					console.log(res);
					if (res.data.status == 200) {
						this.imgload = false
						this.imgsrc = "data:image/png;base64,"+res.data.info.image.replace(new RegExp(" ", "gm"), "+");
					} else {
						this.$message.error('暂无预览')
					}
				
				}).catch(err=>{
					uni.hideLoading()
				})
			},
			yyclick(pkid){
				
				let token = JSON.parse(window.localStorage.getItem('token'))
					// console.log(token);
				if(token){
					// console.log(token);
						
						this.$axios({
							url: '/search/',
							params: {
								pkid: pkid
							}
						}).then(res => {
							console.log(res);
							this.$message.success('已通知站长上传！')
						
						})
					
				}else{
					this.$message.error('您还没有登录')
					setTimeout(()=>{
						this.$router.push({path:'/login'})
					},1200)
				}
				
				
				
			},
			down(item){
				
						// console.log(this.search.content);
					// console.log(pkid);
						let token = JSON.parse(window.localStorage.getItem('token'))
						// console.log(token);
					if(token){
						// console.log(token);
						if(token.info.is_vip==true){
							if(item.content!=''||item.content!=null){
								location.href='/url'
								window.location.href="https://www.biaozhuncha.com/api/download_text/?pk_id="+item.pk_id;  
							}else{
								this.$message.warning('暂无PDF')
							}
						
							
						}else{
							this.$message.error('开通会员解锁更多功能!')
						}
					}else{
						this.$message.error('您还没有登录')
						setTimeout(()=>{
							this.$router.push({path:'/login'})
						},1200)
					}
					
					
					
				
			
			
			},
			tolink(item,index){
				this.opindex = index
				// console.log(this.opindex)
				if(item=="查标准"){
					this.$router.push({path:'/search'})
				}
				if(item=="查产品"){
					this.$router.push({path:'/product'})
				}
				if(item=="查规范"){
					this.$router.push({path:'/searchgf'})
				}
			},
			entersearch(){
				window.sessionStorage.setItem('search',JSON.stringify(this.inpv))
				this.search1()
			},
			TableRowClassName({ row, column, rowIndex, columnIndex }){
				if(rowIndex%2==1){
					return 'background:rgba(255,221,162,.2)'
				}else{
					return ''
				}
			},
			onblursave(){
				// console.log(this.inpv);
				window.sessionStorage.setItem('search',JSON.stringify(this.inpv))
			},
			search1(){
				// window.sessionStorage.setItem('search',JSON.stringify(this.inpv))
			let inv =	JSON.parse(window.sessionStorage.getItem('search'))
			// console.log(inv);
				if(inv==''){
					this.$message.error('请您输入内容后搜索')
				}else{
					this.$axios({
						url: '/querylist/',
						params: {
							input: inv,
							page_num:20,
							types:this.fenValue,
							state:this.stateValue,
							time:this.timeValue,
							condition: this.timeValue
						}
					}).then(res=>{
						console.log(res);
						if(res.data.info.status==404||res.data.status==404){
								this.$message.error('暂无搜索结果')
						}else{
							if(res.data.status == 200){
								this.searchData = res.data.info
								this.$message.success('搜索成功！')
								// window.sessionStorage.setItem('searchData',JSON.stringify(res.data.info))
									let a = res.data.count
									let b = res.data.info.length
									// console.log(a,b);
									// console.log(a/b);
									let c = a/b
								
									this.totals =Math.ceil(c)
							}
							
						
						}
					}).catch(err=>{
						// console.log(err);
					})
				}
			},
			fenClick(item,index){
				// console.log(item);
				this.fenValue = item
				this.fencurrent = index
				// console.log(this.fenValue);
				// console.log(this.inpv);
				let inv =	JSON.parse(window.sessionStorage.getItem('search'))
				if(inv==''){
					this.$message.error('请您输入内容后搜索')
				}else{
					this.search1()
				}
			},
			stateClick(item,index){
				this.stateValue = item
				this.statecurrent = index
				// console.log(this.stateValue);
				let inv =	JSON.parse(window.sessionStorage.getItem('search'))
				if(inv==''){
					this.$message.error('请您输入内容后搜索')
				}else{
					this.search1()
				}
			},
			timeClick(item,index){
				this.timeValue = item
				this.timecurrent = index
				let inv =	JSON.parse(window.sessionStorage.getItem('search'))
				if(inv==''){
					this.$message.error('请您输入内容后搜索')
				}else{
					this.search1()
				}
			},
			handleSizeChange(val){
				// console.log(val);
				let nums = val*20
				// console.log(nums);
				
				// if(this.inpv==''){
				// 	this.$message.error('请您输入内容后搜索')
				// }else{
					let inv =	JSON.parse(window.sessionStorage.getItem('search'))
					this.$axios({
						url: '/querylist/',
						params: {
							input: inv,
							page_num:nums,
							types:this.fenValue,
							state:this.stateValue,
							time:this.timeValue,
							condition: this.timeValue
						}
					}).then(res=>{
						// console.log(res);
						this.searchData = res.data.info
						// this.backTop()
					}).catch(err=>{
						// console.log(err);
					})
				// }
				
			},
			toDetail(row,column,event){
				console.log(row)
			
				let id =JSON.stringify(row.pk_id);

				const {href} = this.$router.resolve(
				{ 
				  path: '/detail',
				  query: {body: id}}
				)
				let token = JSON.parse(window.localStorage.getItem('token'))
				// console.log(token);
				if(token){
					window.open(href, '_blank')
				}else{
					this.$message.error('您还没有登录')
					setTimeout(()=>{
						this.$router.push({path:'/login'})
					},1200)
				}
				

			},
			backTop () {
			      const that = this
			      let timer = setInterval(() => {
			        let ispeed = Math.floor(-that.scrollTop / 5)
			        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
			        if (that.scrollTop === 0) {
			          clearInterval(timer)
			        }
			      }, 16)
			  },
			share(i){
				// console.log('wxopen');
				// wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
				//   wx.updateAppMessageShareData({ 
				//     title: '123', // 分享标题
				//     desc: '123456', // 分享描述
				//     link: 'www.baidu.com', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				//     imgUrl: '', // 分享图标
				//     success: function () {
				//       // 设置成功
				//     }
				//   })
				// }); 
				
			}
		}
	}
</script>

<style scoped="scoped">
	.searchbj{
		margin: 0;
		padding: 0;
	}
	.searchcss{
		font-size: 14px;
	}
	.flbj{
		background: url(../assets/images/quire.png) no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		background-position: center;
		border-radius: 5px;
		color: #FFFFFF;
	}
	.tableStyle{
		background-color: #000000;
	}
	.el-table__header-wrapper{
		background-color: #000000;
	}
	.table .el-table::before{
	    background-color: #FFFFFF;
	  }
	.table /deep/  .el-table, .el-table__expanded-cell {
	    background-color: transparent;
	}
	.el-table__row.statistics-warning-row {
	background: #F7FFFE;
	}
	.iconhover:hover{
		color: red;
	}
	.el-table__row.statistics-warning-row :hover{
		background-color: red!important;
	}
	.opcss{
		font-size: 30px;
		color: #FFFFFF;
		width: 160px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.opbj{
		position: relative;
		/* color: red; */
	}
	.opbj:before{
		content: "";/*单引号双引号都可以，但必须是英文*/
		 
		    width: 50px;
		 
		    height: 1.5px; /*下划线高度*/
		 
		    background: #FFFFFF; /*下划线颜色*/
		 
		    position: absolute;
		 
		    top: 110%;
		 
		    left: 35%;
		 
		    transition: all .8s ; /*css动画效果，0.8秒完成*/
	}
	.btn2 {
			width: 0.5rem;
			height: 0.25rem;
			text-align: center;
			line-height: 0.25rem;
			/* border: 1px solid #f32727; */
			background-color: rgb(254, 137, 26);
			border-radius: 4px;
			color: #fff;
			cursor: pointer;
		}
		/* .btn2:hover{
			background-color: #f32727;
			color: #FFFFFF;
		} */
</style>
<style>
	#pages.is-background .el-pager li:not(.disabled).active  {
	  background-color: #f32727;  
	}
	/* 如果你只想在hover时改变cursor，可以添加以下代码 */
	.el-table .el-table__body tr.el-table__row:hover {
	  cursor: pointer;
	}
</style>
