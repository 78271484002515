<template>
	<div>
		<div style="">
			<!-- <div style="width: 100%;height: 0.3rem;background-color: rgb(250, 97, 67);color: #FFFFFF;font-size: 0.14rem;display: flex;align-items: center;justify-content: space-between;" >
				<div class="yx">
							<div style="display: flex;align-items: flex-start;justify-content: center;margin-left: 0.3rem;height: 0.3rem;margin-top: 0.1rem;" >
								<p>邮箱：kf@biaozhuncha.com</p>
								<p style="margin-left: 0.2rem;">电话：400-6785-363</p>
							</div>
						</div>
				<div style="margin-right: 0.3rem;height: 0.3rem;margin-top: 0.1rem;" class="headertitle">标准查做您忠诚的标准专家、产品建议家</div>
			</div> -->
			<Tablist style=""></Tablist>
			
			<div style="display: flex;justify-content: center;width: 100%;">
				<div style="width: 100%;display: flex;justify-content: center;">
					<img src="../assets/images/index/index_logo.png" alt="" />
				</div>
			</div>
			<div style="width: 100%;height: 10px;"></div>
		</div>
		<div style="display: flex;justify-content: center;width: 100%;">
			
			
			
			<div style="width: 100%;">
				<!-- <div style="font-size: 0.4rem;color: #FFFFFF;display: flex;justify-content: center;">都在用的标准查询平台</div> -->
				
				
				<div style="display: flex;justify-content: center;align-items: center;cursor:pointer;margin-top: 0.20rem;">
				
						<div v-for="(item,index) in options" :key="index" @click="tolink(item,index)" class="opcss" :class='{opbj:index==opindex}'>{{item}}</div>
						
				</div>
				<div>
				<div style="position: fixed;top: 150px;left:20px; z-index: 999;width: 16%;height: 40%;color: #000000;padding: 10px;background: linear-gradient(to top,#f4302b,#fe8b57);color: #fff;overflow: auto;">
					<div style="display: flex;align-items: flex-end;">
						<div style="font-size: 0.2rem;">热门预约</div>
						<div style="font-size: 14px;margin-left: 20px;">已收录：{{hotyylist.count_true}}  未收录：{{hotyylist.count_all-hotyylist.count_true}}</div>
					</div>
					<div style="overflow: auto;height: 300px;">
						<div v-for="(y,index) in hotyylist.info" :key="index" class="texthov" @click="godetail(y.pk_id)">
							<div style="display: flex;align-items: center;;margin-top: 4px;">
								<div >{{y.c_std_code}}</div>
								<div style="margin-left: 10px;">{{y.types}}</div>
							</div>
							
								<div class="" style="margin-top: 4px;overflow: hidden;white-space: nowrap; text-overflow: ellipsis;">{{y.c_c_name}}</div>
						
							
							<div style="border-bottom: 1px dashed #fff;margin-top: 8px;"></div>
						</div>
					</div>
					
					
				</div>
				
				<div style="position: fixed;top: 520px;left:20px; z-index: 999;width: 16%;height: 40%;color: #000000;padding: 10px;background: linear-gradient(to top,#f4302b,#fe8b57);color: #fff;">
					<div style="font-size: 0.2rem;">最近更新</div>
					
					<div style="overflow: auto;height: 300px;">
						<div v-for="(y,index) in updatelist" :key="index" class="texthov" >
							<div style="">
								<div style="" @click="godetail(y.pk_id)">
									<div style="display: flex;align-items: center;;margin-top: 4px;">
										<div >{{y.c_std_code}}</div>
										<div style="margin-left: 10px;">{{y.types}}</div>
										<div style="margin-left: 10px;">{{y.state}}</div>
									</div>
									
								</div>
								<div style="display: flex;justify-content: space-between;align-items: center;">
									<div class="" style="margin-top: 4px;overflow: hidden;white-space: nowrap; text-overflow: ellipsis;width: 80%;">{{y.c_c_name}}</div>
									<div class="btn2" @click="gz(y.pk_id)">跟踪</div>
								</div>
							</div>
						
							
							<div style="border-bottom: 1px dashed #fff;margin-top: 8px;"></div>
						</div>
					</div>
					
					
				</div>
				
				
				<div v-if="userflag">
					<div style="position: fixed;top: 150px;right:20px; z-index: 999;width: 16%;height: 40%;color: #000000;padding: 10px;background: linear-gradient(to bottom,#f4302b,#fe8b57);color: #fff;" v-if="yyflag==200">
						<div style="font-size: 0.2rem;">预约记录</div>
						
						<div style="overflow: auto;height: 300px;">
							<div v-for="(y,index) in reservalist" :key="index" class="texthov" >
								<div style="">
									<div style="" @click="godetail(y.pk_id)">
										<div style="display: flex;align-items: center;;margin-top: 4px;">
											<div >{{y.c_std_code}}</div>
											<div style="margin-left: 10px;">{{y.types}}</div>
											<div style="margin-left: 10px;">{{y.search_time.slice(0,10)}}</div>
										</div>
										
									</div>
									<div style="display: flex;justify-content: space-between;align-items: center;">
										<div class="" style="margin-top: 4px;overflow: hidden;white-space: nowrap; text-overflow: ellipsis;width: 80%;">{{y.c_c_name}}</div>
										<div class="btn2" @click="downloads(y.pk_id)" v-if="y.is_ready">PDF</div>
										<!-- <div class="btn2" v-else style="background-color: #cecdcc;color: #fff;">下载</div> -->
									</div>
								</div>
							
								
								<div style="border-bottom: 1px dashed #fff;margin-top: 8px;"></div>
							</div>
						</div>
						
						
					</div>
					<div style="position: fixed;top: 150px;right:20px; z-index: 999;width: 16%;height: 40%;color: #000000;padding: 10px;background: linear-gradient(to bottom,#f4302b,#fe8b57);color: #fff;" v-else >
						<div style="font-size: 0.2rem;">预约记录</div>
						
						 <div style="overflow: auto;height: 60px;text-align: center;font-size: 18px;line-height: 60px;">
							暂无预约记录
						</div>
						
						
					</div>
				</div>
				
				<div v-if="userflag">
					<div style="position: fixed;top: 520px;right:20px; z-index: 999;width: 16%;height: 40%;color: #000000;padding: 10px;background: linear-gradient(to bottom,#f4302b,#fe8b57);color: #fff;" v-if="downflag==200" >
						<div style="font-size: 0.2rem;">订阅记录</div>
						
						<div style="overflow: auto;height: 300px;">
							<div v-for="(y,index) in downlist" :key="index" class="texthov" >
								<div style="">
									<div style="" @click="godetail(y.standard__pk_id)">
										<div style="display: flex;align-items: center;;margin-top: 4px;">
											<div >{{y.standard__c_std_code}}</div>
											<div style="margin-left: 10px;">{{y.d_time.slice(0,10)}}</div>
										</div>
											
									</div>
									<div style="display: flex;justify-content: space-between;align-items: center;">
										<div class="" style="margin-top: 4px;overflow: hidden;white-space: nowrap; text-overflow: ellipsis;width: 80%;">{{y.c_c_name}}</div>
										<div class="btn2" @click="downloads(y.standard__pk_id)">PDF</div>
									</div>
								</div>
							
								
								<div style="border-bottom: 1px dashed #fff;margin-top: 8px;"></div>
							</div>
						</div>
						
						
					</div>
					<div style="position: fixed;top: 520px;right:20px; z-index: 999;width: 16%;height: 40%;color: #000000;padding: 10px;background: linear-gradient(to bottom,#f4302b,#fe8b57);color: #fff;"v-else>
						<div style="font-size: 0.2rem;">订阅记录</div>
						
						 <div style="overflow: auto;height: 60px;text-align: center;font-size: 18px;line-height: 60px;">
							暂无订阅记录
						</div>
						
						
					</div>
				</div>
				</div>
				<!-- 搜索框 -->
				<div style="display: flex;justify-content: center;margin-top: 0.20rem;">
					<!-- @keyup.enter="search1" -->
					<div style="display: flex;flex-direction: column;justify-content: center;align-items: flex-start; ">
						<div class="inps" style="">
							<input type="text" style="margin-left: 0.20rem;width: 8rem;"
								placeholder="" @click="searchgo">
							<img src="../assets/images/searchicon.png"
								style="width: 0.36rem;height: 0.36rem;margin-right: 0.20rem;cursor:pointer;margin-top: 0.1rem;" @click="searchgo">
							<!-- <p class="el-icon-search" style="font-size: 20px;margin-right: 20px;cursor:pointer;" @click="search1"></p> -->
						</div>
						<div style="margin-top: 0.20rem;display: flex;align-items: center;">
							<div style="font-family: sousuo;font-size: 0.20rem;color: #ff8c24;">热门搜索：</div>
							<div v-for="(item,index) in hotlist" :key="index"
								style="color: #ff8c24;font-size: 0.20rem;margin: 0 0.1rem 0 0.20rem;cursor: pointer;" @click="search(item)">{{item}}</div>
						</div>
					</div>
					
				</div>
				
				<!-- <div style="display: flex;justify-content: center;">
					<div style="width: 11rem;margin-top: 0.4rem;">
						<div class="jingang">
							<div style="margin: 0.20rem 0 0 0.8rem;" @click="goone">
								<img src="../assets/images/index/cbz.png" style="width: 0.8rem;height: 0.67rem;">
								<div style="text-align: center;color: #101010;font-size: 0.16rem;margin-top: 0.05rem;">查标准</div>
							</div>
					
							<div style="margin: 0.20rem 0 0 1.4rem;" @click="gotwo">
								<img src="../assets/images/index/ccp.png" style="width: 0.64rem;height: 0.65rem;">
								<div style="text-align: center;color: #101010;font-size: 0.16rem;margin-top: 0.05rem;">查产品</div>
							</div>
					
							<div style="margin: 0.20rem 0 0 1.5rem;" @click="gothree">
								<img src="../assets/images/index/cgf.png" style="width: 0.6rem;height: 0.6rem;">
								<div style="text-align: center;color: #101010;font-size: 0.16rem;margin-top: 0.05rem;">查规范</div>
							</div>
					
							<div style="margin: 0.20rem 0 0 1.54rem;" @click="gofour">
								<img src="../assets/images/index/lljl.png" style="width: 0.61rem;height: 0.65rem;">
								<div style="text-align: center;color: #101010;font-size: 0.16rem;margin-top: 0.05rem;">浏览记录</div>
							</div>
					
							<div style="" class="downmargin" @click="gofive">
								<img src="../assets/images/index/wdxz.png" style="width: 0.8rem;height: 0.5rem;">
								<div style="text-align: center;color: #101010;font-size: 0.16rem;margin-top: 0.05rem;">我的下载</div>
							</div>
						</div>
						</div>
				</div> -->
				
				<!-- 	<div style="display: flex;justify-content: center;margin-top: 0.28rem;" @click="pay">
						<img src="../assets/images/index/vip.png" style="width: 1000px;height: 1rem;">
					</div> -->
					<!-- 新闻 -->
					<div style="width: 100%;height: 20px;"></div>
					
						<div style="display: flex;width: 1100px;margin: 0 auto;">
							
								<el-carousel indicator-position="outside" style="width: 800px;float: left;" height="340px">
								    <el-carousel-item v-for="(item,index) in imglist" :key="index" >
								      <img :src="item.urls" v-if="item.flag == 1" style="width: 800px;height: 340px; cursor: pointer;float: left;" alt="" @click="carouselclick(index)">
									  <video src="../assets/images/index/video1.mp4" controls v-if="item.flag == 0" style="width: 800px;cursor: pointer;float: left;height: 340px;"></video>
								    </el-carousel-item>	
								  </el-carousel>
								 
							
							<div style="width: 280px;height: 340px;float: left;clear: both;">
												<img src="../assets/images/index/vip2.png" style="width: 100%;cursor: pointer;" @click="pay">
							</div>
						</div>
					
					<div style="width: 100%;" class="newsmodel">
						<div style="width: 11rem;">
								<div style="display: flex;justify-content: space-between;width: 10.90rem;">
									<div style="width: 7.7rem;" class="box">
										<div style="height: 0.5rem;font-size: 0.24rem;color: #101010;line-height: 0.50rem;margin-left: 0.1rem;font-weight: 700;">
											热门新闻</div>
										<div style="width: 98%;height: 1.55rem;margin-left: 0.1rem;background-color: rgba(245,65,55,.1);display: flex;padding: 0.15rem;color: #4a4b4b;font-size: 0.16rem;overflow: auto;">
											<div style="width: 5rem;height: 100%;">
												<div style="font-size: 0.18rem;color: #101010;">标准查早晚报</div>
												<div style="width: 5rem;margin-top: 0.1rem;">
													<div v-for="(item,index) in papers" :key="index" style="padding: 0 0 0.05rem 0;cursor: pointer;" @click="detailnews(item.num)">{{index+1}}、{{item.title}}</div>
												</div>
											</div>
											<div style="width: 2.8rem;height: 100%;">
												<div>星期{{w}}</div>
												<div>{{y+'-'+m+'-'+d}}</div>
											</div>
										</div>
										
									
								
									</div>
									<div style="width: 3rem;height: 2.04rem;" class="cygn">
										<div style="width: 100%;height: 0.55rem;display: flex;justify-content: space-between;align-items: center;">
											<div style="font-size: 0.24rem;color: #101010;margin-left: 0.16rem;">常用功能</div>
											<div style="font-size: 0.14rem;color: #f32727;cursor: pointer;font-weight: 700;" @click="personalto">
												进入个人中心 
												<div class="el-icon-arrow-right"></div>
											</div>
										</div>
										<div style="width: 100%;height: 1.45rem;">
											<div style="width: 100%;height: 0.48rem;display: flex;align-items: center;cursor: pointer;" @click="mess">
												<img src="../assets/images/index/xx.png" style="width: 0.26rem;height: 0.27rem;margin-left: 0.1rem;">
												<div style="font-size: 0.16rem;color: #101010;margin-left: 0.15rem;">我的消息</div>
											</div>
											
											<div style="width: 100%;height: 0.48rem;display: flex;align-items: center;cursor: pointer;"  @click="track">
												<img src="../assets/images/index/gz.png" style="width: 0.3rem;height: 0.3rem;margin-left: 0.08rem;">
												<div style="font-size: 0.16rem;color: #101010;margin-left: 0.13rem;">我的跟踪</div>
											</div>
											
											<div style="width: 100%;height: 0.48rem;display: flex;align-items: center;cursor: pointer;"  @click="feed">
												<img src="../assets/images/index/yjfk.png" style="width: 0.26rem;height: 0.25rem;margin-left: 0.1rem;">
												<div style="font-size: 0.16rem;color: #101010;margin-left: 0.15rem;">意见反馈</div>
											</div>
										</div>
									</div>
							</div>
						
							<!-- <button @click="downloads">下载</button> -->
							
						<!-- 	<div style="margin: 0.20rem 0 0 0.20rem;overflow: auto;height: 6rem;width: 100%;" class="box2">
								<el-timeline style="width: 7.43rem;">
									<el-timeline-item :timestamp="item.paper_time" placement="top" :color="colortime" v-for="(item,index) in newslist" :key="index">
										<el-card class="card" @click.native="detailnews(item.news__num)">
											
											<div style="">{{item.news__title}}</div>
										</el-card>
								 </el-timeline-item>
								</el-timeline>
							</div> -->
								
							
						</div>
					</div>
					
			
				<div style="width: 100%;height: 30px;"></div>
				<!-- <div @click="tonews">跳转新闻</div> -->
			</div>
		</div>
		
		<!-- <div style="width: 200px;height: 100px;background-color: #fe8b57;position: fixed;top: 100px;right: 0;" @click="goqu">
			问卷调查
		</div> -->
	</div>
	
</template>


<script>
	import Tablist from '../common/Tab.vue'
	export default {
		components:{
			Tablist,
		},
		data() {
			return {
				srcs:require('../assets/images/index/xx.png'),
				hotlist: ['智慧城市', '电动汽车', '医疗器械', '数据安全'],
				colortime: '#f32727',
				papers: [],
				y: '',
				m: '',
				d: '',
				weekArr: ['日', '一', '二', '三', '四', '五', '六'], // 每周
				w: '',
				list:[],
				newslist:[],
				options:['查标准','查产品','查规范'],
				opindex:0,
				hotyylist:'',//热门预约
				updatelist:'',//最近更新
				userflag:false,
				reservalist:'',//预约记录
				downlist:'',//
				downflag:'',//下载有无记录
				yyflag:'',
				imglist:[
					{
						urls:require('../assets/images/index/carousel3.png'),
						flag:1
					},
					{
						urls:'../assets/images/index/video1.mp4',
						flag:0
					}
					,{
					urls:require('../assets/images/index/carousel0.png'),
					flag:1
				},{
					urls:require('../assets/images/index/carousel1.png'),
					flag:1
				},{
					urls:require('../assets/images/index/carousel2.png'),
					flag:1
				},{
					urls:require('../assets/images/index/carousel4.png'),
					flag:1
				}]
				
			}
		},
		created() {
			this.getpaper()
			this.gettime()
			this.getnews()
			this.hotyy()
			this.getupdate()
			let token = JSON.parse(window.localStorage.getItem('token'))
			if(token){
				this.userflag = true
			}else{
				this.userflag = false
			}
			this.reserva()
			this.getdown()
			console.log('-----',navigator.userAgent);
		},
		methods: {
			goqu(){
				this.$router.push({path:'/question'})
			},
			carouselclick(index){
				if(index == 0){
					// window.open('', '_blank')
					this.$router.push({path:'/question'})
				}
				if(index == 2){
					// window.open('', '_blank')
					window.open('https://zhuanlan.zhihu.com/p/700083030', '_blank')
				}
				if(index == 3){
					window.open('https://mp.weixin.qq.com/s/KqVBUwVlrrNxKAZxHGWCZw', '_blank')
				}
				if(index == 4){
					window.open('https://mp.weixin.qq.com/s/xQgG6zuUYrZdFuDY1014Lg', '_blank')
				}
				if(index == 5){
					window.open('https://mp.weixin.qq.com/s/Y1vh9vVOvnEhOKQ-Rv63aQ', '_blank')
				}
			},
			getdown(){
					this.$axios({
						url:'/index_menu/',
						params:{
							download:'1'
						}
					}).then(res=>{
						// console.log(res);
						
						if(res.data.status == 200){
							this.downlist = res.data.info
							this.downflag = res.data.status
							// console.log(this.downlist);
						}
						if(res.data.status == 201){
							this.downflag = res.data.status
						}
					})
			},
			reserva(){
				
				this.$axios({
					url:'/index_menu/',
					params:{
						reservation:'1'
					}
				}).then(res=>{
					// console.log(res);
					if(res.data.status == 200){
						this.yyflag=res.data.status
						this.reservalist = res.data.info
						console.log(this.reservalist);
					}
					if(res.data.status == 201){
						this.yyflag=res.data.status
					}
				})
			},
			godetail(pid){
				
								// console.log(r)
								let id =JSON.stringify(pid);
								// console.log(id)
								
				// tag: 'a',
								const {href} = this.$router.resolve(
								{ 
								  path: '/detail',
								  query: {body: id}}
								)
								let token = JSON.parse(window.localStorage.getItem('token'))
								// console.log(token);
								if(token){
									window.open(href, '_blank')
									// console.log(this.currentPage);
								}else{
									this.$message.error('您还没有登录')
									setTimeout(()=>{
										this.$router.push({path:'/login'})
									},1200)
								}
								
								// this.$router.push({path:'/detail',query: {body:r}})
							
			},
			getupdate(){
				this.$axios({
					url:'/index_menu/',
					params:{
						update:'1'
					}
				}).then(res=>{
					// console.log(res);
					this.updatelist = res.data.info
					console.log(this.updatelist);
				})
			},
			hotyy(){
				this.$axios({
					url:'/index_menu/',
					params:{
						hot:'1'
					}
				}).then(res=>{
					// console.log(res);
					this.hotyylist = res.data
					// console.log(this.hotyylist);
				})
			},
			pay(){
				let token = JSON.parse(window.localStorage.getItem('token'))
					const {href} = this.$router.resolve(
					{ 
					  path: '/pay'
				}
					)
					if(token){
						window.open(href, '_blank')
					}else{
						this.$message.error('您还没有登录')
					}
			},
			gz(pkid){
				
					let token = JSON.parse(window.localStorage.getItem('token'))
						// console.log(token);
					if(token){
						console.log(pkid);
						// console.log(token);
						if(token.info.is_vip==true){
							this.$axios({
								url: '/search/',
								params: {
									pkid: pkid,
									trace:1
								}
							}).then(res=>{
								console.log(res);
								
								if(res.data.status==200){
									
									this.$message.success('跟踪成功！')
									
								}
								if(res.data.status==403){
									this.$message.error('您还没有登录')
									setTimeout(()=>{
										this.$router.push({path:'/login'})
									},1200)
								}
							})
						}else{
							this.$message.error('开通会员解锁更多功能!')
						}
					}else{
						this.$message.error('您还没有登录')
						setTimeout(()=>{
							this.$router.push({path:'/login'})
						},1200)
					}
				
			},
			downloads(pkid){
				
				
				
					console.log(pkid);
				
					let token = JSON.parse(window.localStorage.getItem('token'))
					// console.log(token);
				if(token){
					// console.log(token);
					if(token.info.is_vip==true){
						
						
						location.href='/url'
						window.location.href="https://www.biaozhuncha.com/api/download_text/?pk_id="+pkid;  
						
						// if(this.search.content!=''||this.search.content!=null){
						// 	this.$axios({
						// 		url: '/search/',
						// 		params: {
						// 			pkid: this.search.pk_id,
						// 			download:1
						// 		}
						// 	}).then(res=>{
						// 		console.log(res);
						// 	}).catch(err=>{
						// 		console.log(err);
						// 	})
							// this.downs.push(this.search)
							// let urls = 'http://www.biaozhuncha.com/api/media/'+this.search.content
							// window.location.href = urls; // 本窗口打开下载
							// window.open(urls, '_blank')//新窗口打开
							
							// this.$axios({
							// 	url:'/download_text/',
							// 	params:{
							// 		pk_id: pkid,
							// 	}
							// }).then(res=>{
							// 	console.log(res);
							// 	if(res.data.status == 402){
							// 		this.$message.error(res.data.info)
							// 	}else{
							// 		// location.href='/url'
							// 		console.log(res.data);
							// 		window.location.href=res.data; 
							// 	}
								
							// })
						// }else{
						// 	this.$message.error('资料收集中，敬请期待！')
						// }
						
					}else{
						this.$message.error('开通会员解锁更多功能!')
					}
				}else{
					this.$message.error('您还没有登录')
					setTimeout(()=>{
						this.$router.push({path:'/login'})
					},1200)
				}
				
				
				
			},
			searchgo(){
				this.$router.push({path:'/search'})
			},
			goone(){
					this.$router.push({path:'/search'})	
			},
			gotwo(){
				this.$router.push({path:'/product'})
			},
			gothree(){
				this.$router.push({path:'/searchgf'})
			},
			gofour(){
				this.$router.push({path:'/my_his'})
			},
			gofive(){
				this.$router.push({path:'/my_down'})
			},
			feed(){
				
				let token = JSON.parse(window.localStorage.getItem('token'))
					const {href} = this.$router.resolve(
					{ 
					  path: '/my_feed'
				}
					)
					if(token){
						window.open(href, '_blank')
					}else{
						this.$message.error('您还没有登录')
					}
			},
			track(){
				
				let token = JSON.parse(window.localStorage.getItem('token'))
					const {href} = this.$router.resolve(
					{ 
					  path: '/my_track'
				}
					)
					if(token){
						window.open(href, '_blank')
					}else{
						this.$message.error('您还没有登录')
					}
			},
			mess(){
				
				let token = JSON.parse(window.localStorage.getItem('token'))
					const {href} = this.$router.resolve(
					{ 
					  path: '/my_mess'
				}
					)
					if(token){
						window.open(href, '_blank')
					}else{
						this.$message.error('您还没有登录')
					}
			},
			tonews(){
				this.$router.push('/news')
			},
			personalto(){
				let token = JSON.parse(window.localStorage.getItem('token'))
				const {href} = this.$router.resolve(
				{ 
				  path: '/personal'
			}
				)
				if(token){
					window.open(href, '_blank')
				}else{
					this.$message.error('您还没有登录')
				}
			},
			detailnews(i){
				// console.log(i);
				const {href} = this.$router.resolve(
				{ 
				  path: '/news_detail',
				  query: {num: i}}
				)
	
				// console.log(token);
		
					window.open(href, '_blank')
				// this.$router.push({path:'/news_detail',query:{num:i}})
			},
			tolink(item,index){
				this.opindex = index
				// console.log(this.opindex)
				if(item=="查标准"){
					this.$router.push({path:'/search'})
				}
				if(item=="查产品"){
					this.$router.push({path:'/product'})
				}
				if(item=="查规范"){
					this.$router.push({path:'/searchgf'})
				}
			},
			search(i){
				window.sessionStorage.setItem('search',JSON.stringify(i))
				this.$router.push({path:'/search'})
			},
			getpaper() {
				this.$axios({
					url: '/push/'
				}).then(res => {
					// console.log(res);
					this.papers = res.data.info
				})
			},
			gettime() {
				let date = new Date();
				let timestamp = new Date().getTime();
				this.y = date.getFullYear(); //获取年份
				this.m = date.getMonth() + 1 //月
				this.d = date.getDate() //当前日
				this.w = this.weekArr[new Date().getDay()];
			},
			getnews(){
				this.$axios({
					url: '/push/',
					params:{
						index:1
					}
				}).then(res => {
					// console.log(res);
					this.newslist = res.data.info.slice(0,30)
				})
			}
		}
	}
</script>
<style>
    body{
       margin:0;
       padding:0;
     }
 </style>
<style scoped="">
	
	@media screen and (max-width: 750px) {
			
		
		.inps{
			display: inline-block;
			width: 7rem;
			width: 600px;
			/* border: 13px solid red; */
		}
		.newsmodel{
			
			display: flex;
			justify-content: flex-start;
			/* margin-left: 100px; */
		}
		.jingang{
			/* margin-left: 100px; */
		}
		.downmargin{
			transform: translate(-0.5rem,0);
		}
	} 
	.downmargin{
		margin: 0.3rem 0 0 1.56rem;
	}
	.newsmodel{
		display: flex;
		justify-content: center;
	}
	.inps{
		display: inline-block;
		width: 9rem;
		height: 0.55rem;
		border-radius: 0.1rem;
		display: flex;
		justify-content: space-between;
		align-items: cener;
		background-color: #ffffff;
		overflow: hidden;
	}
	.jingang {
		width: 100%;
		height: 1.37rem;
		background: url(../assets/images/index/jingang.png)no-repeat;
		background-size: cover;
		display: flex;
		align-items: center;
	}

	.box2::-webkit-scrollbar {
		/* display: none; */
	}
	.card{
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.card:hover {
		background-color: #feeceb;
	}

	.cygn {
		background: url(../assets/images/index/cygn.png)no-repeat;
		background-size: cover;
	}
	.opcss{
		font-size: 0.3rem;
		color: #fff;
		width: 1.6rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.opbj{
		position: relative;
		/* color: red; */
	}
	.opbj:before{
		content: "";/*单引号双引号都可以，但必须是英文*/
		 
		    width: 0.5rem;
		 
		    height: 1.5px; /*下划线高度*/
		 
		    background: #fff; /*下划线颜色*/
		 
		    position: absolute;
		 
		    top: 110%;
		 
		    left: 35%;
		 
		    transition: all .8s ; /*css动画效果，0.8秒完成*/
	}
	.texthov:hover{
		cursor: pointer;
		border: 1px solid #fff;
	}
	.texthov{
		font-size: 15px;
	}
	.btn2 {
			width: 0.5rem;
			height: 0.25rem;
			text-align: center;
			line-height: 0.25rem;
			/* border: 1px solid #f32727; */
			background-color: #FFFFFF;
			border-radius: 4px;
			color: #f32727;
		}
		.btn2:hover{
			background-color: #f32727;
			color: #FFFFFF;
		}
		::-webkit-scrollbar{
			display: none;
		}
		
		
.el-header{
	padding: 0;
}
	.logos{
		width: 0.6rem;
		height: 0.6rem;
		margin-left: 2.4rem;
	}
	  .home{
		  height: 100%;
	  }
	  .homecontainer{
	
		  height: 100vh;
	
	  }
	  .headerone{
		  width: 100%;
		  /* height: 100px; */
		  display: flex;
		  justify-content: space-between;
		  align-items: center;
		  overflow: hidden;
	  }
	  .befores{
		  position: relative;
	  }
	 .befores:before{
		 content: "";/*单引号双引号都可以，但必须是英文*/
		     width: 0.4rem;
		     height: 1px; /*下划线高度*/
		     background: #FFFFFF; /*下划线颜色*/
		     position: absolute;
			 top: 110%;;
	 }
	 .rightlogo{
		 margin-right: 2.4rem;
		 width: 3.8rem;
		 display: flex;
		 align-items: center;
		 justify-content: space-between;
		 flex-wrap: nowrap;
		 color: #FFFFFF;
		 font-size: 0.16rem;
	 }
	.button1 {
	 background-color: #905321; /* 按钮颜色 */
	   border: none;
	   color: white; /* 文字颜色 */
	   padding: 10px 20px; /* 内部填充 */
	   text-align: center; /* 文字居中 */
	   text-decoration: none;
	   display: inline-block;
	   font-size: 14px; /* 字体大小 */
	   margin: 4px 2px;
	   cursor: pointer;
	   transform: skew(-20deg); /* 倾斜度 */
	   transition: 0.3s;
	   border-radius: 10px 4px 10px 4px;
	}
	 .button1:hover{
		 background-color: #92643e;
	 }
	 .nottitle{
		 background-color: #fceebf;
		 color: #a57c56;
		 border-radius: 10px;
		 padding: 15px 30px;
		 text-align: center;
		 font-weight: 700;
		 margin-top: 10px;
	 }
	   
/* .el-carousel__item h3 {
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  } */
  
</style>
