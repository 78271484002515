<template>
	<div>
		
	
	<Tablist></Tablist>
	<div class="content">
		<!-- <el-upload
		  class="upload-demo"
		  drag
		  :action="upload"
		  :multiple="false"
		  :on-success="successcp"
		  >
		  <i class="el-icon-upload"></i>
		  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
		  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
		</el-upload> -->
		<div style="display: flex;justify-content: center;align-items: center;cursor:pointer;margin-top: 20px;">
		
				<div v-for="(item,index) in options" :key="index" @click="tolink(item,index)" class="opcss" :class='{opbj:index==opindex}'>{{item}}</div>
				
		</div>
		<div style="display: flex;justify-content: center;margin-top: 30px;" >
			<!-- @keyup.enter="search1" -->
			<div style="width: 900px;height: 55px;border-radius: 10px;display: flex;justify-content: space-between;align-items: center;background-color: #ffffff;overflow: hidden;">
				<input type="text" style="margin-left: 20px;width: 800px;background-color:transparent" >
					<img src="../assets/images/searchicon.png" style="width: 36px;height: 36px;margin-right: 20px;cursor:pointer;">
			
			</div>
		</div>
		
		<div class="modelbj" style="">
			<div style="width: 60px;height: 300px;background-color: #FFFFFF;opacity: .3;border-radius: 0 10px 10px 0;"></div>
		
				<img src="../assets/images/detail/scan.png" style="width: 200px;height: 320px;">
		
			<!-- <div style="width: 180px;height: 300px;background-color: #FFFFFF;"></div> -->
			<img src="../assets/images/detail/bao.png" style="width: 200px;height: 320px;">
			<div style="width: 400px;height: 300px;margin-right: 7px;">
				<div style="width: 360px;font-size: 16px;color: #101010;margin-top: 10px;">
					查产品功能请您使用APP拍摄产品执行标准进行查询，并能生成产品标准标识报告，欢迎体验~
				</div>
				<div style="display: flex;flex-direction: column;align-items: center;">
					<img src="../assets/images/detail/erweima.png" style="width: 187px;height: 184px;margin-top: 34px;">
					<div style="margin-top: 16px;">扫一扫 下载标准查APP</div>
				</div>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
	import Tablist from '../common/Tab.vue'
	export default{
		components:{
			Tablist,
		},
		data(){
			return{
				upload:'https://www.biaozhuncha.com/api/image_re/',
				options:['查标准','查产品','查规范'],
				opindex:1,
			}
		},
		created() {
			// this.openthedie()
			// window.localStorage.setItem('local','123456798')
		},
		methods:{
			successcp(response, file, fileList){
				// console.log(response, file, fileList);
			},
			tolink(item,index){
				this.opindex = index
				// console.log(this.opindex)
				if(item=="查标准"){
					this.$router.push({path:'/search'})
				}
				if(item=="查产品"){
					this.$router.push({path:'/product'})
				}
				if(item=="查规范"){
					this.$router.push({path:'/searchgf'})
				}
			}
		}
	}
</script>

<style scoped="scoped">
	.content{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.modelbj{
		width: 900px;
		height: 360px;
		margin-top: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		/* justify-content: center; */
		background: linear-gradient(to right, #ffa18c 50%, #f9f9f9 50%)
	}
/* 	.left{
		width: 600px;
		height: 100%;
		background: #ffa18c;
	}
	.right{
		width: 600px;
		height: 100%;
		background: #f9f9f9;
	} */
	.opcss{
		font-size: 30px;
		color: #FFFFFF;
		width: 160px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.opbj{
		position: relative;
		/* color: red; */
	}
	.opbj:before{
		content: "";/*单引号双引号都可以，但必须是英文*/
		 
		    width: 50px;
		 
		    height: 1.5px; /*下划线高度*/
		 
		    background: #FFFFFF; /*下划线颜色*/
		 
		    position: absolute;
		 
		    top: 110%;
		 
		    left: 35%;
		 
		    transition: all .8s ; /*css动画效果，0.8秒完成*/
	}
</style>
