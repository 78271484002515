import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/base.css'
import './assets/css/index.css'
// import './assets/font/iconfont.css'
import './assets/font/iconfont.css'
import './assets/fonts/font.css'
// import './assets/js/wxsm.js'
// import 'lib-flexible'
// import './assets/js/flexible.js'
// import './assets/css/.min.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
import wx from 'weixin-js-sdk'			//微信sdk依赖
import './assets/js/postcssrc.js'
import './utils/rem';
import axios from 'axios'
import qs from 'qs'
Vue.prototype.$qs = qs
axios.defaults.baseURL = 'https://www.biaozhuncha.com/api'
axios.defaults.withCredentials=true;//让ajax携带cookie
Vue.prototype.$axios = axios
router.afterEach((to,from,next) => {
    // window.scrollTo(0,0);
});
// 正式环境清除所有console.log
if (process.env.NODE_ENV === 'production') {
  if (window) {
    window.console.log = function () {};
  }
}

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
