<template>
	<div class="content2">
		<div class="header" >
			<div style="width: 100%;height: 0.4rem;background-color: #fa6143;display: flex;align-items: center;justify-content: flex-end;">
				<div style="margin-right: 2.2rem;color: #FFFFFF;font-size: 0.15rem;display: flex;align-items: center;cursor: pointer;">
					<div class="iconfont icon-user" style="margin-right: 0.05rem;"></div>
					<div v-if="tokens == false" @click="gologin">登录/注册</div>
					<div v-else @click="layout">退出</div>
				</div>
			</div>
			<div style="display: flex;justify-content: space-between;align-items: center;width: 100%;height: 1.8rem;">
				<div style="display: flex;align-items: center;margin-left: 1.8rem;">
					<img src="../assets/images/newslogo.png" style="width: 0.6rem;height: 0.6rem;">
					<div style="width: 0.03rem;height: 0.5rem;background-color: #c62100;margin: 0 0.3rem 0 0.3rem;"></div>
					<img src="../assets/images/detail/dog.png" style="width: 0.86rem;height: 0.9rem;">
				</div>
				<div style="" class="cbz">查标准就用标准查！</div>
			</div>
			<!-- <img src="../assets/images/detail/title.png" style="width: 355px;height: 37px;margin-right: 180px;"> -->
		</div>
	 	<div style="display: flex;justify-content: center;" class="content">
			<div style="width: 12rem;background-color: #ffffff;margin-top: 0.4rem;">
				<div style="width: 100%;height: 0.46rem;background-color: #f9a31c;line-height: 0.46rem;">
					<p style="font-size: 0.18rem;margin-left: 0.26rem;color: #101010;"> ——“{{search.num}}</p>
				</div>
				
				<div style="display: flex;justify-content: center;margin-top: 0.2rem;">
					<div style="width: 11.6rem;">
						<div style="display: flex;justify-content: flex-start;align-items: center;">
							<div style="width: 2.4rem;height: 0.6rem;line-height: 0.6rem;font-size: 0.16rem;color: #101010;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;background-color: #fff8ec;padding-left: 0.2rem;">批准文号</div>
							<div style="width: 100%;height: 0.6rem;background-color: #FFFFFF;padding-left: 0.2rem;line-height: 0.6rem;font-size: 0.16rem;color: #4a4b4b;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;border-right:1px solid #e5e5e5;">{{search.num}}</div>
						</div>
						
						<div style="display: flex;justify-content: flex-start;align-items: center;">
							<div style="width: 2.4rem;height: 0.6rem;line-height: 0.6rem;font-size: 0.16rem;color: #101010;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;background-color: #fff8ec;padding-left: 0.2rem;">产品名称</div>
							<div style="width: 100%;height: 0.6rem;background-color: #FFFFFF;padding-left: 0.2rem;line-height: 0.6rem;font-size: 0.16rem;color: #4a4b4b;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;border-right:1px solid #e5e5e5;">{{search.name}}</div>
						</div>
						
						<div style="display: flex;justify-content: flex-start;align-items: center;">
							<div style="width: 2.4rem;height: 0.6rem;line-height: 0.6rem;font-size: 0.16rem;color: #101010;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;background-color: #fff8ec;padding-left: 0.2rem;">英文名称</div>
							<div style="width: 100%;height: 0.6rem;background-color: #FFFFFF;padding-left: 0.2rem;line-height: 0.6rem;font-size: 0.16rem;color: #4a4b4b;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;border-right:1px solid #e5e5e5;">{{search.eu_name}}</div>
							
						</div>
						
						<div style="display: flex;justify-content: flex-start;align-items: center;">
							<div style="width: 2.4rem;height: 0.6rem;line-height: 0.6rem;font-size: 0.16rem;color: #101010;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;background-color: #fff8ec;padding-left: 0.2rem;">商品名</div>
							<div style="width: 100%;height: 0.6rem;background-color: #FFFFFF;padding-left: 0.2rem;line-height: 0.6rem;font-size: 0.16rem;color: #4a4b4b;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;border-right:1px solid #e5e5e5;">{{search.shop_name}}</div>
						</div>
						
						<div style="display: flex;justify-content: flex-start;align-items: center;">
							<div style="width: 2.4rem;height: 0.6rem;line-height: 0.6rem;font-size: 0.16rem;color: #101010;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;background-color: #fff8ec;padding-left: 0.2rem;">剂型</div>
							<div style="width: 100%;height: 0.6rem;background-color: #FFFFFF;padding-left: 0.2rem;line-height: 0.6rem;font-size: 0.16rem;color: #4a4b4b;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;border-right:1px solid #e5e5e5;">{{search.reagent}}</div>
						</div>
						
						<div style="display: flex;justify-content: flex-start;align-items: center;">
							<div style="width: 2.4rem;height: 0.6rem;line-height: 0.6rem;font-size: 0.16rem;color: #101010;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;background-color: #fff8ec;padding-left: 0.2rem;">规格</div>
							<div style="width: 100%;height: 0.6rem;background-color: #FFFFFF;padding-left: 0.2rem;line-height: 0.6rem;font-size: 0.16rem;color: #4a4b4b;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;border-right:1px solid #e5e5e5;">{{search.specs}}</div>
						</div>
						
						<div style="display: flex;justify-content: flex-start;align-items: center;">
							<div style="width: 2.4rem;height: 0.6rem;line-height: 0.6rem;font-size: 0.16rem;color: #101010;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;background-color: #fff8ec;padding-left: 0.2rem;">上市许可持有人</div>
							<div style="width: 100%;height: 0.6rem;background-color: #FFFFFF;padding-left: 0.2rem;line-height: 0.6rem;font-size: 0.16rem;color: #4a4b4b;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;border-right:1px solid #e5e5e5;">{{search.license_people}}</div>
						</div>
						
						<div style="display: flex;justify-content: flex-start;align-items: center;">
							<div style="width: 2.4rem;height: 0.6rem;line-height: 0.6rem;font-size: 0.16rem;color: #101010;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;background-color: #fff8ec;padding-left: 0.2rem;">上市许可持有人地址</div>
							<div style="width: 100%;height: 0.6rem;background-color: #FFFFFF;padding-left: 0.2rem;line-height: 0.6rem;font-size: 0.16rem;color: #4a4b4b;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;border-right:1px solid #e5e5e5;">{{search.license_address}}</div>
						</div>
						
						<div style="display: flex;justify-content: flex-start;align-items: center;">
							<div style="width: 2.4rem;height: 0.6rem;line-height: 0.6rem;font-size: 0.16rem;color: #101010;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;background-color: #fff8ec;padding-left: 0.2rem;">生产单位</div>
							<div style="width: 100%;height: 0.6rem;background-color: #FFFFFF;padding-left: 0.2rem;line-height: 0.6rem;font-size: 0.16rem;color: #4a4b4b;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;border-right:1px solid #e5e5e5;">{{search.made_in}}</div>
						</div>
						
						<div style="display: flex;justify-content: flex-start;align-items: center;">
							<div style="width: 2.4rem;height: 0.6rem;line-height: 0.6rem;font-size: 0.16rem;color: #101010;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;background-color: #fff8ec;padding-left: 0.2rem;">批准日期</div>
							<div style="width: 100%;height: 0.6rem;background-color: #FFFFFF;padding-left: 0.2rem;line-height: 0.6rem;font-size: 0.16rem;color: #4a4b4b;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;border-right:1px solid #e5e5e5;">{{search.app_date}}</div>
						</div>
						
						<div style="display: flex;justify-content: flex-start;align-items: center;">
							<div style="width: 2.4rem;height: 0.6rem;line-height: 0.6rem;font-size: 0.16rem;color: #101010;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;background-color: #fff8ec;padding-left: 0.2rem;border-bottom: 1px solid #e5e5e5;">生产地址</div>
							<div style="width: 100%;height: 0.6rem;background-color: #FFFFFF;padding-left: 0.2rem;line-height: 0.6rem;font-size: 0.16rem;color: #4a4b4b;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;border-right:1px solid #e5e5e5;border-bottom: 1px solid #e5e5e5;">{{search.made_in_address}}</div>
						</div>
						
						<div style="display: flex;justify-content: flex-start;align-items: center;">
							<div style="width: 2.4rem;height: 0.6rem;line-height: 0.6rem;font-size: 0.16rem;color: #101010;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;background-color: #fff8ec;padding-left: 0.2rem;border-bottom: 1px solid #e5e5e5;">产品类别</div>
							<div style="width: 100%;height: 0.6rem;background-color: #FFFFFF;padding-left: 0.2rem;line-height: 0.6rem;font-size: 0.16rem;color: #4a4b4b;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;border-right:1px solid #e5e5e5;border-bottom: 1px solid #e5e5e5;">{{search.type}}</div>
						</div>
						
						<div style="display: flex;justify-content: flex-start;align-items: center;">
							<div style="width: 2.4rem;height: 0.6rem;line-height: 0.6rem;font-size: 0.16rem;color: #101010;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;background-color: #fff8ec;padding-left: 0.2rem;border-bottom: 1px solid #e5e5e5;">原批准文号</div>
							<div style="width: 100%;height: 0.6rem;background-color: #FFFFFF;padding-left: 0.2rem;line-height: 0.6rem;font-size: 0.16rem;color: #4a4b4b;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;border-right:1px solid #e5e5e5;border-bottom: 1px solid #e5e5e5;">{{search.ori_num}}</div>
						</div>
						
						<div style="display: flex;justify-content: flex-start;align-items: center;">
							<div style="width: 2.4rem;height: 0.6rem;line-height: 0.6rem;font-size: 0.16rem;color: #101010;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;background-color: #fff8ec;padding-left: 0.2rem;border-bottom: 1px solid #e5e5e5;">药品本位码</div>
							<div style="width: 100%;height: 0.6rem;background-color: #FFFFFF;padding-left: 0.2rem;line-height: 0.6rem;font-size: 0.16rem;color: #4a4b4b;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;border-right:1px solid #e5e5e5;border-bottom: 1px solid #e5e5e5;">{{search.name_num}}</div>
						</div>
						
						<div style="display: flex;justify-content: flex-start;align-items: center;">
							<div style="width: 2.4rem;height: 0.6rem;line-height: 0.6rem;font-size: 0.16rem;color: #101010;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;background-color: #fff8ec;padding-left: 0.2rem;border-bottom: 1px solid #e5e5e5;">本位码备注</div>
							<div style="width: 100%;height: 0.6rem;background-color: #FFFFFF;padding-left: 0.2rem;line-height: 0.6rem;font-size: 0.16rem;color: #4a4b4b;border-top:1px solid #e5e5e5;border-left:1px solid #e5e5e5;border-right:1px solid #e5e5e5;border-bottom: 1px solid #e5e5e5;">{{search.pk_id_remark}}</div>
						</div>
						
						
						
						
						
						
						
						
						<div style="width: 100%;height: 0.2rem;background-color: #FFFFFF;"></div>
						
						
						
					</div>
				</div>
				
				
			</div>
		</div>
		
		
		<div style="width: 100%;height: 20px;background-color: #f8f8f8;"></div>
				
				<Bottom></Bottom>
				
	</div>
</template>

<script>
	import Bottom from '../common/Bottom.vue'
	export default{
		components:{
			Bottom
		},
		data(){
			return{
				search:{},
				tokens:false
			}
		},
		mounted() {
			this.path()
			this.loginout()
		},
		methods:{
			loginout(){
				let token = JSON.parse(window.localStorage.getItem('token'))
				// console.log(token);
				if(token==null){
					this.tokens = false
				}else{
					this.tokens = true
					// console.log(token);
					// this.headhost = 'https://www.biaozhuncha.com/api/media/'+token.info.headshot
				}
			},
			layout(){
				
				
				this.$axios({
					url:'/logout/'
				}).then(res=>{
					window.localStorage.removeItem('token')
					this.$message.success('退出成功')
					this.loginout()
				
				})
			},
			gologin(){
				this.$router.push({path:'/login'})
			},
			path() {
				this.search = JSON.parse(this.$route.query.body)
				// console.log(this.search);
				// console.log(this.search);
				// console.log(JSON.parse(this.$route.query.body));
				// let token = JSON.parse(window.sessionStorage.getItem('token'))
				// console.log(token);
				// if(this.search.drafter){
				// 	this.drafter = this.search.drafter.split(',')
				// }
				// if(this.search.drafter_unit){
				// 	this.danwei =  this.search.drafter_unit.split(',')
				// }
				// console.log(this.danwei);
			},
		}
	}
</script>

<style scoped="scoped">
	@media screen and (max-width: 750px) {
		.content2{
			width: 19.2rem;
		}
		.header{
			width: 19.2rem;
		}
		
	}
	.content{
		background-color: #f8f8f8;
		width: 100%;
		height: 100%;
	}
	.header {
		background: url(../assets/images/detail/detailhead.png) no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		width: 100%;
		height: 2.2rem;
	}
	.dangers{
		background-color: #000000;
	}
	.cbz{
		font-size: 0.4rem;
		color: #FFFFFF;
		margin-right: 1.8rem;
		font-family: jinnan;
	}
</style>
