<template>
	<div style="width: 100%;height: 100%;position: relative;">
		<div style="width: 100%;height: 20px;"></div>
		<h3 style="text-align: center;">扫码登录</h3>
		<div v-if="scan_status == 403 || scan_status == 201" >
			<!--直接把后端返回的二维码的base64编码作为图片的src值即可显示二维码-->
			<div class="scan-img">
				<!-- <el-image v-if="scan_status === 'NOT_SCAN'" style="width: 200px; height: 200px" :src="scan_src" fit="cover">
					
				</el-image> -->
				<div style="width: 272px;height: 272px;display: flex;justify-content: center;align-items: center;">
					<div v-if="scan_status == 403">
						<img :src="'data:image/jpg;base64,'+scan_src"  style="color: #0077AA;background-color: #0077AA;">
					</div>
					<div v-if="scan_status == 201">
						<img src="../assets/images/login/yism.png" >
					</div>
				</div>
			
			</div>
		
			
		</div>
		<div style="text-align: center;"><a href="">刷新</a></div>
		<div style="display: flex;justify-content: center;flex-direction: column;align-items: center;margin-top: 10px;" v-if="scan_status == 403">
			<div>打开标准查APP</div>
			<div style="margin-top: 10px;">在「主页」页面顶部打开扫一扫</div>
		</div>
		
		<div style="display: flex;justify-content: center;flex-direction: column;align-items: center;margin-top: 10px;" v-if="scan_status == 201">
			<div>扫描成功</div>
			<div style="margin-top: 10px;">请在标准查中完成登录</div>
		</div>
		
	
			<img src="../assets/images/login/scanflag.png" class="back" @click="goback">
		
	</div>


</template>

<script>
	export default{
		data(){
			return{
				//二维码状态
				scan_status:403,
				//二维码链接
				scan_src:'',
				Timer:'',
				qr_status:''
			}
		},
		created() {
			this.getCode()
		},
		mounted(){
			
		},
		
		beforeDestroy(){
			clearInterval(this.Timer);
		},
		methods:{
			goback(){
				this.$router.go(-1);//返回上一层
			},
			onCloseModal(){
				clearInterval(this.Timer);
			
			},
			getCode(){
				this.$axios({
					url:'/sign_in/',
					params:{
						get_qrcode:'1'
					}
				}).then(res=>{
				
					//清除之前设置的长轮询
					      clearInterval(this.Timer);
					// let Base64 = require('js-base64').Base64
					let imgs = res.data.info
					this.scan_src =imgs 
					this.checkQr(res.data.uuid);
					// console.log(this.scan_src);
				})
			},
			//轮询检查二维码是否过期或者是否被扫描
			    checkQr(uuid) {
			      var that = this;
			      //开始长轮询
			      var timer = setInterval(() => {
			        //携带上当前二维码的base64编码
			        that.$axios({
						url:'/sign_in/',
						params:{
							uuid:uuid
						
						}
					}).then((res) => {
			            //返回当前二维码的状态
						// console.log(res);
						that.scan_status = res.data.status
						// console.log(that.scan_status)
			
			if(res.data.status == 405){
				clearInterval(that.Timer);
				that.$message.error(res.data.info)
				that.getCode()
			}
			if(res.data.status == 200){
				that.$message.success('登录成功！')
				window.localStorage.setItem('token',JSON.stringify(res.data))
				that.$router.push({path:'/indext'})
			}
			            
			          })
			          .catch((err) => {
			            // console.log(err);
			          });
			      }, 1500);
			      this.Timer = timer;
			    },

		}
	}
</script>

<style scoped="scoped">
	.scan-img{
		display: flex;	        
		      height: 100%;
		            flex-direction: row;  /* 子元素横向排列 */
		            justify-content: center; /* 相对父元素水平居中 */
		          
		
	}
	.back{
	
		position: absolute;
		right: 0;
		bottom: 0;
		cursor: pointer;
	}
	.back:hover{
		opacity: 1.0;
	}
</style>
