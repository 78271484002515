<template>
	<div class="logins">
		<div class="log2">
			<div class="cardWhite">
			
				<div class="leftlogin">
					
				</div>
				
			
				<div class="rightlogin">
					<router-view></router-view>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		methods:{
			
		},
	}
</script>

<style scoped="scoped">
		
	
	@media screen and (max-width: 750px) {
	/* 	body{
			height: 100%;
			width: 19.20rem;
		} */
		.logins{
			height: 100vh;
			width: 19.20rem;
			/* border: 4px solid blue; */
			/* position: fixed; */
		}
		.log2{
			height: 100vh;
			width: 19.20rem;
		}
		
		.leftlogin{
			display: none;
		}
		.cardWhite{
			display:flex;
			justify-content: center;
		}
	} 
	.logins{
		height: 100vh;
		width: 100%;
		/* background-color: #000000; */
		/* position: fixed; */
	}
	.log2{
		height: 100%;
		width: 100%;
		background: url(../assets/images/login/login.png) no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.cardWhite{
		width: 9.1rem;
		height: 4.8rem;
		/* background-color: #FFFFFF; */
		border-radius: 20px;
		display: flex;
		/* border: 1px solid red; */
	}
	.leftlogin{
		width: 6.1rem;
		height: 4.8rem;
		background: url(../assets/images/login/logins.png) no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		/* transform: translate(-10%); */
		/* border: 1px solid red; */

	}
	
	.rightlogin{
		width: 4rem;
		height: 4.8rem;
		/* border: 1px solid pink; */
		background-color: #FFFFFF;
		border-radius: 0 0.3rem 0.3rem 0;
	}
</style>
