import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import News from '../components/News.vue'
import About from '../components/About.vue'
import Index from '../components/Index.vue'
import Detail from '../components/Detail.vue'
import Details from '../components/Details.vue'
import Drafter from '../components/Drafter.vue'
import DraftingUnit from '../components/DraftingUnit.vue'
import Search from '../components/Search.vue'
import Searchcp from '../components/SearchCp.vue'
import Login from '../components/Login.vue'
import CodeLogin from '../components/CodeLogin.vue'
import ChangePass from '../components/ChangePass.vue'
import Wxlogin from '../components/Wxlogin.vue'
import Searchgf from '../components/SearchGf.vue'
import Detailgf from '../components/Gfdetail.vue'
import Committee from '../components/Committee.vue'
import Wxbind from '../components/Wxbind.vue'
import Scanlogin from '../components/Scanlogin.vue'
import Indextwo from '../components/Indextwo.vue'
import News_detail from '../components/News_detail.vue'
import Personal from '../components/Personal.vue'
import Message from '../components/Mymessage.vue'
import Track from '../components/Track.vue'
import Download from '../components/Mydownload.vue'
import Account from '../components/Myaccount.vue'
import History from '../components/History.vue'
import Feedback from '../components/Myfeedback.vue'
import Record from '../components/Record.vue'
import Payment from '../components/Payment.vue'
import Question from '../components/Question.vue'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
   redirect: '/home'
  },
  {
    path: '/home',
    component: Home,
	redirect: '/index',
	children: [
		{
		  path: '/index',
		  component: Index,
		  redirect: '/indext',
		  children:[
			  {
				  path:'/indext',
				  component:Indextwo
			  },
			  {
			  				  path:'/news_detail',
			  				  component:News_detail
			  },
			  {
			  				  path:'/personal',
			  				  component:Personal,
							  redirect: '/my_acc',
							  children:[
								  {
									  path:'/my_mess',
									  component:Message,
								  },{
									  path:'/my_track',
									  component:Track,
								  }
								  ,{
									  path:'/my_down',
									  component:Download,
								  },{
									  path:'/my_acc',
									  component:Account,
								  },{
									  path:'/my_his',
									  component:History,
								  },
								  {
									  path:'/my_feed',
								  	  component:Feedback,
								  },
								  {
									path:'/pay',
								  	component:Payment,
								  }
							  ]
			  },
			  {
			  path: '/search',
			  component: Search
		  },
			{
						  path: '/product',
						  component: Searchcp
			},
			 {
				 path:'/searchgf',
				 component:Searchgf
			 }
		  ]
		},
	  {
	    path: '/news',
	    component: News
	  },
	 
	 
	]
  },
  {
     path: '/about',
     component: About
   },
   {
      path: '/question',
      component: Question
    },
  {
     path: '/record',
     component: Record
   },
  {
     path: '/detail',
     component: Detail,
	 redirect: '/details',
	 children:[{
	 			  path: '/details',
	 			  component: Details
	 },
	 	{
	 				  path: '/drafter',
	 				  component: Drafter
	 	},
			{
						  path: '/draftingunit',
						  component: DraftingUnit
			},
			{
						  path: '/committee',
						  component: Committee
			}
	 ]
   }, 
   {
     path: '/gfdetail',
     component: Detailgf
   },
 {
	 path: '/login',
	 component: Login,
	 redirect: '/code',
	 children:[{
		 
		    path: '/code',
		    component: CodeLogin
		  
	 },{
		 path:'/changepass',
		 component:ChangePass
	 },
	{
			 path:'/wxlogin',
			 component:Wxlogin
	},
	{
			 path:'/wxbind',
			 component:Wxbind
	},
	{
			 path:'/scanapp',
			 component:Scanlogin
	}
	 ]
 },
 
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes,
  mode:'history',
  scrollBehavior(to,from,savedposition) {
  	if(to.hash){
		return {
			selector:to.hash
		}
	}
  }
})


// router.beforeEach((to, from, next) => {
//   // to 将访问哪一个路径
//   // from 代表从哪个路径跳转而来
//   // next 是一个函数,表示放行
//   //   next() 放行 next('/login') 强制跳转
//  const codestatus = window.sessionStorage.getItem('code')
//  console.log(codestatus);
//  if(codestatus==403){
//  	if(from.path == 'wxlogin') return next('wxbind')
//  }
//   next()
// })
export default router
